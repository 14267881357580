import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import PropsTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
// import logo from "../../assets/images/playerview/logo.svg";
import Sidebar from "./sidebar";
import CreateProfileModal from "../profile/registerProfileModal";
import UserContext from "../../context/UserContext";
import Header from "../header/header";
import "./layout.css"

function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const { showProfileModal, setShowProfileModal } = useContext(UserContext);

  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, [queryParams]);

  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End

  return (
    <div className="layout-wrapper">
      <Sidebar showSidebar={showSidebar} handleSidebar={handleSidebar} />
      {/* <div className="layout-header">
        <div className="container">
          <div className="logo">
            <span onClick={handleSidebar} role="presentation">
              <img src={logo} alt="" />
            </span>
          </div>
        </div>
      </div> */}
      <Header handleSidebar={handleSidebar} />

      <div className="layout-content">{children}</div>

      <div className="layout-footer" />
      <Modal
        show={showProfileModal}
        className="bar-room-setting-popup cpn-qr-popup profile-edit-modal"
        centered
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
      >
        {/* <span
          onClick={hideModal}
          className="close-model-icon"
          role="presentation"
        >
          <ClossIcon />
        </span> */}
        <CreateProfileModal />
      </Modal>
    </div>
  );
}
Layout.propTypes = {
  children: PropsTypes.element.isRequired,
};

export default Layout;
