import { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";
import Error from "../../../toast/erros";

function WaitingUser() {
  const { gameData, setDeviceStatus } = useContext(UserContext);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState("");

  const handleSelectPlayers = (e, user) => {
    const {
      target: { checked },
    } = e;
    if (checked) {
      setSelectedPlayers((old) => [...old, { ...user }]);
    } else {
      setSelectedPlayers((old) => old.filter((el) => el.id !== user.id));
    }
  };

  const handleAdd = (value) => {
    if (selectedPlayers.length === 0 && value === "add") {
      return setErrorMessage("Please select atleast one user.");
    }
    if (value === "add") {
      setShowSpinner("ADD");
      socket.emit("addUserFromWaitingList", {
        selectedPlayers,
        gameId: gameData.id,
      });
      return setTimeout(() => {
        setSelectedPlayers([]);
      }, 800);
    }
    setShowSpinner("ALL");
    socket.emit("addUserFromWaitingList", {
      selectedPlayers: gameData.waitingPlayers,
      gameId: gameData.id,
    });
    setDeviceStatus(true);
    setTimeout(() => {
      setSelectedPlayers([]);
    }, 800);
  };

  useEffect(() => {
    if (selectedPlayers.length === 0) {
      setShowSpinner("");
    }
  }, [selectedPlayers]);

  return (
    <div className="invite-wrapper waiting-list-wrapper">
      <div className="invite-user waiting-user">
        {gameData?.waitingPlayers?.length === 0
          ? ""
          : gameData?.waitingPlayers?.map((player) => (
            <UserList
              key={player.id}
              player={player}
              handleSelectPlayers={handleSelectPlayers}
            />
          ))}
      </div>
      <div className="waiting-list-btns">
        <Button className="add-all-btn" onClick={handleAdd} disabled={gameData?.players?.length === 8}>
          {showSpinner === "ALL" ? <Spinner animation="border" /> : "ADD ALL"}
        </Button>
        <Button className="add-btn" onClick={() => handleAdd("add")} disabled={gameData?.players?.length === 8}>
          {showSpinner === "ADD" ? <Spinner animation="border" /> : "ADD"}
        </Button>
      </div>
      {errorMessage ? (
        <Error message={errorMessage} closeAction={() => setErrorMessage("")} />
      ) : (
        ""
      )}
    </div>
  );
}

export default WaitingUser;

function UserList({ player, handleSelectPlayers }) {
  return (
    <div className="invite-user-list">
      <div className="invite-user-info">
        <div className="invite-user-cover">
          <span className="online-user" />
          <img
            src={player?.avatar}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null; // prevents looping
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
            }}
            alt=""
          />
        </div>
        <p>{player.name}</p>
      </div>
      <div className="invite-user-action">
        <Form.Check
          className="add-check-box"
          name="action"
          onChange={(e) => handleSelectPlayers(e, player)}
        />
      </div>
    </div>
  );
}

UserList.propTypes = {
  player: PropTypes.object.isRequired,
  handleSelectPlayers: PropTypes.func.isRequired,
};
