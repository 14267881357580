// eslint-disable-next-line import/no-extraneous-dependencies
import CryptoJS from "crypto-js";

export const getCameras = async () => {
  const allDevices = await navigator.mediaDevices.enumerateDevices();
  const videoCameras = allDevices.filter((m) => m.kind === "videoinput");
  return videoCameras;
};

export const getAllMics = async () => {
  const allDevices = await navigator.mediaDevices.enumerateDevices();
  const microphones = allDevices.filter((m) => m.kind === "audioinput");
  return microphones;
};

export const getAllSpeakers = async () => {
  const allDevices = await navigator.mediaDevices.enumerateDevices();
  const speakers = allDevices.filter((m) => m.kind === "audiooutput");
  return speakers;
};

export const convertAmmount = (amountValue) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((elm) => amountValue >= elm.value);
  return item
    ? (amountValue / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
};

export const DoEncrypt = (text) => {
  if (text) {
    const encrypted = CryptoJS.AES.encrypt(
      text,
      process.env.REACT_APP_KEY
    ).toString();
    return encrypted;
  }
};

export const DoDecrypt = (cipher) => {
  try {
    if (cipher) {
      const bytes = CryptoJS.AES.decrypt(cipher, process.env.REACT_APP_KEY);
      const card = bytes.toString(CryptoJS.enc.Utf8);
      return card;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error in Wrong QR code Scanning ==>", error);
  }
};

export const groupItems = (array, property) => {
  const ggg = array.reduce((groups, item) => {
    const name = item[property]?.split("T")[0];
    // eslint-disable-next-line no-param-reassign
    const group = groups[name] || (groups[name] = []);
    group.push(item);
    return groups;
  }, {});
  const ordered = Object.keys(ggg)
    .sort()
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = ggg[key];
      return obj;
    }, {});
  return ordered;
};

export const debounce = (func, value, timeout = 300) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(value);
    }, timeout);
  };
};
