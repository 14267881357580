import React, { useEffect } from "react";
import jsQR from "jsqr";
import PropsTypes from "prop-types";
import socket from "../../../../config/socket";
import { DoDecrypt, DoEncrypt } from "../../../../utils";

let requestAnimationFrameData;
function QrScanner({
  handleCloseScanner,
  setCameraError,
  gameData,
  usedCards,
}) {
  //  const storedZoomValue = localStorage.getItem("zoom");

  useEffect(() => {
    const video = document.createElement("video");
    video.style.width = 520;
    // video.style.height = 345;
    // Apply zoom to the video element using the retrieved value
    // if (storedZoomValue) {
    //  video.style.transform = `scale(${parseFloat(storedZoomValue)})`;
    // }
    const canvasElement = document.getElementById("canvas");
    const canvas = canvasElement.getContext("2d", { willReadFrequently: true });

    function drawLine(begin, end, color) {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }
    async function tick() {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.hidden = false;
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(
          video,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const imageData = canvas.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          drawLine(
            code.location.topLeftCorner,
            code.location.topRightCorner,
            "#FF3B58"
          );
          drawLine(
            code.location.topRightCorner,
            code.location.bottomRightCorner,
            "#FF3B58"
          );
          drawLine(
            code.location.bottomRightCorner,
            code.location.bottomLeftCorner,
            "#FF3B58"
          );
          drawLine(
            code.location.bottomLeftCorner,
            code.location.topLeftCorner,
            "#FF3B58"
          );

          if (
            code.data
            && code.data.length === 2
            && !usedCards?.find((el) => DoDecrypt(el) === code.data)
          ) {
            usedCards.push(DoEncrypt(code.data));
            let socketType = "";
            const { id, runninground } = gameData;
            if (runninground === 1) {
              socketType = "scanCardForUser";
            } else {
              socketType = "scanCardForCummunity";
            }
            socket.emit(socketType, { gameId: id, card: DoEncrypt(code.data) });
          }
        }
      }
      requestAnimationFrameData = requestAnimationFrame(tick);
    }
    // Use facingMode: environment to attemt to get the front camera on phones
    if (navigator.mediaDevices) {
      navigator?.mediaDevices
        ?.getUserMedia({
          video: {
            facingMode: { ideal: "environment" },
            aspectRatio: { exact: 16 / 9 },
            deviceId:
              JSON.parse(localStorage.getItem("communityCamera"))?.value || "",
          },
        })
        .then((stream) => {
          video.srcObject = stream;
          video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          video.play();
          requestAnimationFrameData = requestAnimationFrame(tick);
        })
        .catch(() => {
          setCameraError(true);
        });
    } else {
      setCameraError(true);
    }
    return () => {
      cancelAnimationFrame(requestAnimationFrameData);
    };
  }, []);

  useEffect(() => {
    socket.on("updateGame", (data) => {
      if (data.game.status === "completed") {
        handleCloseScanner();
      }
    });
  }, []);
  return <canvas id="canvas" hidden />;
}

QrScanner.propTypes = {
  handleCloseScanner: PropsTypes.func.isRequired,
  setCameraError: PropsTypes.func.isRequired,
  gameData: PropsTypes.object.isRequired,
  usedCards: PropsTypes.array.isRequired,
};
export default QrScanner;
