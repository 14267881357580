import { useContext } from "react";
import Lottie from "react-lottie";
import PropsTypes from "prop-types";
import Player from "./player";
import CameraOne from "./cameraOne";
import UserContext from "../../../context/UserContext";
import PlayerAction from "./PlayerActions";
import pauseicon from "../../../assets/images/playerview/pause.json";
import scanicon from "../../../assets/images/playerview/scanwhite.json";
import PlayerBidTimerAmount from "./bidTimerAmount";

const PauseAnimation = {
  loop: true,
  autoplay: true,
  animationData: pauseicon,
};

const ScanAnimation = {
  loop: true,
  autoplay: true,
  animationData: scanicon,
};

function PlayersView({
  playersLeft,
  playersRight,
  action,
  currentPlayer,
  timer,
  remainingTime,
  showCards,
  recentPlayer,
  winners,
  handleOneToOneRequest,
  oneToOneParticipant,
  openAction,
  setOpenAction,
  bet,
  setBet,
  raise,
  setRaise,
  setTimer,
  dealer,
  gamePaused,
  handleStopSound,
  timerType,
  gameData,
}) {
  const { user,
  } = useContext(UserContext);

  const getGamePauseContent = () => {
    switch (gamePaused) {
      case "gamePaused":
        return (
          <div className="layer-game-not-started-content pause-animation">
            <Lottie options={PauseAnimation} />
          </div>
        );
      case "usercardScanning":
        return (
          <div className="layer-game-not-started-content">
            <Lottie options={ScanAnimation} />
          </div>
        );
      case "communityCardScanning":
        return (
          <div className="layer-game-not-started-content">
            <Lottie options={ScanAnimation} />
          </div>
        );
      default:
        return "";
    }
  };
  return (
    <div className="player-game-grid">
      <div
        className={`player-game-view-grid player-game-left-view player-length-${playersLeft?.length}`}
      >
        {playersLeft?.map((player, i) => (
          <Player
            user={player}
            action={action}
            key={player.id}
            currentPlayer={currentPlayer}
            timer={timer}
            remainingTime={remainingTime}
            showCards={showCards}
            recentPlayer={recentPlayer}
            winners={winners}
            handleOneToOneRequest={handleOneToOneRequest}
            oneToOneParticipant={oneToOneParticipant}
            gameData={gameData}
            playerIndex={i}
          />
        ))}
      </div>
      <div className="player-game-dealing">
        <div
          className={`player-camera view-1 ${
            !playersLeft?.concat(playersRight)?.find((us) => us.id === user.id)
              ? "dealer-camera-view"
              : ""
          }`}
        >
          <CameraOne
            participant={dealer}
            player={{ id: "dealer", role: "dealer" }}
            gameData={gameData}
          />
          <div className="player-game-not-started">{getGamePauseContent()}</div>
        </div>
        <PlayerBidTimerAmount gameData={gameData} />

        {playersLeft.concat(playersRight).find((us) => us.id === user.id) ? (
          <div
            className={`player-game-action ${
              timer
              && currentPlayer.id === user.id
              && !currentPlayer?.tentativeAction
                ? "action-timer-added"
                : ""
            }`}
          >
            <PlayerAction
              openAction={openAction}
              setOpenAction={setOpenAction}
              bet={bet}
              setBet={setBet}
              raise={raise}
              setRaise={setRaise}
              currentPlayer={currentPlayer}
              setTimer={setTimer}
              remainingTime={remainingTime}
              gameData={gameData}
              handleStopSound={handleStopSound}
              timer={timer}
              player={playersLeft
                .concat(playersRight)
                .find((el) => el.id === user.id)}
            />
          </div>
        ) : (
          ""
        )}

      </div>

      <div
        className={`player-game-view-grid player-game-right-view player-length-${playersRight.length}`}
      >
        {playersRight.map((player, i) => (
          <Player
            user={player}
            key={player.id}
            action={action}
            currentPlayer={currentPlayer}
            timer={timer}
            remainingTime={remainingTime}
            showCards={showCards}
            recentPlayer={recentPlayer}
            winners={winners}
            handleOneToOneRequest={handleOneToOneRequest}
            oneToOneParticipant={oneToOneParticipant}
            gameData={gameData}
            timerType={timerType}
            playerIndex={i}
          />
        ))}
      </div>
    </div>
  );
}

PlayersView.defaultProps = {
  currentPlayer: {},
  remainingTime: 0,
  recentPlayer: {},
  oneToOneParticipant: null,
  dealer: {},
  gameData: {},
  timerType: "timer",
  timer: 15,
};
PlayersView.propTypes = {
  oneToOneParticipant: PropsTypes.object,
  currentPlayer: PropsTypes.object,
  remainingTime: PropsTypes.number,
  showCards: PropsTypes.bool.isRequired,
  recentPlayer: PropsTypes.object,
  winners: PropsTypes.array.isRequired,
  action: PropsTypes.object.isRequired,
  handleOneToOneRequest: PropsTypes.func.isRequired,
  playersLeft: PropsTypes.array.isRequired,
  playersRight: PropsTypes.array.isRequired,
  timer: PropsTypes.number,
  openAction: PropsTypes.object.isRequired,
  setOpenAction: PropsTypes.func.isRequired,
  bet: PropsTypes.bool.isRequired,
  raise: PropsTypes.bool.isRequired,
  setBet: PropsTypes.func.isRequired,
  setRaise: PropsTypes.func.isRequired,
  setTimer: PropsTypes.func.isRequired,
  gamePaused: PropsTypes.string.isRequired,
  dealer: PropsTypes.object,
  gameData: PropsTypes.object,
  handleStopSound: PropsTypes.func.isRequired,
  timerType: PropsTypes.string,
};

export default PlayersView;
