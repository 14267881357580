import PropsTypes from "prop-types";
import potcard from "../../../../assets/images/dealer/pot-card.svg";
import { client } from "../../../../config/keys";
import { DoDecrypt } from "../../../../utils";

function PotCard({ pot, communityCard, sidePots }) {
  const sidePot1 = sidePots?.slice(1, Math.ceil(sidePots.length / 2));
  const sidePot2 = sidePots?.slice(Math.ceil(sidePots.length / 2));
  return (
    <div className="pot-card">
      <div className="pot-grid">
        {[0, 1, 2, 3, 4].map((i) => (
          <img
            key={i}
            src={
              communityCard[i]
                ? `${client}main-view-cards/${DoDecrypt(communityCard[i])}.svg`
                : potcard
            }
            alt=""
          />
        ))}
      </div>
      {sidePots.length ? (
        <div className="pot-count main-pot-count">
          <h5>main pot</h5>
          <h4>
            $
            {sidePots[0]?.pot}
          </h4>
        </div>
      ) : (
        <div className="pot-count main-pot-count">
          <h5>main pot</h5>
          <h4>
            $
            {pot}
          </h4>
        </div>
      )}

      {sidePot1.length ? (
        <div className="pot-count side-pot-1">
          <h5>side pot</h5>
          <div className="side-pot-amount">
            {sidePot1?.map((sidePot) => (
              <h4>
                $
                {sidePot.pot}
              </h4>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}

      {sidePot2.length ? (
        <div className="pot-count side-pot-2">
          <h5>side pot</h5>
          <div className="side-pot-amount">
            {sidePot2?.map((sidePot) => (
              <h4>
                $
                {sidePot.pot}
              </h4>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

PotCard.defaultProps = {
  pot: 0,
  communityCard: [],
  sidePots: [],
};

PotCard.propTypes = {
  pot: PropsTypes.number,
  communityCard: PropsTypes.array,
  sidePots: PropsTypes.array,
};

export default PotCard;
