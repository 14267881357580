/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from "react";
import DailyIframe from "@daily-co/daily-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import { DailyProvider } from "@daily-co/daily-react";
import { Modal } from "react-bootstrap";
import logo from "../../../assets/images/waitingroom/Logo.svg";
import "./style.css";
import loadericon from "../../../assets/images/dealer/loader.json";

import UserContext from "../../../context/UserContext";
import WaitingRoomCamera from "./waitingroom";

import socket from "../../../config/socket";
import WaitingRoomAction from "./WaitingRoomAction";
import JoinErrorModal from "../waitingRoomPopup/joinErrorModal";
import Sidebar from "../../layout/sidebar";
import CreateProfileModal from "../../profile/registerProfileModal";
import intSignConvert from "../../../utils/intSignConvert";

let game;
function WaitingRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const { gameId } = useParams();
  const [gameData, setGameData] = useState({});
  const [joinError, setJoinError] = useState(false);
  const [waitingPlayers, setWaitingPlayers] = useState([]);
  const [callObject, setCallObject] = useState();

  const LoaderAnimation = {
    loop: true,
    autoplay: true,
    animationData: loadericon,
  };
  const { user, showProfileModal, setShowProfileModal } = useContext(UserContext);

  const handleCloseJoinError = () => {
    navigate("/");
  };

  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, []);

  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);
  const [topValue, setTopValue] = useState(initialTop);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
    setTopValue(initialTop);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
    setTopValue(
      (100 - currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2)) / 2
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End

  useEffect(() => {
    socket.on("connect", () => {
      // eslint-disable-next-line no-console
      console.log("connected");
      socket.emit("checkTable", {
        gameId,
        userId: user.id,
      });
    });
    socket.on("wt-update", (data) => {
      setIsLoading(false);
      localStorage.removeItem("timerCount");
      if (!game) {
        setGameData(data.game);
      }
      game = data.game;
      setWaitingPlayers(game.waitingPlayers);
    });
    socket.on("waitingRoomFull", () => {
      setJoinError(true);
      setIsLoading(false);
    });

    socket.on("updateGame", (data) => {
      if (!game) {
        setGameData(data.game);
      }
      game = data.game;
      setWaitingPlayers(game?.waitingPlayers);
      if (data?.game?.players?.find((el) => el.id === user.id)) {
        window.location.href = `/user/main-game/${gameId}`;
      }
      if (data?.profileUpdate) {
        setShowProfileModal(false);
      }
    });
    socket.on("notFound", (data) => {
      if (
        data.message === "Game not found. Either game is finished or not exist"
      ) {
        navigate("*");
      }
    });
    if (game?.waitingPlayers?.find((pl) => pl.id === user?.id)) {
      localStorage.removeItem("timerCount");
    }
    return () => {
      socket.off("updateGame");
      socket.off("wt-update");
    };
  }, []);

  useEffect(() => {
    if (user) {
      socket.emit("checkTable", { gameId, userId: user.id, waiting: true });
    }
    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          } else {
            socket.emit("checkTable", { gameId, userId: user.id });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
    socket.on("connect", () => {
      socket.emit("checkTable", { gameId, userId: user.id });
    });
  }, []);

  useEffect(() => {
    let newCallObject;
    const getJoin = async () => {
      newCallObject = DailyIframe.createCallObject({
        dailyConfig: {
          userMediaVideoConstraints: {
            deviceId: JSON.parse(localStorage.getItem("camera"))?.value,
          },
          userMediaAudioConstraints: {
            deviceId: JSON.parse(localStorage.getItem("mic"))?.value,
          },
        },
      });
      setCallObject(newCallObject);
      const meettoken = game?.waitingPlayers?.find(
        (el) => el.id === user?.id
      )?.meetingToken;
      const userName = user?.id;
      newCallObject.join({
        url: `https://cloudpokernight.daily.co/w-${gameId}`,
        token: meettoken,
        userName,
        dailyConfig: {
          userMediaVideoConstraints: {
            deviceId: JSON.parse(localStorage.getItem("camera"))?.value,
          },
          userMediaAudioConstraints: {
            deviceId: JSON.parse(localStorage.getItem("mic"))?.value,
          },
        },
        startVideoOff:
            JSON.parse(localStorage.getItem("isCameraOn")) !== null
              ? !JSON.parse(localStorage.getItem("isCameraOn"))
              : false,
        startAudioOff:
            JSON.parse(localStorage.getItem("isMicOn")) !== null
              ? !JSON.parse(localStorage.getItem("isMicOn"))
              : false,
      });
    };
    if (game?.waitingPlayers?.length) {
      getJoin();
    }
    return () => {
      newCallObject?.leave();
    };
  }, [gameData]);

  const [showSidebar, setShowSidebar] = useState(false);
  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="waiting-room-content-container">
      <Sidebar showSidebar={showSidebar} handleSidebar={handleSidebar} />
      <div className="container">
        {callObject ? (
          <DailyProvider callObject={callObject}>
            <div
              className="waiting-room-content"
              style={{
                transform: `translate(-50%, ${intSignConvert(
                  topValue
                )}%) scale(${scaleValue / 100})`,
              }}
            >
              <div className="waiting-room-head">
                <div className="waiting-logo">
                  <span onClick={handleSidebar} role="presentation">
                    <img src={logo} alt="logo" />
                  </span>
                </div>
                <div className="waiting-text">
                  <h4>Waiting for all players to join!</h4>
                </div>
                <WaitingRoomAction gameId={gameId} />
              </div>

              {isLoading ? (
                <div className="loader">
                  <Lottie options={LoaderAnimation} />
                </div>
              ) : (
                <WaitingRoomCamera
                  waitingPlayers={waitingPlayers}
                  videoSrc={game?.videoTrack || ""}
                />
              )}
            </div>
          </DailyProvider>
        ) : (
          ""
        )}
      </div>
      <JoinErrorModal
        joinError={joinError}
        handleJoinError={handleCloseJoinError}
      />
      <Modal
        show={showProfileModal}
        className="bar-room-setting-popup cpn-qr-popup"
        centered
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
      >
        {/* <span
          onClick={hideModal}
          className="close-model-icon"
          role="presentation"
        >
          <ClossIcon />
        </span> */}
        <CreateProfileModal />
      </Modal>
    </div>
  );
}
export default WaitingRoom;
