import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../../assets/images/dealer/logo.svg";
import { ForgetPasswordSchema } from "../../validationSchema/authSchema";
import Success from "../toaster/success";
import Error from "../toaster/erros";
import { forgetPassword } from "../../utils/Api";

function Forgot() {
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ForgetPasswordSchema) });

  const handleForget = async (values) => {
    setIsLoading(true);
    const res = await forgetPassword(values);
    setIsLoading(false);
    if (res.code === 200) {
      setResult("success");
      setMessage("You will receive an email to recover your password.");
    } else {
      setResult("fail");
      setMessage(res.message);
    }
  };
  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* Signup Form */}

          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logo} alt="" />
            </div>
            {result === "success" ? (
              <Success message={message} />
            ) : result === "fail" ? (
              <Error message={message} />
            ) : (
              ""
            )}

            <Form onSubmit={handleSubmit(handleForget)}>
              <div className="full-width">
                <Form.Group className="form-group">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    className={errors.email ? "error-field" : ""}
                    {...register("email")}
                  />
                  {errors?.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>

              <div className="signup-btn">
                <Button type="submit">
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    "Forgot Password"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
