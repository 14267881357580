/* eslint-disable jsx-a11y/media-has-caption */
import PropsTypes from "prop-types";
import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AutomatedCommunityCard from "./automatedCommunityCard";
import socket from "../../../config/socket";

function CommunityCameraView({ tracks, gameData }) {
  const { gameId } = useParams();
  const videoRef = useRef();
  const customTrack = tracks["community-card"] || {};
  const [videoZoom, setVideoZoom] = useState(
    localStorage.getItem("communityVideoZoom")
      ? Number(localStorage.getItem("communityVideoZoom"))
      : 1
  );

  useEffect(() => {
    socket.on("communityVideoZoom", (data) => {
      if (data.gameId === gameId) {
        const zoomValue = Number(data.value);
        setVideoZoom(zoomValue);
        localStorage.setItem("communityVideoZoom", `${data.value}`);
      }
    });
  }, []);

  useEffect(() => {
    if (!customTrack.persistentTrack) return;
    if (videoRef?.current) {
      videoRef.current.srcObject = new MediaStream([
        customTrack?.persistentTrack,
      ]);
    }
  }, [
    customTrack.isOff,
    customTrack.state,
    customTrack.persistentTrack,
    videoRef.current,
    customTrack.subscribed,
  ]);

  return !customTrack?.isOff ? (
    <div className="player-camera-box community-card-cameraTow-view">
      <video
        style={{
          width: "100%",
          transform: `translateX(-50%) scale(${videoZoom}`,
        }}
        ref={videoRef}
        id="community-camera"
        autoPlay
        playsInline
        controls={false}
      />
    </div>
  ) : (
    <AutomatedCommunityCard gameData={gameData} />
  );
}

CommunityCameraView.defaultProps = {
  tracks: {},
  gameData: {},
};

CommunityCameraView.propTypes = {
  tracks: PropsTypes.object,
  gameData: PropsTypes.object,
};

export default CommunityCameraView;
