import { TexasHoldem } from "poker-odds-calc";
import { DoDecrypt } from ".";

export const addPlayerOnTable = (players, Table) => {
  players.forEach((player) => {
    if (player.cards.length === 2 && !player.fold) {
      Table.addPlayer(
        player.cards?.map(
          (card) => DoDecrypt(card)[0] + DoDecrypt(card)[1].toLowerCase()
        )
      );
    }
  });
};

export const updateCommunityCard = (cards, Table) => {
  const encryptedCards = cards?.map((card) => DoDecrypt(card));
  Table.setBoard(
    encryptedCards?.map((card) => card[0] + card[1].toLowerCase())
  );
};

export const getResult = (gameData) => {
  let players = [];

  if (gameData.runninground === 0) {
    players = gameData.players;
  } else if (gameData.runninground === 1) {
    players = gameData.preflopround;
  } else if (gameData.runninground === 2) {
    players = gameData.flopround;
  } else if (gameData.runninground === 3) {
    players = gameData.turnround;
  } else if (gameData.runninground === 4) {
    players = gameData.riverround;
  } else if (gameData.runninground === 5) {
    players = gameData.showdown;
  }
  const a = players?.filter((player) => player.cards.length === 2 && !player.fold)
    .length > 1;
  if (players?.length > 1 && a) {
    const Table = new TexasHoldem();
    addPlayerOnTable(players, Table);
    if (gameData.communityCard?.length >= 3) {
      updateCommunityCard(gameData?.communityCard, Table);
    }
    const result = Table.calculate();
    return result.getPlayers();
  }
  return [];
};
