import { useDaily, useDevices } from "@daily-co/daily-react";
import PropsTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import LeaveRoomModal from "../waitingRoomPopup/leaveRoomModal";
import MicIcon from "../../../IconComponents/MicIcon";
import MicOffIcon from "../../../IconComponents/MicOffIcon";
import ArrowIcon from "../../../IconComponents/ArrowIcon";
import CameraIcon from "../../../IconComponents/CamIcon";
import CamOffIcon from "../../../IconComponents/CamOffIcon";
import useOutsideAlerter from "../../../utils/outSideAlert";

function WaitingRoomAction({ gameId }) {
  const camDropRef = useRef(null);
  const micDropRef = useRef(null);
  const call = useDevices();
  const navigation = useNavigate();
  const {
    user,
    noiseCancellation,
    blurBackGround,
    userCamera,
    userMic,
    userSpeaker,
  } = useContext(UserContext);
  const callObject = useDaily();
  const [leaveRoom, setLeaveRoom] = useState(false);
  const [micLevel, setMicLevel] = useState(45);
  const [showDrop, setShowDrop] = useState("");
  const [microphoneOptions, setMicrophoneOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);

  const remoteparticipant = callObject?.participants()?.local || {};
  const [micOn, setMicOn] = useState(
    JSON.parse(localStorage.getItem("isMicOn")) !== null
      ? JSON.parse(localStorage.getItem("isMicOn"))
      : true
  );
  const [camOn, setCamOn] = useState(
    JSON.parse(localStorage.getItem("isCameraOn")) !== null
      ? JSON.parse(localStorage.getItem("isCameraOn"))
      : true
  );

  useEffect(() => {
    if (userMic?.value) {
      call.setMicrophone(userMic?.value);
    }
  }, [userMic?.value]);

  useEffect(() => {
    if (userCamera?.value) {
      call.setCamera(userCamera?.value);
    }
  }, [userCamera?.value]);

  useEffect(() => {
    if (userSpeaker?.value) {
      call.setSpeaker(userSpeaker?.value);
    }
  }, [userSpeaker?.value]);

  const toggleLeavePopup = () => {
    setLeaveRoom(!leaveRoom);
  };

  const handleMic = () => {
    setMicOn(!remoteparticipant.audio);
    localStorage.setItem("isMicOn", !remoteparticipant.audio);
    socket.emit("playerMicChange", {
      gameId,
      userId: user.id,
      isAudio: !remoteparticipant.audio,
    });
  };

  const handleSocketMictrigger = (data) => {
    callObject.updateParticipant(remoteparticipant.session_id, {
      setAudio: data.isAudio,
    });
  };

  const handleSocketCamTrigger = (data) => {
    callObject.updateParticipant(remoteparticipant.session_id, {
      setVideo: data.isVideo,
    });
  };

  const handleCamera = () => {
    setCamOn(!remoteparticipant.video);
    localStorage.setItem("isCameraOn", !remoteparticipant.video);
    socket.emit("playerCameraChange", {
      gameId,
      userId: user.id,
      isVideo: !remoteparticipant.video,
    });
  };

  const handleLeaveTable = async () => {
    socket.emit("leaveWatingRoom", { gameId, userId: user.id });
    await callObject.leave();
    navigation("/");
  };

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }
    setShowDrop(name);
  };

  useEffect(() => {
    const getMediaDevices = async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const videoCameras = allDevices.filter((m) => m.kind === "videoinput");
      const microphones = allDevices.filter((m) => m.kind === "audioinput");
      //  const speakers = allDevices.filter((m) => m.kind === "audiooutput");
      setCameraOptions(
        videoCameras.map((camera) => ({
          label: camera.label,
          value: camera.deviceId,
        }))
      );
      setMicrophoneOptions(
        microphones.map((mic) => ({ label: mic.label, value: mic.deviceId }))
      );
    };
    getMediaDevices();
  }, []);

  useEffect(() => {
    if (user.role !== "dealer" && remoteparticipant.video) {
      callObject.updateInputSettings({
        video: {
          processor: {
            type: blurBackGround || "background-image",
            config: {
              source: `${window.location.origin}/backgrounds/greyBg.png`,
              fit: "fit",
            },
          },
        },
        audio: {
          processor: {
            type: JSON.parse(localStorage.getItem("noise-off"))
              ? "noise-cancellation"
              : "none",
          },
        },
      });
    }
  }, [remoteparticipant.video, noiseCancellation]);

  useEffect(() => {
    socket.on("playerMicChange", (data) => {
      if (data.userId === user.id) {
        localStorage.setItem("isMicOn", data.isAudio);
        handleSocketMictrigger(data);
      }
    });

    socket.on("playerCameraChange", (data) => {
      if (data.userId === user.id) {
        // localStorage.setItem("isCameraOn", data.isVideo);
        handleSocketCamTrigger(data);
      }
    });
    return () => {
      socket.off("playerCameraChange");
      socket.off("playerMicChange");
    };
  }, [remoteparticipant]);

  useOutsideAlerter(
    showDrop === "camera" ? camDropRef : showDrop === "mic" ? micDropRef : "",
    setShowDrop
  );

  return (
    <div className="waiting-btn">
      <div className="waiting-con-btn">

        <MicSetting
          micOn={micOn}
          handleMic={handleMic}
          micLevel={micLevel}
          setMicLevel={setMicLevel}
          handleSetting={handleSetting}
          showDrop={showDrop}
          microphoneOptions={microphoneOptions}
          micDropRef={micDropRef}
        />

        <CamSetting
          camOn={camOn}
          handleCamera={handleCamera}
          handleSetting={handleSetting}
          showDrop={showDrop}
          cameraOptions={cameraOptions}
          camDropRef={camDropRef}
        />
      </div>
      <Button className="waiting-leave-btn" onClick={toggleLeavePopup}>
        leave
      </Button>
      <LeaveRoomModal
        visible={leaveRoom}
        toggleLeavePopup={toggleLeavePopup}
        handleLeaveTable={handleLeaveTable}
      />
    </div>
  );
}

export default WaitingRoomAction;

WaitingRoomAction.propTypes = {
  gameId: PropsTypes.string.isRequired,
};

function MicSetting({
  micOn,
  handleMic,
  micLevel,
  setMicLevel,
  handleSetting,
  showDrop,
  microphoneOptions,
  activeTab,
  isExpanded,
  micDropRef,
}) {
  const { userMic, setUserMic } = useContext(UserContext);
  const handleUserMic = (value) => {
    setUserMic(value);
    localStorage.setItem("mic", JSON.stringify(value));
  };
  return (
    <div className="setting-btn-group" ref={micDropRef}>
      <span className="setting-btn">
        <span onClick={() => handleMic()} role="presentation">
          {micOn ? <MicIcon /> : <MicOffIcon />}
        </span>
        <span
          className={`btn-down-icon ${showDrop === "mic" ? "rotate" : ""}`}
          onClick={() => handleSetting("mic")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
      </span>
      <div className={`drop-wrapper ${showDrop === "mic" ? "show" : "hide"}`}>
        {microphoneOptions?.map((m) => (
          <div className="drop-item" key={m.label}>
            <div className="invite-user-action">
              <Form.Check
                className="add-check-box"
                type="radio"
                id={m.label}
                name="mic-action"
                label={m.label}
                value={m.deviceId}
                defaultChecked={m.value === userMic?.value}
                onChange={() => handleUserMic(m)}
              />
            </div>
          </div>
        ))}
        <div className="drop-item-divider" />
        <div className="volume-control-wrapper">
          <div className="slider-title">
            <span>Input volume</span>
          </div>
          <div className="volume-range-main">
            <input
              className="volume-input-slider"
              type="range"
              min="0"
              max="100"
              value={micLevel}
              onChange={(e) => setMicLevel(e.target.value)}
              id="slider"
            />
            <div
              id="selector"
              //  style={{ left: `${micLevel <= 7 ? 7 : micLevel}%` }}
              style={{
                left: `${
                  activeTab === "multi-table" && isExpanded && micLevel < 3
                    ? 2
                    : micLevel <= 7
                      ? 7
                      : micLevel
                }%`,
              }}
            >
              <div
                className="volume-range-selectBtn"
                style={{
                  left: `${micLevel <= 12 ? 0 : micLevel >= 96 ? 0 : 12}px`,
                }}
              />
              <div
                className="selectValue "
                style={{
                  left: `${micLevel <= 12 ? 50 : micLevel >= 96 ? 50 : 100}%`,
                }}
              >
                {micLevel}
                %
              </div>
            </div>
            <div id="progressBar" style={{ width: `${micLevel}%` }} />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
MicSetting.defaultProps = {
  micOn: true,
  showDrop: "",
  isExpanded: false,
  activeTab: "",
};

MicSetting.propTypes = {
  micOn: PropsTypes.bool,
  handleMic: PropsTypes.func.isRequired,
  setMicLevel: PropsTypes.func.isRequired,
  micLevel: PropsTypes.number.isRequired,
  showDrop: PropsTypes.string,
  handleSetting: PropsTypes.func.isRequired,
  microphoneOptions: PropsTypes.array.isRequired,
  isExpanded: PropsTypes.bool,
  activeTab: PropsTypes.string,
  micDropRef: PropsTypes.object.isRequired,
};

export function CamSetting({
  camOn,
  handleCamera,
  handleSetting,
  showDrop,
  cameraOptions,
  camDropRef,
}) {
  const { userCamera, setUserCamera } = useContext(UserContext);
  const call = useDevices();

  const selectedCamera = call.cameras.find((cam) => cam.selected)?.device
    .deviceId;

  const handleCameraChange = (value) => {
    setUserCamera(value);
    localStorage.setItem("camera", JSON.stringify(value));
  };
  return (
    <div className="setting-btn-group" ref={camDropRef}>
      <span className="setting-btn">
        <span onClick={() => handleCamera()} role="presentation">
          {camOn ? <CameraIcon /> : <CamOffIcon />}
        </span>
        <span
          className={`btn-down-icon ${showDrop === "camera" ? "rotate" : ""}`}
          onClick={() => handleSetting("camera")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
      </span>
      <div
        className={`drop-wrapper ${showDrop === "camera" ? "show" : "hide"}`}
      >
        {cameraOptions.map((camera) => (
          <div className="drop-item" key={camera.label}>
            <div className="invite-user-action">
              <Form.Check
                className="add-check-box"
                type="radio"
                id={camera.label}
                name="camera-action"
                label={camera.label}
                value={camera.value}
                checked={
                  camera.value === userCamera?.value
                  || selectedCamera === camera.value
                }
                onChange={() => handleCameraChange(camera)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
CamSetting.defaultProps = {
  camOn: true,
  showDrop: "",
};

CamSetting.propTypes = {
  camOn: PropsTypes.bool,
  handleCamera: PropsTypes.func.isRequired,
  showDrop: PropsTypes.string,
  handleSetting: PropsTypes.func.isRequired,
  cameraOptions: PropsTypes.array.isRequired,
  camDropRef: PropsTypes.object.isRequired,
};
