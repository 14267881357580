import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { LoginSchema } from "../../validationSchema/authSchema";
import { login } from "../../utils/Api";
import logo from "../../assets/images/dealer/logo.svg";
import UserContext from "../../context/UserContext";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { setTokens, setUser, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user) {
      const redirect = queryParams.get("redirect");
      if (redirect) {
        navigate(redirect);
      } else if (
        user.role === "user"
        && (!user.compnayName
          || !user.twitter
          || !user.instagram
          || !user.title
          || !user.aboutMe)
      ) {
        navigate("/user/profile");
      } else if (
        user.role === "user"
        && user.compnayName
        && user.twitter
        && user.instagram
        && user.title
        && user.aboutMe
      ) {
        navigate("/user");
      } else if (user && user.role === "dealer") {
        navigate("/dealer");
      }
    }
  }, [user, navigate]);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) });

  const loginUser = async (values) => {
    setIsLoading(true);
    const res = await login(values);
    setIsLoading(false);
    if (res.message === "Incorrect email or password") {
      setError(
        "email",
        { type: "custom", message: "Invalid credentials" },
        { shouldFocus: true }
      );
      setError(
        "password",
        { type: "custom", message: "Invalid credentials" },
        { shouldFocus: true }
      );
    } else if (res.user) {
      localStorage.setItem("token", JSON.stringify(res.tokens));
      setTokens(res.tokens);
      if (res.user) {
        setUser(res.user);
        const redirect = queryParams.get("redirect") || localStorage.getItem("redirectLink");
        if (redirect && res?.user?.role !== "dealer") {
          navigate(redirect);
          localStorage.removeItem("redirectLink");
        } else if (user.role === "user") {
          navigate(`${user.role}/profile`);
        } else if (user.role === "dealer") {
          navigate("/dealer");
        } else navigate("/");
      }
    }
  };
  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* Signup Form */}

          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logo} alt="" />
            </div>
            <div className="game-cp-wrapper">
              <h4>cloud poker night</h4>
            </div>
            <Form onSubmit={handleSubmit(loginUser)}>
              <div className="full-width">
                <Form.Group className="form-group">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    className={errors.email ? "error-field" : ""}
                    {...register("email")}
                  />
                  {errors?.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              <div className="full-width">
                <Form.Group className="form-group">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    className={errors.password ? "error-field" : ""}
                    {...register("password")}
                  />
                  {errors?.password ? (
                    <p className="error-text">{errors.password.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>

              <div className="signup-btn">
                <Button type="submit">
                  {isLoading ? <Spinner animation="border" /> : "Login"}
                </Button>
              </div>
            </Form>
            <p>
              Forgot your password?
              {" "}
              <Link to="/forgot">Click here</Link>
            </p>
            <p>
              Don’t have an account?
              {" "}
              <Link to="/signup">Sign up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
