import PropsTypes from "prop-types";
import HandHistoryIcon from "../../../IconComponents/HandHistoryIcon";
// import socket from "../../../config/socket";

function HandHistoryTab({ allGameHands, handCounts, tableData }) {
  const customReverse = (winnersHand) => {
    const arr = [];
    // eslint-disable-next-line for-direction
    for (let i = winnersHand.length - 1; i >= 0; i -= 1) {
      arr.push(winnersHand[i]);
    }
    return arr;
  };

  const handleViewCards = (hand) => {
    const winnerCard = hand?.winnerHand?.filter((el) =>
      hand?.winnerCards?.includes(el)
    );
    const winnerHandCards = hand?.winnerHand?.filter(
      (el) => !hand?.winnerCards?.includes(el)
    );
    return (
      <>
        <div
          className={`hand-history-card-wrapper ${
            winnerCard?.length > 0 ? "highlighted-winner-cards" : ""
          }`}
        >
          {winnerCard?.map((card, i) => (
            <img
              src={`/main-view-cards/${card}.svg`}
              alt={card}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
        </div>
        {winnerHandCards?.map((card, i) => (
          <img
            src={`/main-view-cards/${card}.svg`}
            alt={card}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))}
      </>
    );
  };

  return (
    <div className="chat-wrapper hand-history-wrapper">
      <div className="tab-card-head">
        <HandHistoryIcon />
        <h4>
          Hand history
          <span>{handCounts}</span>
        </h4>
      </div>
      <div className="tab-card-body">
        {allGameHands?.map((el, ind) =>
          (el?.winnersHand?.length > 0 ? (
            <>
              <div className="tab-card-element">
                <p className="element-title">
                  {tableData === "All Tables" ? `Table #${ind + 1}` : tableData}
                </p>
              </div>
              {customReverse(el.winnersHand)?.map((hand, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="tab-card-element" key={hand.id + index}>
                  <div className="hand-history-head">
                    <div className="hand-history-head-left">
                      <span className="hand-count">{hand?.handCount}</span>
                      <div className="hand-history-profile-wrapper">
                        <img
                          src={hand?.avatar}
                          onError={({ currentTarget }) => {
                            // eslint-disable-next-line no-param-reassign
                            currentTarget.onerror = null; // prevents looping
                            // eslint-disable-next-line no-param-reassign
                            currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                          }}
                          alt={hand?.name}
                        />
                        <div className="hand-winner-name">
                          <p>{hand?.name}</p>
                          <p className="hand-name">
                            {hand?.handName ? hand?.handName : "All-Folded"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="hand-history-head-right">
                      <div className="hand-pot-details">
                        <p>Pot:</p>
                        <span>
                          $
                          {hand?.winningAmount}
                        </span>
                      </div>
                      {/* <div className="hand-history-card-wrapper">
                        {hand?.handName
                          ? hand?.winnerCards.map((card, i) => (
                            <img
                              src={`/main-view-cards/${card}.svg`}
                              alt={card}
                                // eslint-disable-next-line react/no-array-index-key
                              key={i}
                            />
                          ))
                          : ""}
                      </div> */}
                    </div>
                  </div>

                  <div className="hand-history-card-wrapper">
                    {/* {hand?.communityCards.map((card, i) => (
                      <img
                        src={`/main-view-cards/${card}.svg`}
                        alt={card}
                          // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      />
                    ))} */}
                    {handleViewCards(hand)}
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          ))
        )}
      </div>
    </div>
  );
}
HandHistoryTab.defaultProps = {
  allGameHands: [],
  handCounts: 0,
  tableData: "",
};
HandHistoryTab.propTypes = {
  allGameHands: PropsTypes.array,
  handCounts: PropsTypes.number,
  tableData: PropsTypes.string,
};

export default HandHistoryTab;
