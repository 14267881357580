import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import "./waitingRoomModal.css";

function LeaveRoomModal({ visible, toggleLeavePopup, handleLeaveTable }) {
  return (
    <div className="joinErrorModal">
      <Modal
        show={visible}
        onHide={toggleLeavePopup}
        centered
        className="bar-room-leave-popup joinErrorModal"
      >
        <div className="bar-room-leave">
          <p>Are you sure you want to leave the waiting room?</p>
          <div className="leaveRoomButtons">
            <Button className="joinErrorModal-btn" onClick={handleLeaveTable}>
              <div>Leave</div>
            </Button>
            <Button className="joinErrorModal-borderBtn" onClick={toggleLeavePopup}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
LeaveRoomModal.propTypes = {
  visible: PropsTypes.bool.isRequired,
  toggleLeavePopup: PropsTypes.func.isRequired,
  handleLeaveTable: PropsTypes.func.isRequired,
};
export default LeaveRoomModal;
