import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import PropsTypes from "prop-types";
// import CopyToClipboard from "react-copy-to-clipboard";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import arrow from "../../../assets/images/inrovideo/arrow.svg";
import datearrow from "../../../assets/images/game/date-arrow.svg";
import { addGameInEvent, deleteGameFromEvent, getAllUsers, updateGameEvent } from "../../../utils/Api";
import { updateGameEventSchema } from "../../../validationSchema/gameSchema";
import "./detailPopup.css"
import DetailTable from "./detailTable";
import DeletePopup from "./deletePopup";

function DetailPopup({ game: g, handleDeleteGame, handleClose }) {
  const [game, setGame] = useState(g);

  const [setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch
  } = useForm({ resolver: yupResolver(updateGameEventSchema) });
  const scheduledDate = watch("scheduledDate") ? new Date(watch("scheduledDate")) : new Date(game?.scheduleDate);
  const sceduleTime = watch("time") ? watch("time") : game?.time;
  const timezone = watch("timezone") ? watch("timezone") : game?.timezone;
  const [icreasingBlindMinutes, setIncreasing] = useState("");

  useEffect(() => {
    getAllUsers(setUsers);
    setValue("buyin", game?.buyin);
    setValue("smallBlind", game?.smallBlind);
    setValue("time", game?.time);
    setValue("timezone", game?.timezone);
    setValue("scheduledDate", new Date(game?.scheduleDate));
    setValue("noOfTables", game?.games?.length);
    setValue("icreasingBlindMinutes", game?.icreasingBlindMinutes);
    setValue("logoUrl", game?.companyLogo ? game?.companyLogo : "");
    setValue("gameBanner", game?.gameBanner ? game?.gameBanner : "");
    setIncreasing(game?.icreasingBlindMinutes);
  }, [game]);
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    const inputElements = document.querySelectorAll("input[type='number']");

    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleScroll, { passive: false });
    });

    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleScroll);
      });
    };
  }, []);

  const handleDateSelect = (date) => {
    setValue("scheduledDate", new Date(date).toISOString());
  }
  const handleKeyDown = (event) => {
    const disallowedChars = ["-", "+", "e", "E"];
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };
  const handleDeleteGameData = async () => {
    setDeleteShow(!deleteShow)
    // setIsLoading("delete");
  };

  const handleDelete = async () => {
    // eslint-disable-next-line no-underscore-dangle
    setIsLoading("delete");
    await handleDeleteGame(game?.id)
    setIsLoading("")
    setDeleteShow(!deleteShow);
  }

  const handleGameUpdate = async (values) => {
    setIsLoading("save");
    await updateGameEvent(game.id, {
      ...values,
      title: values.title,
      noOfTables: game.games.length,
      icreasingBlindMinutes

    });
    setIsLoading("");
    handleClose();
  };

  const handleDeleteGameFromEvent = async (gameId) => {
    setIsLoading(`deleteTable-${gameId}`);
    const res = await deleteGameFromEvent(game.id, gameId);
    setIsLoading("");
    setGame(res);
  }

  const handleAddMoreTable = async () => {
    setIsLoading("addMore");
    const res = await addGameInEvent(game.id);
    setGame(res);
    setIsLoading("");
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      background: "#454851",
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#4C505E",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#454851",
      padding: "0px",
    }),
    control: () => ({
      background: "#454851",
      border: "1px solid #A0AABB",
      borderRadius: "6px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
    }),
  };

  const options = [
    { value: "GMT", label: "GMT" },
    { value: "IST", label: "IST" },
    { value: "PST", label: "PST" },
    { value: "EST", label: "EST" },
    { value: "PT", label: "PT" }
  ];

  const handleTimeSelect = (date) => {
    let h = new Date(date).getHours();
    h = h < 10 ? `0${h}` : h;
    let m = new Date(date).getMinutes();
    m = m < 10 ? `0${m}` : m;
    setValue("time", `${h}:${m}`);
  }
  const bigBlindVal = parseInt(watch("smallBlind") * 2, 10) || 0
  //  const icreasingBlindMinutes = parseInt(watch("icreasingBlindMinutes"), 10) || 0

  const handleBindsChanges = (e) => {
    if (!e.target.value)setIncreasing("")
    if (/^\d+(?:\.\d{1,2})?$/.test(e.target.value)) {
      setIncreasing(e.target.value)
    }
  }

  return (
    <div className="game-details">
      <Form onSubmit={handleSubmit(handleGameUpdate)}>
        <div className="game-details-head">
          <span
            className="intro-back-arrow"
            onClick={handleClose}
            role="presentation"
          >
            <img src={arrow} alt="" />
          </span>
          <h2>Poker Nights</h2>
          <div className="details-action-btn">
            <Button className="delete-btn" onClick={handleDeleteGameData}>
              {isLoading === "delete" ? <Spinner animation="border" /> : " Delete Game"}
            </Button>
            <Button className="save-btn" type="submit">
              {isLoading === "save" ? <Spinner animation="border" /> : "Save Game"}
            </Button>
          </div>
        </div>
        <div className="detail-form-grid">
          <div className="detail-form-section">
            <div className="two-input-grid">
              <Form.Group className="form-group">
                <Form.Label>Game title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Game Name"
                  defaultValue={game?.title}
                  name="title"
                  className={errors?.title ? "error-field" : ""}
                  {...register("title")}
                />
                {errors?.title ? (
                  <p className="error-text">{errors?.title?.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Increasing blinds (minutes)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set increasing blinds time interval"
                  value={icreasingBlindMinutes}
                  className={errors.icreasingBlindMinutes ? "error-field" : ""}
                  onChange={handleBindsChanges}
                  min={0}

                />
                {errors?.icreasingBlindMinutes ? (
                  <p className="error-text">{errors.icreasingBlindMinutes.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </div>

            <div className="two-input-grid">
              <Form.Group className="form-group">
                <Form.Label>Automatic buy-in (dollars)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Game Name"
                  defaultValue={game?.buyin}
                  name="title"
                  onKeyDown={handleKeyDown}
                  className={errors?.buyin ? "error-field" : ""}
                  {...register("buyin")}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Blind structure</Form.Label>
                <div className="bind-structure">
                  {icreasingBlindMinutes ? (
                    <Slider {...settings}>
                      {[1, 2, 3, 4, 5, 6].map((ele) => (
                        <div className="bind-list" key={ele}>
                          <h6>
                            {ele * icreasingBlindMinutes}
                            {" "}
                            min
                          </h6>
                          <p>
                            $
                            {(bigBlindVal * ele) / 2}
                            /$
                            {bigBlindVal * ele}
                          </p>
                        </div>
                      ))}
                    </Slider>)

                    : (
                      <p>
                        {" "}
                        Enter initial small blind
                        {" "}
                        <br />
                        {" "}
                        and increasing blinds time interval
                      </p>
                    )}
                </div>

              </Form.Group>
            </div>
            <div className="two-input-grid">
              <Form.Group className="form-group">
                <Form.Label>Game date</Form.Label>
                <div className="game-filter-date game-date-box">
                  <DatePicker
                    selected={scheduledDate}
                    onChange={(date) => handleDateSelect(date)}
                    placeholderText="MM/DD/YYYY"
                    name="scheduledDate"
                    className={errors.scheduledDate ? "error-field" : ""}
                  />
                  <img src={datearrow} alt="datearrow" />
                </div>
                {errors?.scheduledDate ? (
                  <p className="error-text">{errors.scheduledDate.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>

              <div className="half-width time-box">
                <Form.Group className="form-group">
                  <Form.Label>Time</Form.Label>
                  <div className="game-filter-date game-date-box">
                    <DatePicker
                      onChange={(date) => handleTimeSelect(date)}
                      showTimeSelect
                      className={errors.time ? "error-field" : ""}
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Time"
                      value={sceduleTime}
                    />
                    <img src={datearrow} alt="datearrow" />
                  </div>
                  {errors?.time ? (
                    <p className="error-text">{errors.time.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <div className="time-zone-box">
                  <Form.Group className="form-group">
                    <Form.Label>Time zone</Form.Label>
                    <div className="time-zone-select">
                      <Select
                        classNamePrefix="select"
                        className={errors.timezone ? "error-field" : ""}
                        styles={customStyles}
                        placeholder="Time zone"
                        defaultValue={options.find((el) => el.value === timezone)}
                        options={options}
                        onChange={(v) => setValue("timezone", v.value)}
                      />
                      <div className="game-time-zone">
                        <img src={datearrow} alt="datearrow" />
                      </div>
                    </div>
                    {errors?.timezone ? (
                      <p className="error-text">{errors.timezone.message}</p>
                    ) : (""
                    )}
                  </Form.Group>
                </div>

              </div>
            </div>

            <div className="blind-info">

              <div className="two-input-grid">
                <Form.Group className="form-group">
                  <Form.Label>Small blind</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Game Name"
                    defaultValue={game?.smallBlind}
                    name="title"
                    onKeyDown={handleKeyDown}
                    className={errors?.smallBlind ? "error-field" : ""}
                    {...register("smallBlind")}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Big blind</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Game Name"
                    value={bigBlindVal}
                    name="title"
                    className={errors?.bigBlind ? "error-field" : ""}
                    {...register("bigBlind")}
                  />

                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <DetailTable
        games={game?.games}
        handleAddMoreTable={handleAddMoreTable}
        handleDeleteGameData={handleDeleteGameFromEvent}
        isLoading={isLoading}
        gameEventId={game?.id}
        setGame={setGame}
      />
      <DeletePopup
        deleteShow={deleteShow}
        handleCancel={handleDeleteGameData}
        handleDeleteGame={handleDelete}
        isLoading={isLoading}
      />
    </div>
  );
}

DetailPopup.propTypes = {
  game: PropsTypes.object.isRequired,
  handleClose: PropsTypes.func.isRequired,
  handleDeleteGame: PropsTypes.func.isRequired,
  handleShowGameDetail: PropsTypes.func.isRequired,
};
export default DetailPopup;
