function SpeakerIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 4.06007C13.75 2.72407 12.134 2.05507 11.19 3.00007L6.69001 7.50007H4.75801C3.61701 7.50007 2.44001 8.16407 2.09801 9.40507C1.86598 10.2505 1.74893 11.1234 1.75001 12.0001C1.75001 12.8981 1.87101 13.7681 2.10001 14.5951C2.44101 15.8351 3.61801 16.5001 4.75901 16.5001H6.68901L11.189 21.0001C12.134 21.9451 13.75 21.2761 13.75 19.9401V4.06007ZM18.834 5.10607C18.9746 4.96562 19.1653 4.88673 19.364 4.88673C19.5628 4.88673 19.7534 4.96562 19.894 5.10607C23.702 8.91307 23.702 15.0861 19.894 18.8941C19.8253 18.9678 19.7425 19.0269 19.6505 19.0679C19.5585 19.1088 19.4592 19.1309 19.3585 19.1327C19.2578 19.1344 19.1578 19.1159 19.0644 19.0782C18.971 19.0405 18.8862 18.9843 18.815 18.9131C18.7438 18.8419 18.6876 18.7571 18.6499 18.6637C18.6122 18.5703 18.5936 18.4703 18.5954 18.3696C18.5972 18.2688 18.6192 18.1695 18.6602 18.0775C18.7012 17.9855 18.7603 17.9027 18.834 17.8341C19.6002 17.068 20.208 16.1585 20.6227 15.1575C21.0373 14.1564 21.2507 13.0836 21.2507 12.0001C21.2507 10.9166 21.0373 9.8437 20.6227 8.8427C20.208 7.84169 19.6002 6.93217 18.834 6.16607C18.6936 6.02545 18.6147 5.83483 18.6147 5.63607C18.6147 5.43732 18.6936 5.2467 18.834 5.10607Z"
        fill="white"
      />
      <path
        d="M16.1822 7.75719C16.2519 7.6875 16.3346 7.63221 16.4256 7.59449C16.5166 7.55677 16.6142 7.53735 16.7127 7.53735C16.8112 7.53735 16.9088 7.55677 16.9998 7.59449C17.0909 7.63221 17.1736 7.6875 17.2432 7.75719C17.8005 8.31436 18.2425 8.97584 18.5441 9.70386C18.8457 10.4319 19.0009 11.2122 19.0009 12.0002C19.0009 12.7882 18.8457 13.5685 18.5441 14.2965C18.2425 15.0245 17.8005 15.686 17.2432 16.2432C17.1017 16.3797 16.9122 16.4553 16.7156 16.4535C16.5189 16.4517 16.3308 16.3727 16.1918 16.2336C16.0529 16.0944 15.9741 15.9063 15.9724 15.7096C15.9708 15.513 16.0465 15.3236 16.1832 15.1822C16.6011 14.7643 16.9325 14.2683 17.1587 13.7223C17.3848 13.1763 17.5012 12.5911 17.5012 12.0002C17.5012 11.4092 17.3848 10.8241 17.1587 10.2781C16.9325 9.73214 16.6011 9.23606 16.1832 8.81819C16.0428 8.67757 15.9639 8.48694 15.9639 8.28819C15.9639 8.08944 16.0428 7.89882 16.1832 7.75819L16.1822 7.75719Z"
        fill="white"
      />
    </svg>
  );
}

export default SpeakerIcon
