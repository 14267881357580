import { useState, useEffect } from "react";
import PropsTypes from "prop-types";
import HistorySidebar from "./historySidebar";

function DealerLayout({ children }) {
  const initialScale = window.innerWidth / 19.2;
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);
  const [topValue, setTopValue] = useState(initialTop);

  useEffect(() => {
    setScaleValue(initialScale);
    setTopValue(initialTop);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    const currentScreenSize = e.target.innerWidth;
    setScaleValue(currentScreenSize / 19.2);
    setTopValue((100 - currentScreenSize / 19.2) / 2);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="dealer">
      <HistorySidebar />
      <div
        className="dealer-wrapper"
        style={{
          transform: `translate(-50%, -${topValue}%) scale(${
            scaleValue / 100
          })`,
        }}
      >
        {children}
      </div>
    </div>
  );
}

DealerLayout.propTypes = {
  children: PropsTypes.node.isRequired,
};
export default DealerLayout;
