/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useContext } from "react";
import PropsTypes from "prop-types";
import {
  useDaily,
  useVideoTrack,
  useAudioTrack,
  useDevices,
} from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";

function Participant({ player, setIsMute, gameData }) {
  const { user, oneToOneRoom, pokerTableVolume, speaker } = useContext(UserContext);
  const videoRef = useRef();
  const audioRef = useRef();
  const callObject = useDaily();
  const devices = useDevices();
  //  const allPlayers = [
  //    ...gameData.players,
  //    ...gameData.viewers,
  //    gameData.dealer,
  //  ];

  const { hasCamError } = devices || {};

  const remoteparticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteparticipant = remoteparticipants.find(
    (ele) => ele.user_name === player.id
  );

  const videoTrack = useVideoTrack(remoteparticipant?.session_id);
  const audioTrack = useAudioTrack(remoteparticipant?.session_id);
  const handleEndCall = () => {
    socket.emit("oneToOneLeaveRequest", {
      gameId: gameData.id,
      roomId: gameData?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
  };

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoRef.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack?.persistentTrack,
      ]);
    }
  }, [
    videoTrack.isOff,
    videoTrack.state,
    videoTrack.persistentTrack,
    videoRef.current,
    videoTrack.subscribed,
  ]);

  useEffect(() => {
    if (!audioTrack.persistentTrack) return;
    if (audioRef?.current) {
      const isParticipantInCall = oneToOneRoom.find((room) =>
        room.roomId.includes(player.id)
      );
      const isUserInCall = oneToOneRoom.some((room) =>
        room.roomId.includes(user.id)
      );

      if (isParticipantInCall) {
        if (isParticipantInCall.roomId?.includes(user.id)) {
          audioRef.current.volume = 1.0;
        } else {
          audioRef.current.volume = 0.03;
        }
      } else if (isUserInCall) {
        audioRef.current.volume = 0.03;
      } else {
        audioRef.current.volume = pokerTableVolume;
      }
      audioRef.current.srcObject = new MediaStream([
        audioTrack?.persistentTrack,
      ]);
    }
    if (audioTrack.isOff) {
      setIsMute(true);
    } else {
      setIsMute(false);
    }
  }, [
    audioTrack.persistentTrack,
    audioTrack.isOff,
    audioTrack.track,
    oneToOneRoom,
    pokerTableVolume,
    audioTrack.subscribed,
  ]);
  return (
    <>
      <div className="player-box-iframe ">
        {remoteparticipant
        && remoteparticipant.video
        && (!videoTrack.isOff
          || !hasCamError
          || videoTrack.state === "playable") ? (
            <video
              style={{ width: "100%" }}
              ref={videoRef}
              autoPlay
              controls={false}
            />
          ) : (
            <div className="main-player-camoff">
              <img
                src={player?.avatar}
                onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                  currentTarget.onerror = null; // prevents looping
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                }}
                alt=""
              />
            </div>
          )}
        {oneToOneRoom?.find((room) => room.roomId?.includes(player.id))
        && user.id === player.id ? (
          <div className="end-private-call">
            <Button onClick={handleEndCall}>End Call</Button>
          </div>
          ) : (
            ""
          )}
        <audio
          ref={audioRef}
          autoPlay
          muted={remoteparticipant?.local || !speaker}
          id={player.id}
        />
      </div>

      <div className="player-box-iframe multi-table-avatar">
        {!remoteparticipant ? (
          <div className="main-player-camoff">
            <img
              src={player?.avatar}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.onerror = null; // prevents looping
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
              }}
              alt=""
            />
            <span>{player?.name}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
Participant.defaultProps = {
  player: null,
  gameData: {},
};

Participant.propTypes = {
  player: PropsTypes.object,
  gameData: PropsTypes.object,
  setIsMute: PropsTypes.func.isRequired,
};

export default Participant;
