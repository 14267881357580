/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from "react";
import DailyIframe from "@daily-co/daily-js";
import { useParams } from "react-router-dom";
import { DailyProvider } from "@daily-co/daily-react";
import Button from "react-bootstrap/Button";
import PlayerGameView from ".";
import GameBanner from "./gameBanner";
import GameSidebar from "./gameSidebar";
import UserContext from "../../../context/UserContext";
import Rail from "../../rail/rail";
import socket from "../../../config/socket";
import intSignConvert from "../../../utils/intSignConvert";
import crossIcon from "../../../assets/images/toast/cross.svg";
import EndCallIcon from "../../../assets/images/toast/close.svg";
import "../../rail/style.css";
import HandInfo from "./handInfo";

let newCallObject;
let endOneToOneCall;
let interval;

function NewGameView() {
  const {
    user,
    setCallObject,
    callObject,
    gameEvent,
    setGameData,
    allGamesData,
    gameData,
    oneToOneDuration,
    setOneToOneDuration,
    isPlayerLoading,
  } = useContext(UserContext);
  const { gameId } = useParams();
  const [showIntro, setShowIntro] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(
    allGamesData[gameId]?.viewers?.length ? "rail" : "multi-table"
  );
  const [sidebarExpand, setSidebarExpand] = useState(true);
  const [activeCallNotification, setActiveCallNotification] = useState(true);
  const [communityCameraChange, setCommunityCameraChange] = useState(
    JSON.parse(localStorage.getItem("communityCamera"))?.value || ""
  );
  const [showHandInfo, setShowHandInfo] = useState(false);

  //  Screen Scaling Function Start

  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);
  const [topValue, setTopValue] = useState(initialTop);
  const [showWaitModal, setShowWaitModal] = useState(true);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
    setTopValue(initialTop);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
    setTopValue(
      (100 - currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2)) / 2
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End
  useEffect(() => {
    const getJoin = async () => {
      if (user.role === "dealer") {
        const dealerToken = gameData?.dealer?.meetingToken;

        newCallObject = DailyIframe.createCallObject({
          url: `https://cloudpokernight.daily.co/${gameId}`,
        });
        setCallObject(newCallObject);
        newCallObject
          .join({
            url: `https://cloudpokernight.daily.co/${gameId}`,
            token: dealerToken,
            userName: "dealer",
            startVideoOff:
              JSON.parse(localStorage.getItem("isCameraOn")) !== null
                ? !JSON.parse(localStorage.getItem("isCameraOn"))
                : false,
            startAudioOff:
              JSON.parse(localStorage.getItem("isMicOn")) !== null
                ? !JSON.parse(localStorage.getItem("isMicOn"))
                : false,
            dailyConfig: {
              userMediaVideoConstraints: {
                deviceId: JSON.parse(localStorage.getItem("camera"))?.value,
              },
              userMediaAudioConstraints: {
                deviceId: JSON.parse(localStorage.getItem("mic"))?.value,
              },
            },
          })
          .then(async (d) => {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: {
                deviceId: communityCameraChange,
              },
            });
            newCallObject.startCustomTrack({
              track: stream.getVideoTracks()[0],
              trackName: "community-card",
            });
          })
          .then((data) => {
            console.log("community connect ==>", data);
          })
          .catch((error) => {
            console.log("error =>", error);
          });
      } else {
        const meettoken = gameData?.players?.find((el) => el.id === user?.id)?.meetingToken
          || gameData?.viewers?.find((el) => el.id === user?.id)?.meetingToken;

        const userName = user?.id;
        newCallObject = DailyIframe.createCallObject({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          token: meettoken,
          userName,
          dailyConfig: {
            userMediaVideoConstraints: {
              deviceId: JSON.parse(localStorage.getItem("camera"))?.value,
              aspectRatio: 1.777778
            },
            userMediaAudioConstraints: {
              deviceId: JSON.parse(localStorage.getItem("mic"))?.value,
            },
          },
        });
        setCallObject(newCallObject);
        newCallObject.join({
          url: `https://cloudpokernight.daily.co/${gameId}`,
          token: meettoken,
          userName,
          startVideoOff:
            JSON.parse(localStorage.getItem("isCameraOn")) !== null
              ? !JSON.parse(localStorage.getItem("isCameraOn"))
              : false,
          startAudioOff:
            JSON.parse(localStorage.getItem("isMicOn")) !== null
              ? !JSON.parse(localStorage.getItem("isMicOn"))
              : false,
          dailyConfig: {
            userMediaVideoConstraints: {
              deviceId: JSON.parse(localStorage.getItem("camera"))?.value,
            },
            userMediaAudioConstraints: {
              deviceId: JSON.parse(localStorage.getItem("mic"))?.value,
            },
          },
        });
      }
    };
    if (gameData?.meetingToken && gameData?._id === gameId && !showIntro) {
      getJoin();
    }
    return () => {
      newCallObject?.leave();
      newCallObject?.destroy();
      setCallObject();
    };
  }, [gameData?.meetingToken, showIntro, gameData?._id === gameId]);

  useEffect(() => {
    if (!gameData?.videoTrack) {
      return;
    }
    if (localStorage.getItem("videoKey") === gameId) {
      setShowIntro(false);
    } else {
      //  localStorage.setItem("videoKey", gameId);
      setShowIntro(true);
    }
  }, [gameData]);

  useEffect(() => {
    socket.on("communityCamera", async (data) => {
      if (data.gameId === gameId) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: communityCameraChange,
          },
        });
        callObject.startCustomTrack({
          track: stream.getVideoTracks()[0],
          trackName: "community-card",
        });
        setCommunityCameraChange(data?.camera?.value);
      }
    });
    return () => {
      socket.off("communityCamera");
    };
  }, [callObject, communityCameraChange]);

  useEffect(() => {
    socket.on("forCall", (data) => {
      if (!newCallObject) {
        setGameData(data.game);
      }
    });
  }, []);

  const handleCallTimer = (callStartTime) => {
    if (interval) {
      setOneToOneDuration("00:00");
      clearInterval(interval);
      interval = null;
      endOneToOneCall = null;
    }
    let date1 = new Date().getTime();
    interval = setInterval(() => {
      const date2 = new Date(callStartTime);
      const diffTime = (date1 - date2) / 1000;
      if (endOneToOneCall) {
        setOneToOneDuration("00:00");
        clearInterval(interval);
      } else {
        // const h = Math.floor(diffTime / 3600);
        let m = Math.floor((diffTime % 3600) / 60);
        let s = Math.floor((diffTime % 3600) % 60);
        m = m < 10 ? `0${m < 0 ? 0 : m}` : m;
        s = s < 10 ? `0${s < 0 ? 0 : s}` : s;
        let str = "";
        // if (h) {
        //  str += h < 10 ? `0${h}:` : `${h}:`;
        // }
        str += `${m}:${s}`;
        setOneToOneDuration(str);
      }
      date1 += 1000;
    }, 1000);
  };

  useEffect(() => {
    if (
      allGamesData[gameId]?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )
    ) {
      handleCallTimer(
        allGamesData[gameId]?.oneToOneRoom?.find(
          (el) => el.requestedBy === user.id || el.requestedTo === user.id
        )?.timestamp
      );
      setActiveCallNotification(true);
    } else if (interval) {
      clearInterval(interval);
      interval = null;
      endOneToOneCall = null;
      setOneToOneDuration("00:00");
    }
  }, [allGamesData[gameId]?.oneToOneRoom?.length]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEndCall = () => {
    endOneToOneCall = true;
    socket.emit("oneToOneLeaveRequest", {
      gameId: allGamesData[gameId].id,
      roomId: allGamesData[gameId]?.oneToOneRoom?.find(
        (el) => el.requestedBy === user.id || el.requestedTo === user.id
      )?.roomId,
    });
    setOneToOneDuration("00:00");
  };

  const handleHandInfo = () => {
    setShowHandInfo(!showHandInfo);
  };

  useEffect(() => {
    if (
      activeTab === "rail"
      && isExpanded
      && allGamesData[gameId]?.viewers?.length > 0
    ) { return; }
    if (
      allGamesData[gameId]?.viewers?.length > 0
      || (activeTab === "multi-table" && allGamesData[gameId]?.viewers?.length > 0)
    ) {
      setActiveTab("rail");
      setIsExpanded(true);
    } else {
      setActiveTab("multi-table");
      setIsExpanded(false);
    }
  }, [allGamesData[gameId]?.viewers?.length]);

  return (
    <div
      className={`player-view-main ${
        activeTab === "rail" ? "rail-player-main" : ""
      }`}
    >
      <div
        className="hand-info-view"
        style={{
          transform: `translate(${-topValue}%, ${intSignConvert(
            topValue
          )}%) scale(${scaleValue / 100})`,
        }}
      >
        {!isPlayerLoading && (
          <HandInfo
            handleHandInfo={handleHandInfo}
            showHandInfo={showHandInfo}
          />
        )}
      </div>
      <div className="container">
        <DailyProvider callObject={callObject}>
          <div className="main-player-game-wrapper">
            <div
              className="player-view-main-wrapper"
              style={{
                transform: `translate(-50%, ${intSignConvert(
                  topValue
                )}%) scale(${scaleValue / 100})`,
              }}
            >
              {localStorage.getItem("newPlayerId") === user?.id
              && user?.role !== "dealer"
              && !gameData?.viewers?.find((pl) => pl.id === user?.id)
              && showWaitModal ? (
                <div className="add-player-notification">
                  <p>You will be seated as soon as the current hand ends</p>
                  <span
                    onClick={() => setShowWaitModal(false)}
                    role="presentation"
                  >
                    <img src={crossIcon} alt="cross icon" />
                  </span>
                </div>
                ) : (
                  ""
                )}
              {allGamesData[gameId]?.oneToOneRoom?.length
              && activeCallNotification ? (
                <div className="one-to-one-call-notification-wrapper">
                  {allGamesData[gameId]?.oneToOneRoom.map((el) =>
                    (el?.requestedBy === user?.id
                    || el?.requestedTo === user?.id ? (
                      <div
                        className="one-to-one-call-notification"
                        key={el?.roomId}
                      >
                        <div className="one-to-one-notification-wrapper">
                          <div className="one-to-one-player-name">
                            <p>
                              {el?.requestedBy === user?.id
                                ? `${el?.requestedToName}`
                                : `${el?.requestedByName}`}
                            </p>
                            <span>Active 1-1 call</span>
                          </div>
                          <p>{oneToOneDuration}</p>
                        </div>
                        <Button
                          className="rail-end-call"
                          onClick={handleEndCall}
                        >
                          <img src={EndCallIcon} alt="close icon" />
                        </Button>
                      </div>
                      ) : (
                        ""
                      ))
                  )}
                </div>
                ) : (
                  ""
                )}
              <div className="player-game-view-wrapper-top">
                <div
                  className={`player-game-view-top-left ${
                    sidebarExpand ? "show" : "hide"
                  } ${activeTab === "rail" && isExpanded ? "rail" : ""} ${
                    activeTab === "multi-table" && isExpanded
                      ? "multi-table"
                      : ""
                  }`}
                >
                  <GameBanner gameData={gameEvent} isExpanded={isExpanded} />

                  <div
                    className={`player-game-view-container ${
                      activeTab === "rail" && isExpanded ? "rail" : ""
                    } ${
                      activeTab === "multi-table" && isExpanded
                        ? "multi-table"
                        : ""
                    }`}
                  >
                    <PlayerGameView
                      showIntro={showIntro}
                      setShowIntro={setShowIntro}
                    />
                  </div>
                </div>
                <div
                  className={`player-game-view-top-right ${
                    sidebarExpand ? "show" : "hide"
                  } ${activeTab === "rail" && isExpanded ? "rail" : ""} ${
                    activeTab === "multi-table" && isExpanded
                      ? "multi-table"
                      : ""
                  }`}
                >
                  <GameSidebar
                    activeTab={activeTab}
                    isExpanded={isExpanded}
                    sidebarExpand={sidebarExpand}
                    setSidebarExpand={setSidebarExpand}
                  />
                </div>
              </div>
              <div className="player-game-view-wrapper-bottom">
                {gameEvent?.games?.length > 1
                || allGamesData[gameId]?.viewers?.length > 0 ? (
                  <Rail
                    isExpanded={isExpanded}
                    handleExpanded={handleExpanded}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  ) : (
                    ""
                  )}
              </div>
            </div>
          </div>
        </DailyProvider>
      </div>
    </div>
  );
}

export default NewGameView;
