/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PropsTypes from "prop-types";
import ChatIcon from "../../../IconComponents/ChatIcon";
import SendIcon from "../../../IconComponents/SendIcon";
import UserContext from "../../../context/UserContext";
import TimeFormat from "../../../utils/chatTimeFormat";
import EmojiIcon from "../../../IconComponents/EmojiIcon";
import ProfileModal from "../../profile/profileModal";
import cross from "../../../assets/images/profile/cross.svg";
import ChatBtnIcon from "../../../IconComponents/ChatBtnIcon";
import ChatEmoji from "../../chatEmoji/chatEmoji";
import socket from "../../../config/socket";
import { chatInstance } from "../../../config/axios";
import ChatCamIcon from "../../../IconComponents/ChatCamIcon";
import CamModal from "./camModal";
import UploadImageModal from "./uploadImageModal";
import WinnerDefaultImage from "../../../assets/images/winnerDefaultImg.png";

const chatColor = [];

const ColorList = [
  "#B2A4FF",
  "#d93355",
  "#DDAB34",
  "#9DF1DF",
  "#5B72E2",
  "#D7905D",
  "#CDE990",
  "#83BC9A",
  "#F3BCC8",
  "#00ACEE",
  "#E5D1FA",
  "#9D896D",
  "#7C5DD7",
  "#D2E9E9",
  "#57B661",
  "#D6002B",
  "#AAC8A7",
  "#FEA1A1",
  "#FFEA20",
  "#FF55BB",
  "#A0D8B3",
  "#B71375",
  "#6C9BCF",
  "#F97B22",
  "#C9A7EB",
  "#FF6969",
  "#00FFCA",
  "#FA9884",
  "#57C5B6",
  "#AA77FF",
];

function Chat({ chats, handleChangeMessage, message, handleSendNewMessage }) {
  const { gameEvent, allGamesData, user } = useContext(UserContext);
  const { gameId } = useParams();
  const [tablesCount, setTablesCount] = useState(null);
  const gameData = allGamesData[gameId];
  const scrollableRef = useRef(null);
  const [showProfile, setShowProfile] = useState(false);
  const [chatUserId, setChatUserId] = useState("");
  const [showEmoji, setShowEmoji] = useState("");
  const [showCam, setShowCam] = useState(false);
  const [reactedEmoji, setReactedEmoji] = useState({
    id: "",
    emoji: "",
    count: 0,
  });

  // Image States
  const [showCamModal, setShowCamModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [sendImgLoader, setSendImgLoader] = useState(false);
  const intialFileData = {
    file: "",
    caption: "",
  };
  const [file, setFile] = useState(intialFileData);
  const [preview, setPreview] = useState("");
  const [allCameraOptions, setAllCameraOptions] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const showCamOption = useRef(null);
  const [cameraError, setCameraError] = useState(false);

  const handleClose = () => {
    setShowProfile(false);
  };

  const handleShowChatProfile = (userId) => {
    setChatUserId(userId);
    setShowProfile(true);
  };

  useEffect(() => {
    if (gameEvent?.games?.length > 0) {
      const obj = {};
      gameEvent.games.forEach((game, i) => {
        obj[game.id?.toString()] = i + 1;
      });
      setTablesCount({ ...obj });
    }
  }, [gameEvent?.games?.length]);

  const scrollToBottom = () => {
    scrollableRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //  const chatSecElement = document?.getElementById("blankChat");
  useEffect(() => {
    scrollToBottom();
  }, [
    chats?.length,
    chats[chats.length - 1]?.reactions?.length,
    scrollableRef,
  ]);

  const getAllPlayers = gameData?.players?.concat(gameData?.viewers);
  useEffect(() => {
    getAllPlayers?.forEach((element, i) => {
      chatColor.push({ id: element.id, color: ColorList[i] });
    });
  }, [gameData?.players, gameData?.viewers]);

  const handleReaction = (e, type, cId) => {
    if (!cId) return;
    socket.emit("reactions", {
      gameEventId: gameEvent?.id,
      chatId: cId,
      userId: user?.id,
      emoji: e,
    });

    setReactedEmoji({ ...reactedEmoji });
  };

  // Work of taking a picture

  const handleCamerOptions = (e) => {
    const { value } = e;
    setSelectedCamera(value);
  };

  const getCameraOptions = async () => {
    if (
      "mediaDevices" in navigator
      && "getUserMedia" in navigator.mediaDevices
    ) {
      navigator.mediaDevices.getUserMedia({ video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraOptions = devices.filter((el) => el.kind === "videoinput");
      setAllCameraOptions(
        cameraOptions?.map((camera) => ({
          label: camera.label,
          value: camera.deviceId,
        }))
      );
    }
  };

  const handleCamModal = () => {
    if (!showCamModal) getCameraOptions();
    setShowCamModal(true);
    setShowCam(false);
    setPreview("");
    setFile(intialFileData);
  };

  const handleUploadModal = () => {
    setShowUploadModal(true);
    setShowCam(false);
    setPreview("");
    setFile(intialFileData);
  };

  const handleImageChange = (e) => {
    if (!e[0]) return;
    const imageUrl = URL.createObjectURL(e[0]);
    setPreview(imageUrl);
    setFile({ ...file, file: e[0] });
  };

  const handleCaption = (e) => {
    setFile({ ...file, caption: e });
  };

  const handleSendMessageWithImage = async (e) => {
    e.preventDefault();
    setSendImgLoader(true);
    try {
      if (!file.file) return;
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("gameEventId", gameEvent?.id);
      formData.append("gameId", gameId);
      formData.append("caption", file.caption);
      formData.append("type", file.file.type);
      const response = await chatInstance().post("/uploadChatImage", formData);
      if (response) {
        setFile({ ...intialFileData });
        setShowCamModal(false);
        setShowUploadModal(false);
        setShowCam(false);
        setSendImgLoader(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      setSendImgLoader(false);
    }
  };

  const handleSubmit = (e) => {
    if (!file.file) {
      handleSendNewMessage(e);
    }
    // handleSendMessageWithImage(e);
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const generateRandomFileName = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${timestamp}_${randomString}.jpg`;
  };

  const takepicture = () => {
    const canvas = document.getElementById("canvas");
    const video = document.getElementById("video");
    if (canvas && video) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.clientWidth, canvas.clientHeight);
      const data = canvas.toDataURL("image/png");
      const blobData = dataURItoBlob(data);
      const fileName = generateRandomFileName();
      const resultFile = new File([blobData], fileName, {
        type: "image/jpeg",
        lastModified: new Date(),
      });
      const imageUrl = URL.createObjectURL(resultFile);
      setPreview(imageUrl);
      setFile({ ...file, file: resultFile });
    }
  };

  const clearphoto = () => {
    setFile({ ...file, file: "" });
    setPreview("");
  };

  const handleVideoClick = (e) => {
    if (e.target.paused) e.target.play();
    else e.target.pause();
  };

  useEffect(() => {
    if (showCamModal || !file?.file) {
      const video = document.getElementById("video");
      if (video) {
        const mediaObject = {
          video: true,
          audio: false,
        };
        if (selectedCamera) mediaObject.video = { deviceId: selectedCamera };
        navigator.mediaDevices
          .getUserMedia(mediaObject)
          .then((stream) => {
            video.srcObject = stream;
            video.play();
          })
          .catch((err) => {
            setCameraError(true);
            // eslint-disable-next-line no-console
            console.error(`An error occurred: ${err}`);
          });
      }
    }
  }, [showCamModal, file, selectedCamera]);

  const handleViewCards = (hand) => {
    const winnerCard = hand?.winnerHand?.filter((el) =>
      hand?.winnerCards?.includes(el)
    );
    const winnerHandCards = hand?.winnerHand?.filter(
      (el) => !hand?.winnerCards?.includes(el)
    );
    return (
      <>
        <div
          className={`hand-history-card-wrapper ${
            winnerCard?.length > 0 ? "highlighted-winner-cards" : ""
          }`}
        >
          {winnerCard?.map((card, i) => (
            <img
              src={`/main-view-cards/${card}.svg`}
              alt={card}
              // eslint-disable-next-line react/no-array-index-key
              key={i + 1}
            />
          ))}
        </div>
        {winnerHandCards?.map((card, i) => (
          <img
            src={`/main-view-cards/${card}.svg`}
            alt={card}
            // eslint-disable-next-line react/no-array-index-key
            key={i + 2}
          />
        ))}
      </>
    );
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCam(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(showCamOption);

  function deduplicateReactions(reactions) {
    const uniqueReactions = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const reaction of reactions) {
      const { emoji } = reaction;

      if (uniqueReactions[emoji]) {
        // eslint-disable-next-line no-plusplus
        uniqueReactions[emoji].count++;
        uniqueReactions[emoji].userIds = Array.from(
          new Set([...uniqueReactions[emoji].userIds, ...reaction.userIds]));
      } else {
        uniqueReactions[emoji] = { ...reaction };
      }
    }

    return Object.values(uniqueReactions);
  }

  return (
    <div className="chat-wrapper">
      <div className="tab-card-head">
        <ChatIcon />
        <h4>Game Chat</h4>
      </div>
      <div className="chat-content-wrapper">
        <div className="tab-card-body">
          {chats?.map((chat, chatIndex) =>
            (chat?.type !== "winnerHands" && chat?.type !== "tableWinner" ? (
              <div
                className="tab-card-element tab-chat-element"
                // eslint-disable-next-line react/no-array-index-key
                key={chat.id + chatIndex + 1}
              >
                <div className="chat-text-wrapper">
                  <div className="chat-detail-wrapper">
                    <img
                      className="chat-avatar"
                      onClick={() => handleShowChatProfile(chat.userId)}
                      src={chat?.userId?.avatar}
                      onError={({ currentTarget }) => {
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.onerror = null; // prevents looping
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                      }}
                      alt={chat?.firstName}
                      role="presentation"
                    />
                    <p
                      className="chat-user-name"
                      onClick={() => handleShowChatProfile(chat.userId)}
                      style={{
                        color: `${
                          chatColor?.find((el) => el?.id === chat.userId.id)
                            ?.color
                        }`,
                      }}
                      role="presentation"
                    >
                      {chat?.userId?.firstName}
                    </p>
                    {tablesCount ? (
                      <span>{`Table #${tablesCount[chat?.gameId]}`}</span>
                    ) : null}
                  </div>
                  <p className="chat-message">{chat?.message}</p>
                  {chat?.type?.includes("image") ? (
                    <img
                      className="chat-media-file"
                      src={chat.imageUrl}
                      alt="Not Found"
                    />
                  ) : null}
                  {chat?.type?.includes("video") ? (
                    <video
                      className="chat-media-file"
                      src={chat.imageUrl}
                      onClick={handleVideoClick}
                      playsInline
                      controls
                    />
                  ) : null}

                  <div className="reacted-emoji-wrapper">
                    {deduplicateReactions(chat?.reactions)?.map((eleme, reactionIndex) => (
                      <div
                        className="reacted-emoji"
                        role="presentation"
                        onClick={() =>
                          handleReaction(eleme?.emoji, "emoji", chat?.id)}
                        key={reactionIndex + 3}
                      >
                        <span role="presentation" aria-label="emoji">
                          {eleme?.emoji}
                        </span>
                        {eleme?.count >= 1 ? (
                          <p>{eleme?.userIds?.length}</p>
                        ) : null}
                        <div className="reaction-info-wrapper">
                          <div className="reacting-username">
                            {eleme?.userIds?.map((el) => (
                              <p>{el?.firstName}</p>
                            ))}
                          </div>
                          <p className="reaction-info-text">
                            Reacted with:
                            {" "}
                            <span>{eleme?.emoji}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="chat-right-wrapper">
                  <span className="chat-time">
                    {TimeFormat(chat?.createdAt)}
                  </span>
                  <Button
                    className={`chat-react-btn ${
                      showEmoji === chat?.id ? "active" : ""
                    }`}
                    onClick={() => setShowEmoji(chat?.id)}
                  >
                    <EmojiIcon />
                  </Button>
                </div>
                <div
                  className={`chat-reaction-emoji ${
                    showEmoji === chat?.id ? "show" : ""
                  }`}
                >
                  <ChatEmoji
                    name="reaction"
                    handleReaction={handleReaction}
                    setShowEmoji={setShowEmoji}
                    chatId={chat?.id}
                  />
                </div>
              </div>
            ) : chat?.hands?.length > 0 ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {chat?.hands?.map((hand, index) => (
                  <div
                    className="tab-card-element hand-chat-elem"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${hand.id}${index + 7}`}
                  >
                    <div className="hand-chat-elem-wrapper">
                      <div className="hand-chat-wrapper">
                        <div className="hand-history-head">
                          <div className="hand-history-head-left">
                            <div className="hand-history-profile-wrapper">
                              <img
                                src={hand?.avatar}
                                onError={({ currentTarget }) => {
                                  // eslint-disable-next-line no-param-reassign
                                  currentTarget.onerror = null; // prevents looping
                                  // eslint-disable-next-line no-param-reassign
                                  currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                                }}
                                alt={hand?.name}
                              />
                              <div className="hand-winner-name">
                                <p>{hand?.name}</p>
                                <p className="hand-name">
                                  {hand?.handName
                                    ? hand?.handName
                                    : "All-Folded"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="hand-history-head-right">
                            <div className="hand-pot-details">
                              <p>Pot:</p>
                              <span>
                                $
                                {hand?.winningAmount}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="hand-history-card-wrapper">
                          {handleViewCards(hand)}
                        </div>
                      </div>
                      <div className="hand-reacted-emoji-wrapper">
                        <div className="reacted-emoji-wrapper">
                          {chat?.reactions?.map((eleme) => (
                            <div
                              className="reacted-emoji"
                              role="presentation"
                              onClick={() =>
                                handleReaction(eleme?.emoji, "emoji", chat?.id)}
                            >
                              <span role="presentation" aria-label="emoji">
                                {eleme?.emoji}
                              </span>
                              {eleme?.count >= 1 ? (
                                <p>{eleme?.userIds?.length}</p>
                              ) : null}
                              <div className="reaction-info-wrapper">
                                <div className="reacting-username">
                                  {eleme?.userIds?.map((el) => (
                                    <p>{el?.firstName}</p>
                                  ))}
                                </div>
                                <p className="reaction-info-text">
                                  Reacted with:
                                  {" "}
                                  <span>{eleme?.emoji}</span>
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <Button
                          className={`chat-react-btn ${
                            showEmoji === chat?.id ? "active" : ""
                          }`}
                          onClick={() => setShowEmoji(chat?.id)}
                        >
                          <EmojiIcon />
                        </Button>
                        <div
                          className={`chat-reaction-emoji ${
                            showEmoji === chat?.id ? "show" : ""
                          }`}
                        >
                          <ChatEmoji
                            name="reaction"
                            handleReaction={handleReaction}
                            setShowEmoji={setShowEmoji}
                            chatId={chat?.id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : chat?.type === "tableWinner" ? (
              <div className="tab-card-element hand-chat-elem">
                <div className="hand-chat-elem-wrapper">
                  <div className="hand-chat-wrapper table-last-winner">
                    <div className="hand-history-profile-wrapper">
                      <img
                        src={
                          chat?.userId?.avatar.includes("user-default")
                            ? WinnerDefaultImage
                            : chat?.userId?.avatar
                        }
                        onError={({ currentTarget }) => {
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.onerror = null; // prevents looping
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.src = { WinnerDefaultImage };
                        }}
                        alt={chat?.userId?.name}
                      />
                    </div>
                    <div className="hand-winner-name">
                      {tablesCount ? (
                        <p>{`Table #${tablesCount[chat?.gameId]} Winner`}</p>
                      ) : null}
                      <p className="hand-name">
                        {chat?.userId?.firstName}
                        {" "}
                        {chat?.userId?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="hand-reacted-emoji-wrapper">
                    <div className="reacted-emoji-wrapper">
                      {chat?.reactions?.map((eleme, i) => (
                        <div
                          className="reacted-emoji"
                          role="presentation"
                          onClick={() =>
                            handleReaction(eleme?.emoji, "emoji", chat?.id)}
                          key={`${chat?.id}rea${i}ct`}
                        >
                          <span role="presentation" aria-label="emoji">
                            {eleme?.emoji}
                          </span>
                          {eleme?.count >= 1 ? (
                            <p>{eleme?.userIds?.length}</p>
                          ) : null}
                          <div className="reaction-info-wrapper">
                            <div className="reacting-username">
                              {eleme?.userIds?.map((el) => (
                                <p>{el?.firstName}</p>
                              ))}
                            </div>
                            <p className="reaction-info-text">
                              Reacted with:
                              {" "}
                              <span>{eleme?.emoji}</span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Button
                      className={`chat-react-btn ${
                        showEmoji === chat?.id ? "active" : ""
                      }`}
                      onClick={() => setShowEmoji(chat?.id)}
                    >
                      <EmojiIcon />
                    </Button>
                    <div
                      className={`chat-reaction-emoji ${
                        showEmoji === chat?.id ? "show" : ""
                      }`}
                    >
                      <ChatEmoji
                        name="reaction"
                        handleReaction={handleReaction}
                        setShowEmoji={setShowEmoji}
                        chatId={chat?.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            ))
          )}
          <span id="blankChat" ref={scrollableRef} />
        </div>
      </div>
      <div className="tab-card-bottom">
        <Form className="Chat-input-wrapper" onSubmit={handleSubmit}>
          <Form.Control
            type="text"
            placeholder="Send a message"
            onChange={(e) => handleChangeMessage(e, "chat")}
            value={message}
          />
          <div className="cam-emoji-wrapper">
            <div ref={showCamOption}>
              <span
                className="emoji-input-btn cam-input-btn"
                onClick={() => setShowCam(!showCam)}
                role="presentation"
              >
                <ChatCamIcon />
              </span>
              <div
                className={`chat-input-emoji chat-input-cam ${
                  showCam ? "show" : ""
                }`}
              >
                <div className="cam-input-wrapper">
                  <span onClick={() => handleCamModal()} role="presentation">
                    Take photo
                  </span>
                  <span onClick={() => handleUploadModal()} role="presentation">
                    Upload photo
                  </span>
                  {/* <input type="file" onChange={handleImageChange} /> */}
                </div>
              </div>
            </div>
            <div>
              <span
                className="emoji-input-btn"
                onClick={() => setShowEmoji("chat")}
                role="presentation"
              >
                <ChatBtnIcon />
              </span>
            </div>
          </div>
          <div
            className={`chat-input-emoji ${showEmoji === "chat" ? "show" : ""}`}
          >
            <ChatEmoji
              name="chat"
              selectEmoji={handleChangeMessage}
              setShowEmoji={setShowEmoji}
              handleReaction={handleChangeMessage}
            />
          </div>

          <Button className="bg-btn" type="submit">
            <SendIcon />
          </Button>
        </Form>
      </div>
      {showProfile && (
        <div className="profile-viewer chat-profile-view">
          <span
            className="closeprofile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="close icon" />
          </span>
          <ProfileModal user={chatUserId} />
        </div>
      )}
      <CamModal
        showCamModal={showCamModal}
        setShowCamModal={setShowCamModal}
        takepicture={takepicture}
        clearphoto={clearphoto}
        file={file}
        handleSendMessageWithImage={handleSendMessageWithImage}
        preview={preview}
        allCameraOptions={allCameraOptions}
        handleCamerOptions={handleCamerOptions}
        handleChangeMessage={handleChangeMessage}
        handleImageChange={handleImageChange}
        handleCaption={handleCaption}
        sendImgLoader={sendImgLoader}
        selectedCamera={selectedCamera}
        cameraError={cameraError}
      />
      <UploadImageModal
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        takepicture={takepicture}
        clearphoto={clearphoto}
        file={file}
        handleSendMessageWithImage={handleSendMessageWithImage}
        preview={preview}
        allCameraOptions={allCameraOptions}
        handleCamerOptions={handleCamerOptions}
        handleChangeMessage={handleChangeMessage}
        handleImageChange={handleImageChange}
        handleCaption={handleCaption}
        sendImgLoader={sendImgLoader}
      />
    </div>
  );
}

Chat.propTypes = {
  chats: PropsTypes.array.isRequired,
  message: PropsTypes.string.isRequired,
  handleChangeMessage: PropsTypes.func.isRequired,
  handleSendNewMessage: PropsTypes.func.isRequired,
};

export default Chat;
