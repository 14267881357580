/* eslint-disable react/jsx-indent */
import { Button, Form, FormLabel, ProgressBar } from "react-bootstrap";
import PropsTypes from "prop-types";
import { useState, useContext, useEffect } from "react";
import InputRange from "react-input-range";
import { useParams } from "react-router-dom";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import { DoDecrypt, convertAmmount } from "../../../utils";
import PlusIcon from "../../../IconComponents/PlusIcon";
import MinusIcon from "../../../IconComponents/MinusIcon";

function PlayerAction({
  setOpenAction,
  openAction,
  //  setBet,
  //  setRaise,
  bet,
  raise,
  setTimer,
  currentPlayer,
  remainingTime,
  gameData,
  handleStopSound,
  timer,
  player,
}) {
  const {
    user: { id: userId },
  } = useContext(UserContext);
  const { gameId } = useParams();
  const [values, setValues] = useState(0);
  const [tentativeAction, setTentativeAction] = useState(null);
  const {
    smallBlind,
    players,
    preflopround,
    flopround,
    turnround,
    riverround,
    sidePots,
    bigBlind,
    winnerPlayer,
    showdown,
    isReset,
  } = gameData;
  const showDownPlayersCards = showdown?.filter((el) => el.id === player.id);

  useEffect(() => {
    if (gameData) {
      if (gameData?.runninground === 1) {
        setValues(gameData.raiseAmount * 2);
      } else {
        setValues(gameData.raiseAmount);
      }
    }
  }, [gameData?.runninground, currentPlayer]);

  useEffect(() => {
    if (player.tentativeAction === null) {
      setTentativeAction();
    } else {
      setTentativeAction(player?.tentativeAction);
    }
  }, [currentPlayer, player]);

  const callAction = () => {
    // const callAmt = (gameData.raiseAmount - currentPlayer.pot);
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("docall", {
      userId,
      gameId: gameData.id,
      amount: gameData?.raiseAmount,
    });
    setTimer(0);
  };

  const raiseAction = (x) => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("doraise", {
      userId,
      gameId: gameData.id,
      amount: x,
    });
    setTimer(0);
  };

  const checkAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("docheck", {
      userId,
      gameId: gameData.id,
    });
    setTimer(0);
  };

  const betAction = (x) => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("dobet", {
      userId,
      gameId: gameData.id,
      amount: x,
    });
    setTimer(0);
  };

  const allinAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("doallin", {
      userId,
      gameId: gameData.id,
      amount: currentPlayer.wallet,
    });
    setTimer(0);
  };

  const foldAction = () => {
    handleStopSound("timer-out");
    setOpenAction({
      bet: false,
      call: false,
      raise: false,
      check: false,
      allin: false,
      fold: false,
    });
    if (remainingTime < 1) {
      return;
    }
    socket.emit("dofold", {
      userId,
      gameId: gameData.id,
    });
    setTimer(0);
  };

  let playersPot;
  switch (gameData?.runninground) {
    case 1:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = preflopround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    case 2:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = flopround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    case 3:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = turnround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    case 4:
      if (sidePots?.length > 0) {
        playersPot = sidePots?.reduce((a, b) => a + b.pot, 0);
      } else {
        playersPot = riverround?.reduce((a, b) => a + b.pot, 0);
      }
      break;
    default:
      playersPot = players?.reduce((a, b) => a + b.pot, 0);
  }

  const handleConfirm = (e) => {
    e.preventDefault();
    if (values === gameData.raiseAmount) {
      const amt = values * 2;
      if (amt >= currentPlayer?.wallet) {
        allinAction(currentPlayer?.wallet);
      } else {
        setValues(amt);
        raiseAction(amt);
      }
    } else if (
      values > gameData.raiseAmount
      && !(values >= currentPlayer.wallet)
    ) {
      raiseAction(values);
    } else if (values >= currentPlayer.wallet) {
      allinAction(currentPlayer.wallet);
    }
  };
  const handleBetAction = (e) => {
    e.preventDefault();
    if (values === gameData.raiseAmount) {
      setValues(values);
      betAction(values);
    } else if (
      values > gameData.raiseAmount
      && !(values >= currentPlayer.wallet)
    ) {
      betAction(values);
    } else if (values >= currentPlayer.wallet) {
      allinAction(currentPlayer.wallet);
    }
  };

  const handleCallAction = () => {
    if (gameData?.raiseAmount >= currentPlayer?.wallet) {
      allinAction(currentPlayer?.wallet);
    } else {
      callAction();
    }
  };

  const handleTentativeAction = (e) => {
    const {
      target: { value, checked },
    } = e;
    if (tentativeAction === value) {
      setTentativeAction("");
    } else {
      setTentativeAction(value);
    }

    socket.emit("playerTentativeAction", {
      gameId: gameData.id,
      userId,
      playerAction: checked ? value : null,
    });
  };

  const getTentativeAction = () => {
    const FOLD_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-1"
        className="tentative-box tentative-fold"
      >
        <Form.Check
          className="tentative-action-btn tentative-fold-btn"
          inline
          label="Fold"
          value="fold"
          name="group1"
          type="checkbox"
          key="fold"
          onChange={handleTentativeAction}
          defaultChecked={player?.tentativeAction?.startsWith("fold")}
          id="inline-checkbox-1"
          checked={tentativeAction === "fold"}
        />
      </FormLabel>
    );

    const CHECK_FOLD_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-2"
        className="tentative-box tentative-check-fold"
      >
        <Form.Check
          className="tentative-action-btn tentative-check-fold-btn"
          inline
          label="Check/Fold"
          value="check/fold"
          name="group1"
          type="checkbox"
          key="check/fold"
          onChange={handleTentativeAction}
          defaultChecked={player?.tentativeAction?.startsWith("check/fold")}
          //  disabled={player?.tentativeAction !== null && player.id === userId}
          id="inline-checkbox-2"
          checked={tentativeAction === "check/fold"}
        />
      </FormLabel>
    );

    const CHECK_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-3"
        className="tentative-box tentative-check"
      >
        <Form.Check
          className="tentative-action-btn tentative-check-btn"
          inline
          label="Check"
          value="check"
          name="group1"
          type="checkbox"
          key="check"
          onChange={handleTentativeAction}
          defaultChecked={player?.tentativeAction?.startsWith("check")}
          //  disabled={player?.tentativeAction !== null && player.id === userId}
          id="inline-checkbox-3"
          checked={tentativeAction === "check"}
        />
      </FormLabel>
    );
    const CALL_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-4"
        className="tentative-box tentative-call"
      >
        <Form.Check
          className="tentative-action-btn tentative-call-btn"
          inline
          defaultChecked={player?.tentativeAction?.startsWith("call")}
          label={`Call $${convertAmmount(gameData.raiseAmount - player.pot)}`}
          value={`call $${convertAmmount(gameData.raiseAmount - player.pot)}`}
          name="group1"
          type="checkbox"
          key="call"
          onChange={handleTentativeAction}
          //  disabled={player?.tentativeAction !== null && player.id === userId}
          id="inline-checkbox-4"
          checked={
            tentativeAction
            === `call $${convertAmmount(gameData.raiseAmount - player.pot)}`
          }
        />
      </FormLabel>
    );
    const CALL_ANY_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-5"
        className="tentative-box tentative-call-any"
      >
        <Form.Check
          className="tentative-action-btn tentative-call-any-btn"
          inline
          defaultChecked={player?.tentativeAction?.startsWith("callAny")}
          label="Call Any"
          value="callAny"
          name="group1"
          type="checkbox"
          key="callAny"
          onChange={handleTentativeAction}
          //  disabled={player?.tentativeAction !== null && player.id === userId}
          id="inline-checkbox-5"
          checked={tentativeAction === "callAny"}
        />
      </FormLabel>
    );

    const ALL_IN_BTN = (
      <FormLabel
        htmlFor="inline-checkbox-6"
        className="tentative-box tentative-all-in"
      >
        <Form.Check
          className="tentative-action-btn tentative-all-in-btn"
          inline
          defaultChecked={player?.tentativeAction?.startsWith("allin")}
          label="All-in"
          value={`allin ${convertAmmount(player.wallet)}`}
          name="group1"
          type="checkbox"
          key="allin"
          onChange={handleTentativeAction}
          //  disabled={player?.tentativeAction !== null && player.id === userId}
          id="inline-checkbox-6"
          checked={tentativeAction?.includes("allin")}
        />
      </FormLabel>
    );

    let btn = "";

    if (
      gameData.raiseAmount === player.pot
      || gameData.lastAction === "check"
    ) {
      btn = (
        <>
          {CALL_ANY_BTN}
          {CHECK_BTN}
          {CHECK_FOLD_BTN}
        </>
      );
    } else if (gameData.raiseAmount > player.pot) {
      if (player.wallet >= gameData.raiseAmount) {
        btn = (
          <>
            {CALL_ANY_BTN}
            {CALL_BTN}
            {FOLD_BTN}
          </>
        );
      } else {
        btn = (
          <>
            {ALL_IN_BTN}
            <span className="placeholder-btn-action" />
            {FOLD_BTN}
          </>
        );
      }
    }
    return btn;
  };

  const [v, setV] = useState(values);

  useEffect(() => {
    setV(values);
  }, [values]);

  const handleChange = (value) => {
    if (value > gameData?.raiseAmount) {
      if (value >= currentPlayer?.wallet) {
        setValues(currentPlayer?.wallet);
      } else {
        const val = ((value - (value % bigBlind)) / bigBlind) * bigBlind;
        setValues(val);
      }
    }
  };
  const handleInputChange = (e) => {
    if (
      e.target.value <= currentPlayer.wallet
      && e.target.value >= gameData.raiseAmount
    ) {
      setValues(Number(e.target.value));
    }
  };

  const handleIncrement = () => {
    if (values < currentPlayer.wallet) {
      setValues(values + Math.round(smallBlind / 2));
    }
  };

  const handleDecrement = () => {
    if (gameData?.runninground === 1) {
      if (values > gameData.raiseAmount * 2) {
        setValues(values - Math.round(smallBlind / 2));
      }
    } else if (values > gameData?.raiseAmount) {
      setValues(values - Math.round(smallBlind / 2));
    }
  };

  const handleShowFirstCard = () => {
    socket.emit("showCard", { userId: player?.id, gameId, cardIndex: 0 });
  }
  const handleShowSecondCard = () => {
    socket.emit("showCard", { userId: player?.id, gameId, cardIndex: 1 });
  }
  const handleShowBothCard = () => {
    socket.emit("showCard", { userId: player?.id, gameId, cardIndex: "showBothCard" });
  }
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    const inputElements = document.querySelectorAll("input[type='number']");

    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleScroll, { passive: false });
    });

    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleScroll);
      });
    };
  }, []);

  return (
    <div className="player-game-action-grid show-card">
      {currentPlayer?.id === userId && !currentPlayer?.tentativeAction && !isReset ? (
        <div className="player-game-action-content">
          {currentPlayer?.id === userId
            && !currentPlayer?.tentativeAction
            && currentPlayer.id === userId
            && currentPlayer.wallet > gameData.raiseAmount
            && !openAction.allin
            && Object.values(openAction).find((el) => el) && (
              <div className="player-range-action-wrapper">
                <div className="player-range-input">
                  <InputRange
                    maxValue={currentPlayer?.wallet}
                    minValue={gameData?.raiseAmount}
                    value={v}
                    onChange={(value) => handleChange(value)}
                    formatLabel={() => ""}
                    draggableTrack={false}
                  />
                </div>
                <div className="player-pot-input">
                  <Button
                    className="pot-input-btn"
                    disabled={
                      !(
                        (gameData.pot + playersPot <= currentPlayer.wallet
                          && (gameData.pot + playersPot) / 2
                            >= gameData?.raiseAmount)
                      )
                    }
                    onClick={() => setValues(gameData.pot + playersPot)}
                    title={`$${gameData.pot + playersPot}`}
                  >
                    POT
                  </Button>
                  <Button
                    className="pot-input-btn"
                    disabled={
                      !(
                        ((gameData.pot + playersPot) / 2
                          <= currentPlayer.wallet
                          && (gameData.pot + playersPot) / 2
                            >= gameData?.raiseAmount)
                      )
                    }
                    onClick={() =>
                      setValues(Math.round((gameData.pot + playersPot) / 2))}
                    title={`$${Math.round((gameData.pot + playersPot) / 2)}`}
                  >
                    1/2
                  </Button>
                  {/* <Button
                className="pot-input-btn"
                disabled={
                  !(
                    ((gameData.pot + playersPot) / 4 <= currentPlayer.wallet
                      && (gameData.pot + playersPot) / 4
                        >= gameData?.raiseAmount)
                    || remainingTime !== 0
                  )
                }
                onClick={() =>
                  setValues(Math.round((gameData.pot + playersPot) / 4))}
                title={`$${Math.round((gameData.pot + playersPot) / 4)}`}
              >
                1/4
              </Button> */}
                </div>
              </div>
          )}
          {currentPlayer.id === userId
         && Object.values(openAction).find((el) => el) ? (
            <>
              {openAction.bet && !bet && (
                <div className="bet-btn-wrapper">
                  <div className="bet-input-wrapper">
                    <span
                      className="increment-icon-btn"
                      onClick={handleDecrement}
                      role="presentation"
                    >
                      <MinusIcon />
                    </span>
                    <Form.Control
                      type="number"
                    //  defaultValue={values}
                      value={
                        currentPlayer?.wallet > values
                          ? v
                          : currentPlayer?.wallet
                      }
                      onChange={handleInputChange}
                    />
                    <span
                      className="increment-icon-btn"
                      onClick={handleIncrement}
                      role="presentation"
                    >
                      <PlusIcon />
                    </span>
                  </div>
                  <Button
                    className="player-raise-btn"
                    disabled={remainingTime <= 0}
                    onClick={handleBetAction}
                  >
                    Bet
                  </Button>
                </div>
              )}
              {openAction.raise && !raise && (
                <div className="bet-btn-wrapper">
                  <div className="bet-input-wrapper">
                    <span
                      className="increment-icon-btn"
                      onClick={handleDecrement}
                      role="presentation"
                    >
                      <MinusIcon />
                    </span>
                    <Form.Control
                      type="number"
                    //  defaultValue={
                    //    values > currentPlayer?.wallet
                    //      ? currentPlayer?.wallet
                    //      : v
                    //  }
                      value={
                        values > currentPlayer?.wallet
                          ? currentPlayer?.wallet
                          : v
                      }
                      onChange={handleInputChange}
                    />
                    <span
                      className="increment-icon-btn"
                      onClick={handleIncrement}
                      role="presentation"
                    >
                      <PlusIcon />
                    </span>
                  </div>
                  <Button
                    className="player-raise-btn"
                    disabled={remainingTime <= 0}
                    onClick={handleConfirm}
                  >
                    Raise
                  </Button>
                </div>
              )}

              {!openAction.raise && !openAction.bet && openAction.allin && (
                <Button
                  className="player-allin-btn"
                  disabled={remainingTime <= 0}
                  onClick={allinAction}
                >
                  All-In
                </Button>
              )}
              {openAction.call && (
                <Button
                  className="player-call-btn"
                  disabled={remainingTime <= 0}
                  onClick={handleCallAction}
                >
                  Call
                  <br />
                    $
                {convertAmmount(gameData.raiseAmount - currentPlayer.pot)}
                </Button>
              )}
              {openAction.check && (
                <Button
                  className="player-check-btn"
                  disabled={remainingTime <= 0}
                  onClick={checkAction}
                >
                  Check
                </Button>
              )}
              {!openAction.call
                && !openAction.check
                && gameData.raiseAmount !== player.pot && (
                  <span className="placeholder-btn-action" />
              )}
              {openAction.fold && (
                <Button
                  className="player-fold-btn"
                  disabled={remainingTime <= 0}
                  onClick={foldAction}
                >
                  Fold
                </Button>
              )}
            </>
            ) : (
            <div className="player-placeholder-btns">
              <span className="placeholder-btn" />
              <span className="placeholder-btn" />
              <span className="placeholder-btn" />
            </div>
            )}
          {/* <Button
            className="player-tip-btn disable-btn"
            onClick={handleTipDealer}
          >
            Tip Dealer
          </Button> */}
        </div>
      ) : (
        gameData?.gamestart
        && gameData?.runninground >= 1
        && gameData?.runninground < 5
        && player?.cards?.length === 2
        && !player?.fold
        && !(gameData?.lastAction === "check" && player?.action === true)
        && player.actionType !== "all-in" && (
          <div className="player-game-action-content player-game-action-check-content">
            {getTentativeAction()}
          </div>
        )
      )}
      {timer
      && currentPlayer.id === userId
      && remainingTime >= 0
      && !isReset
      && !currentPlayer?.tentativeAction ? (
        <ProgressBar now={(remainingTime * 100) / timer} />
        ) : (
          ""
        )}
      {(!winnerPlayer?.find((el) => el)?.handName
       && winnerPlayer?.find((el) => el.id === userId)) && (
        showDownPlayersCards?.map((pl) => (
        <div className="player-placeholder-btns show-card-btn" key={pl?.id}>
          <Button className="show-bg-btn" onClick={handleShowBothCard}>
            <span>Show</span>
           {pl.cards.map((c) => (
            <div className="show-btn-card" key={c}>
              <img src={`/cards/${DoDecrypt(c)}.svg`} alt="card" />
            </div>
           ))}
          </Button>
          <Button className="show-outline-btn" onClick={handleShowSecondCard}>
            <span>Show</span>
            <div className="show-btn-card">
              <img src={`/cards/${DoDecrypt(pl?.cards[1])}.svg`} alt="card" />
            </div>
          </Button>
          <Button className="show-outline-btn" onClick={handleShowFirstCard}>
            <span>Show</span>
            <div className="show-btn-card">
              <img src={`/cards/${DoDecrypt(pl?.cards[0])}.svg`} alt="card" />
            </div>
          </Button>
        </div>
        ))
      )}
    </div>
  );
}
PlayerAction.defaultProps = {
  currentPlayer: {},
  gameData: {},
  player: {},
};

PlayerAction.propTypes = {
  openAction: PropsTypes.object.isRequired,
  setOpenAction: PropsTypes.func.isRequired,
  bet: PropsTypes.bool.isRequired,
  //  setBet: PropsTypes.func.isRequired,
  raise: PropsTypes.bool.isRequired,
  //  setRaise: PropsTypes.func.isRequired,
  currentPlayer: PropsTypes.object,
  setTimer: PropsTypes.func.isRequired,
  remainingTime: PropsTypes.number.isRequired,
  gameData: PropsTypes.object,
  handleStopSound: PropsTypes.func.isRequired,
  timer: PropsTypes.number.isRequired,
  player: PropsTypes.object,
};

export default PlayerAction;

// function RaiseWidget({ values, setValues, raiseAmount, wallet }) {
//  const [v, setV] = useState();

//  useEffect(() => {
//    setV(values);
//  }, [values]);
//  const handleChange = (value) => {
//    if (value >= raiseAmount && value <= wallet) {
//      setValues(value);
//    }
//  };

//  const handleInputChange = (e) => {
//    if (e.target.value <= wallet && e.target.value >= raiseAmount) {
//      setValues(Number(e.target.value));
//    }
//  };

//  return (
//    <div className="raise-widget">
//      <div className="raise-widget-content">
//        <Form onSubmit={(e) => e.preventDefault()}>
//          <div className="raise-slider change-stack">
//            <Form.Control
//              type="number"
//              defaultValue={v}
//              onChange={handleInputChange}
//            />
//            <InputRange
//              maxValue={wallet}
//              minValue={raiseAmount}
//              value={values}
//              onChange={(value) => handleChange(value)}
//              formatLabel={() => ""}
//            />
//          </div>
//          <div className="raise-quick-bets">
//            <div className="raise-quick-bets-row">
//              <Button onClick={() => handleChange(values - 50)}>- $50</Button>
//              <Button onClick={() => handleChange(values + 50)}>+ $50</Button>
//            </div>
//            <div className="raise-quick-bets-row">
//              {values + 100 <= wallet && (
//                <Button onClick={() => setValues(values + 100)}>$100</Button>
//              )}
//              {values + 200 <= wallet && (
//                <Button onClick={() => setValues(values + 200)}>$200</Button>
//              )}
//              {values + 500 <= wallet && (
//                <Button onClick={() => setValues(values + 500)}>$500</Button>
//              )}
//              {values + 1000 <= wallet && (
//                <Button onClick={() => setValues(values + 1000)}>$1000</Button>
//              )}

//              <Button onClick={() => setValues(wallet)} className="all-in-btn">
//                All in
//              </Button>
//            </div>
//          </div>
//        </Form>
//      </div>
//    </div>
//  );
// }

// RaiseWidget.propTypes = {
//  setValues: PropsTypes.func.isRequired,
//  values: PropsTypes.number.isRequired,
//  raiseAmount: PropsTypes.number.isRequired,
//  wallet: PropsTypes.number.isRequired,
// };
