import React from "react";
import PropsTypes from "prop-types";
// import { useParams } from "react-router-dom";
import ViewersIcon from "../../../IconComponents/ViewersIcon";
// import PhoneIcon from "../../../IconComponents/PhoneIcon";
// import socket from "../../../config/socket";
// import UserContext from "../../../context/UserContext";

function ViewerTab({ allGameViewers, viewersCounts, tableData }) {
//  const { gameId } = useParams();
//  const { user, setMessages } = useContext(UserContext);
//  const handleOneToOneRequest = (requestedTo, name) => {
//    socket.emit("oneToOneRequest", {
//      requestedBy: user.id,
//      requestedTo,
//      requestedToName: name,
//      requestedByName: user.name,
//      gameId,
//    });
//    setMessages((old) => [
//      ...old,
//      {
//        msg: "Request has been send",
//        id: user.id + requestedTo,
//        type: "success",
//      },
//    ]);
//  };

  return (
    <div className="chat-wrapper viewer-wrapper">
      <div className="tab-card-head">
        <ViewersIcon />
        <h4>
          Viewers
          <span>{viewersCounts}</span>
        </h4>
      </div>
      <div className="tab-card-body">
        {allGameViewers?.map((el, ind) =>
          (el?.viewers?.length > 0 ? (
            <>
              <div className="tab-card-element" key={`${el?.gameId}${ind + 1}`}>
                <p className="element-title">
                  {tableData === "All Tables" ? `Table #${ind + 1}` : tableData}
                </p>
              </div>
              {el.viewers.map((viewer) => (
                <div className="tab-card-element" key={viewer?.id}>
                  <div className="hand-history-head rank-element-head viewer-element-head">
                    <div className="hand-history-head-left">
                      <div className="hand-history-profile-wrapper">
                        <div
                          className={`viewer-profile-status ${
                            viewer?.status === "online" ? "online" : "offline"
                          }`}
                        >
                          <img
                            src={viewer?.avatar}
                            onError={({ currentTarget }) => {
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.onerror = null; // prevents looping
                              // eslint-disable-next-line no-param-reassign
                              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                            }}
                            alt="user"
                          />
                        </div>
                        <div className="hand-winner-name">
                          <p>{viewer?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="rank-head-right">
                      {/* <div className="viewer-call-icon" role="presentation">
                        {user.id !== viewer.id && gameId === el.gameId ? (
                          <span
                            role="presentation"
                            onClick={() =>
                              handleOneToOneRequest(viewer?.id, viewer?.name)}
                          >
                            <PhoneIcon />
                          </span>
                        ) : (
                          ""
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            ""
          ))
        )}
      </div>
    </div>
  );
}
ViewerTab.defaultProps = {
  allGameViewers: [],
  viewersCounts: 0,
  tableData: "All Tables",
};
ViewerTab.propTypes = {
  allGameViewers: PropsTypes.array,
  viewersCounts: PropsTypes.number,
  tableData: PropsTypes.string,
};
export default ViewerTab;
