import { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import "react-input-range/lib/css/index.css";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";

function ChangeStack({ userId, handleStackClose }) {
  const { gameData } = useContext(UserContext);
  const [values, setValues] = useState();

  const handleInputChange = (e) => {
    setValues(Number(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    socket.emit("updateUserWallet", { gameId: gameData.id, userId, chips: values })
    handleStackClose();
  };
  return (
    <div className="change-stack">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Form.Control
          type="number"
          placeholder="Enter amount"
          defaultValue={values}
          onChange={handleInputChange}
          autoFocus
        />
        <div className="stack-btn">
          <Button onClick={handleStackClose}>Cancel</Button>
          <Button type="submit">OK</Button>
        </div>
      </Form>
    </div>
  );
}
ChangeStack.defaultProps = {
  userId: null
}
ChangeStack.propTypes = {
  userId: PropTypes.string,
  handleStackClose: PropTypes.func.isRequired,
};

export default ChangeStack;
