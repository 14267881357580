/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import InputRange from "react-input-range";
import { ReactMic } from "react-mic";
import Error from "../toast/erros";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./settings.css";
import UserContext from "../../context/UserContext";

const InitialState = {
  mic: localStorage.getItem("mic")
    ? JSON.parse(localStorage.getItem("mic"))
    : "",
  speaker: localStorage.getItem("speaker")
    ? JSON.parse(localStorage.getItem("speaker"))
    : "",
  camera: localStorage.getItem("camera")
    ? JSON.parse(localStorage.getItem("camera"))
    : "",
  inputVolume: localStorage.getItem("inputVolume")
    ? Number(localStorage.getItem("inputVolume"))
    : 0.5,
  outputVolume: localStorage.getItem("outputVolume")
    ? Number(localStorage.getItem("outputVolume"))
    : 0.5,
  barRoomBackgroundSound: localStorage.getItem("barRoomBackgroundSound")
    ? Number(localStorage.getItem("barRoomBackgroundSound"))
    : 0.5,
  tableConversationsSound: localStorage.getItem("tableConversationsSound")
    ? Number(localStorage.getItem("tableConversationsSound"))
    : 0.5,
};

function BarRoomSetting() {
  const {
    setPokerTableVolume,
    setNoiseCancellation,
    noiseCancellation,
    setUserMic,
    setUserCamera,
    setUserSpeaker,
    setbarroomVolume,
    setMicValue,
  } = useContext(UserContext);
  const [micOption, setMicOption] = useState([]);
  const [speakerOption, setSPeakerOption] = useState([]);
  const [cameraOption, setCameraOption] = useState([]);
  const [values, setValues] = useState(InitialState);
  const [message, setMessage] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [micTest, setMicTest] = useState(false);
  const [record, setRecord] = useState(false);
  const videoRef = useRef();
  const handleChange = (value, type) => {
    setValues({ ...values, [type]: value });
    if (type === "mic") {
      setUserMic(value);
    } else if (type === "camera") {
      setIsVideoPlaying(false);
      setUserCamera(value);
    } else if (type === "speaker") {
      setUserSpeaker(value);
    }
    if (["mic", "camera", "speaker"].includes(type)) {
      localStorage.setItem(type, JSON.stringify(value));
    } else {
      localStorage.setItem(type, value);
    }
    if (type === "outputVolume") {
      setPokerTableVolume(value);
      localStorage.setItem("tableConversationsSound", JSON.stringify(value));
      setbarroomVolume(value);
      localStorage.setItem("barRoomBackgroundSound", JSON.stringify(value));
    }
    if (type === "inputVolume") {
      setMicValue(value?.toString());
    }
    if (type === "tableConversationsSound") {
      setPokerTableVolume(value);
    }
    if (type === "barRoomBackgroundSound") {
      setbarroomVolume(value);
    }
  };

  useEffect(() => {
    const getMediaDevices = async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const videoCameras = allDevices.filter((m) => m.kind === "videoinput");
      const microphones = allDevices.filter((m) => m.kind === "audioinput");
      const speakers = allDevices.filter((m) => m.kind === "audiooutput");
      setCameraOption(
        videoCameras.map((camera) => ({
          label: camera.label,
          value: camera.deviceId,
        }))
      );
      setMicOption(
        microphones.map((mic) => ({ label: mic.label, value: mic.deviceId }))
      );
      setSPeakerOption(
        speakers.map((speaker) => ({
          label: speaker.label,
          value: speaker.deviceId,
        }))
      );
    };
    getMediaDevices();
  }, []);

  const startVideoTesting = () => {
    if (!values.camera) {
      setMessage("Please select camera first");
      return setTimeout(() => {
        setMessage("");
      }, 2000);
    }
    setIsVideoPlaying(true);
    if (!navigator?.mediaDevices?.getUserMedia) {
      setMessage("User media not supported");
      return setTimeout(() => {
        setMessage("");
      }, 2000);
    }
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: values.camera.value } })
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`${error.name}: ${error.message}`);
      });
  };

  const handleSuccess = (stream) => {
    // console.log(stream)
    const audio = document.getElementById("audio");
    audio.src = stream.blobURL;
  };

  const handleMicTest = () => {
    if (!micTest) {
      if (!values.mic || !values.speaker) {
        setMessage("Please select mic & Speaker device first");
        return setTimeout(() => {
          setMessage("");
        }, 2000);
      }
    }
    setMicTest(!micTest);
    setTimeout(() => setRecord(!record), 400);
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      background: "#454851",
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "16px",
      cursor: "pointer",
      ":hover": {
        background: "#4C505E",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#454851",
      padding: "0px",
    }),
    control: () => ({
      background: "#454851",
      border: "1px solid #A0AABB",
      borderRadius: "6px",
      color: "#fff",
      display: "flex",
      alignItem: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
    }),
  };

  const [isOff, setIsOff] = useState(
    localStorage.getItem("noise-off")
      ? JSON.parse(localStorage.getItem("noise-off"))
      : true
  );

  const handleButtonClick = () => {
    localStorage.setItem("noise-off", !isOff);
    setIsOff(!isOff);
    setNoiseCancellation(!noiseCancellation);
  };

  return (
    <div className="bar-room-settings">
      <h2>Settings</h2>
      <div className="bar-room-settings-grid">
        <div className="bar-room-settings-audio change-stack">
          <div className="bar-room-audio-grid-half">
            <h4>Mic</h4>
            <div className="form-group">
              <Form.Label>Input device</Form.Label>
              <Select
                options={micOption}
                onChange={(value) => handleChange(value, "mic")}
                name="mic"
                defaultValue={values.mic}
                value={
                  JSON.parse(localStorage.getItem("mic"))
                    ? JSON.parse(localStorage.getItem("mic"))
                    : values?.mic
                }
                styles={customStyles}
              />
            </div>
            {/* <div className="form-group">
              <Form.Label>Input volume</Form.Label>
              <InputRange
                maxValue={1}
                minValue={0}
                step={0.1}
                value={
                  JSON.parse(localStorage.getItem("inputVolume"))
                    ? JSON.parse(localStorage.getItem("inputVolume"))
                    : values.inputVolume
                }
                onChange={(value) => handleChange(value, "inputVolume")}
              />
            </div> */}
          </div>
          <div className="bar-room-audio-grid-half">
            <h4>Speakers</h4>
            <div className="form-group">
              <Form.Label>Output device</Form.Label>
              <Select
                options={speakerOption}
                onChange={(value) => handleChange(value, "speaker")}
                name="speaker"
                styles={customStyles}
                value={
                  JSON.parse(localStorage.getItem("speaker"))
                    ? JSON.parse(localStorage.getItem("speaker"))
                    : values?.speaker
                }
              />
            </div>
            <div className="form-group">
              <Form.Label>Output volume</Form.Label>
              <InputRange
                maxValue={1}
                minValue={0}
                step={0.1}
                value={values.outputVolume}
                onChange={(value) => handleChange(value, "outputVolume")}
              />
            </div>
          </div>
          <div className="bar-room-audio-grid-full">
            <audio id="audio" autoPlay />
            <h4>Mic test</h4>
            <div className="form-group">
              {micTest ? (
                <>
                  <ReactMic
                    record={record}
                    className="sound-wave"
                    onStop={handleSuccess}
                    strokeColor="#000000"
                    backgroundColor="#FF4081"
                  />
                  <Button onClick={() => setRecord(!record)}>
                    play record
                  </Button>
                </>
              ) : (
                ""
              )}
              <p>
                Start a test and say something fun - we&apos;ll play your voice
                back to you.
              </p>
              <Button onClick={handleMicTest}>
                {micTest ? "Stop" : "Test Mic"}
              </Button>
              {/* <Button className="stop-teting-btn">Stop Testing</Button> */}
            </div>
          </div>
          <div className="bar-room-audio-grid-full">
            <h4>Sounds</h4>
            <div className="sound-grid">
              <div className="form-group">
                <Form.Label>Rail participants conversation</Form.Label>
                <InputRange
                  maxValue={1}
                  minValue={0}
                  step={0.1}
                  value={values.barRoomBackgroundSound}
                  onChange={(value) =>
                    handleChange(value, "barRoomBackgroundSound")}
                />
              </div>
              <div className="form-group">
                <Form.Label>Main Game Conversation</Form.Label>
                <InputRange
                  maxValue={1}
                  minValue={0}
                  step={0.1}
                  value={values.tableConversationsSound}
                  onChange={(value) =>
                    handleChange(value, "tableConversationsSound")}
                />
              </div>
            </div>
          </div>
          <div className="bar-room-audio-grid-full">
            <div className="sound-grid">
              <div className="form-group">
                <h4>Noise Cancellation</h4>

                <input
                  className="switch"
                  type="checkbox"
                  onChange={handleButtonClick}
                  checked={
                    !(
                      localStorage.getItem("noise-off")
                      && !JSON.parse(localStorage.getItem("noise-off"))
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bar-room-settings-video">
          <div className="bar-room-audio-grid-full">
            <h4>Video</h4>
            <div className="bar-room-video-wall-grid">
              <div className="bar-room-video-placeholder full-w">
                {isVideoPlaying ? (
                  <video
                    style={{ width: "100%", height: "300px" }}
                    ref={videoRef}
                    autoPlay
                    controls={false}
                  />
                ) : (
                  ""
                )}
                {!isVideoPlaying ? (
                  <Button onClick={startVideoTesting}>Test Video</Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="bar-room-audio-grid-full">
            <div className="form-group">
              <Form.Label>Camera</Form.Label>
              <Select
                options={cameraOption}
                onChange={(value) => handleChange(value, "camera")}
                name="camera"
                defaultValue={
                  JSON.parse(localStorage.getItem("camera"))
                    ? JSON.parse(localStorage.getItem("camera"))
                    : values.camera
                }
                styles={customStyles}
              />
            </div>
          </div>
          {/* <Button onClick={startVideoTesting}>Test Video</Button> */}
          {/* <Button className="stop-teting-btn">Stop Testing</Button> */}
        </div>
      </div>
      {message ? <Error message={message} /> : ""}
    </div>
  );
}

export default BarRoomSetting;
