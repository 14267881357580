import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import PropsTypes from "prop-types";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";

function SetChips({ handleChipsClose }) {
  const { gameData } = useContext(UserContext);
  const [chips, setChips] = useState();
  const [type, setType] = useState("both");
  const [minBlind, setMinBlind] = useState();

  const handleSubmit = async () => {
    socket.emit("setChipsAndMinBlind", { gameId: gameData.id, chips, minBlind, type })
    handleChipsClose();
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "chips") setChips(Number(value));
    if (name === "minBlind") setMinBlind(Number(value));
    if (name === "set-chips") {
      setType(value);
    }
  };
  return (
    <div className="set-chips">
      <Form>
        <Form.Group className="set-chip-radio">
          <Form.Check
            type="radio"
            label="Chips"
            id="setChips"
            name="set-chips"
            value="chips"
            onChange={handleChange}
          />

          <Form.Check
            type="radio"
            label="Blinds"
            id="setBlind"
            value="blinds"
            name="set-chips"
            onChange={handleChange}
          />
          <Form.Check
            type="radio"
            label="Both"
            id="setBoth"
            value="both"
            name="set-chips"
            onChange={handleChange}
            defaultChecked={type === "both"}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Chips</Form.Label>
          <Form.Control
            type="number"
            name="chips"
            placeholder="0"
            defaultValue={chips}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Minimum blinds</Form.Label>
          <Form.Control
            type="number"
            name="minBlind"
            placeholder="0"
            defaultValue={minBlind}
            onChange={handleChange}
          />
        </Form.Group>
        <div className="stack-btn">
          <Button onClick={handleSubmit}>Set</Button>
        </div>
      </Form>
    </div>
  );
}

SetChips.propTypes = {
  handleChipsClose: PropsTypes.func.isRequired
}
export default SetChips;
