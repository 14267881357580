/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState } from "react";
import PropsTypes from "prop-types";
import guest from "../../../assets/images/waitingroom/guests.svg";
import JoinErrorModal from "../waitingRoomPopup/joinErrorModal";
import WaitingRoomParticipant from "./WaitngRoomParticipant";

function WaitingRoomCamera({ waitingPlayers, videoSrc }) {
  const [errorPopup, setErrorPopup] = useState(false);

  const handleJoinError = () => {
    setErrorPopup(!errorPopup);
  };

  return (
    <div className="wr-camera-content">
      <div className="wr-camera-grid">
        <div className="wr-camera-gridbox-center">
          <div className="wr-main-camera guestImgContainer">
            {videoSrc ? (
              <video
                id="waiting-intro-video"
                playsInline
                preload="auto"
                src={videoSrc}
                autoPlay
                muted
                controls
                loop
              />
            ) : (
              <img src={guest} alt="guest" />
            )}
          </div>
        </div>
        <div className="wr-camera-gridbox">
          {[0, 1, 2, 3, 4, 5, 6, 7].map((el) => (
            <WaitingRoomParticipant
              user={waitingPlayers.length > el ? waitingPlayers[el] : null}
              key={`item-${el + 1}`}
            />
          ))}
        </div>
      </div>
      <JoinErrorModal joinError={errorPopup} handleJoinError={handleJoinError} />
    </div>
  );
}
WaitingRoomCamera.propTypes = {
  waitingPlayers: PropsTypes.array.isRequired,
  videoSrc: PropsTypes.string.isRequired,
};
export default WaitingRoomCamera;
