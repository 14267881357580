/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";
import PropsTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ShutterIcon from "../../../IconComponents/ShutterIcon";
import SettingOutlineIcon from "../../../IconComponents/SettingOutlineIcon";
import arrow from "../../../assets/images/inrovideo/arrow.svg";
import ChatBtnIcon from "../../../IconComponents/ChatBtnIcon";
import SendIcon from "../../../IconComponents/SendIcon";
import ChatEmoji from "../../chatEmoji/chatEmoji";

function CamModal({
  showCamModal,
  setShowCamModal,
  takepicture,
  handleSendMessageWithImage,
  preview,
  allCameraOptions,
  file,
  clearphoto,
  handleCamerOptions,
  handleCaption,
  sendImgLoader,
  selectedCamera,
  cameraError,
}) {
  const [showCamOption, setShowCamOption] = useState(false);
  const [caption, setCaption] = useState("");
  const [showEmoji, setShowEmoji] = useState("");
  const handleCamClose = () => {
    setShowCamModal(false);
  };
  const handleFocus = (e) => {
    if (e.target.paused) e.target.play();
    else e.target.pause();
  };

  const handleCamOptions = () => {
    setShowCamOption(!showCamOption);
  };

  const handleChangeCaption = (e, name) => {
    if (name === "emoji") {
      setCaption((old) => old + e);
    } else {
      setCaption(e.target.value);
    }
  };

  useEffect(() => {
    handleCaption(caption);
  }, [caption]);

  useEffect(() => {
    setCaption("");
  }, [showCamModal]);

  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End

  return (
    <Modal
      show={showCamModal}
      onHide={handleCamClose}
      centered
      className="poker-popup player-history-modal cam-input-modal"
      style={{
        transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {preview ? (
            <span
              className="preview-btn"
              onClick={clearphoto}
              role="presentation"
            >
              <img src={arrow} alt="" />
              Preview
            </span>
          ) : (
            "Take a photo"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="selfie-cam">
          {preview ? (
            file?.file?.type?.includes("image") ? (
              <img src={preview} alt="user" />
            ) : (
              <video
                src={preview}
                onClick={handleFocus}
                playsInline
                controls={false}
              />
            )
          ) : cameraError ? (
            <div className="camera-not-found">
              <div>
                <h4>Camera not found</h4>
                <p>Please check the connection</p>
              </div>
            </div>
          ) : (
            <>
              <video id="video">Video stream not available.</video>
              <canvas
                id="canvas"
                width="403.33"
                height="303"
                style={{ visibility: "hidden", position: "absolute" }}
              >
                {" "}
              </canvas>
            </>
          )}
        </div>
        {!preview ? (
          <div className="cam-shutter-btn-wrapper">
            <div style={{ width: "24px" }} />
            <span
              role="presentation"
              className="shutter-btn"
              onClick={takepicture}
              style={{
                pointerEvents: `${cameraError ? "none" : "unset"}`,
                opacity: `${cameraError ? "0.5" : "1"}`,
              }}
            >
              <ShutterIcon />
            </span>
            <div className="cam-setting-btn-wrapper">
              <span
                className="cam-select-btn"
                onClick={() => handleCamOptions()}
                role="presentation"
                style={{
                  pointerEvents: `${cameraError ? "none" : "unset"}`,
                  opacity: `${cameraError ? "0.5" : "1"}`,
                }}
              >
                <SettingOutlineIcon />
              </span>
              <div className={`drop-wrapper ${showCamOption ? "show" : ""}`}>
                {allCameraOptions.map((camera) => (
                  <div className="drop-item" key={camera.label}>
                    <div className="invite-user-action">
                      <Form.Check
                        className="add-check-box"
                        type="radio"
                        id={camera.label}
                        name="camera"
                        label={camera.label}
                        value={camera.value}
                        checked={selectedCamera === camera.value}
                        onChange={() => handleCamerOptions(camera)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="cam-shutter-btn-wrapper">
            <div className="tab-card-bottom">
              <Form
                className="Chat-input-wrapper"
                onSubmit={(e) => handleSendMessageWithImage(e)}
              >
                <Form.Control
                  type="text"
                  placeholder="Add a caption..."
                  onChange={(e) => handleChangeCaption(e, "chat")}
                  value={caption}
                />
                <div className="cam-emoji-wrapper">
                  <span
                    className="emoji-input-btn"
                    onClick={() => setShowEmoji("caption")}
                    role="presentation"
                  >
                    <ChatBtnIcon />
                  </span>
                </div>
                <div
                  className={`chat-input-emoji ${
                    showEmoji === "caption" ? "show" : ""
                  }`}
                >
                  <ChatEmoji
                    name="chat"
                    // selectEmoji={handleChangeCaption}
                    setShowEmoji={setShowEmoji}
                    handleReaction={handleChangeCaption}
                  />
                </div>

                <Button
                  className="bg-btn"
                  type="submit"
                  disabled={sendImgLoader}
                >
                  {sendImgLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <SendIcon />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

CamModal.defaultProps = {
  cameraError: false,
};

CamModal.propTypes = {
  showCamModal: PropsTypes.bool.isRequired,
  setShowCamModal: PropsTypes.func.isRequired,
  takepicture: PropsTypes.func.isRequired,
  file: PropsTypes.object.isRequired,
  handleSendMessageWithImage: PropsTypes.func.isRequired,
  preview: PropsTypes.string.isRequired,
  allCameraOptions: PropsTypes.array.isRequired,
  clearphoto: PropsTypes.func.isRequired,
  handleCamerOptions: PropsTypes.func.isRequired,
  handleCaption: PropsTypes.func.isRequired,
  sendImgLoader: PropsTypes.bool.isRequired,
  selectedCamera: PropsTypes.string.isRequired,
  cameraError: PropsTypes.bool,
};

export default CamModal;
