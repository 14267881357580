import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import DealerCompanion from "./components/dealer/dealercompanion";
import SignUp from "./components/signup/signup";
import Login from "./components/login/login";
import CreateProfile from "./components/profile/profile";
import Game from "./components/dealer/dashboard";
import VerifyEmail from "./components/signup/verify-email";
import Forgot from "./components/forgot/forgot";
import ResetPassword from "./components/resetPassword/ResetPassword";
import UserContext from "./context/UserContext";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import NotFound from "./components/NotFound/NotFound";
import UserDashboard from "./components/User/UserDashboard";
import IntroVideo from "./components/introVideo";
import { getAllGameEvents, getUser } from "./utils/Api";
import loadericon from "./assets/images/dealer/loader.json";
import WaitingRoom from "./components/User/waitingroom";
import RequestAccess from "./components/requestAccess/requestAccess";
import "react-phone-input-2/lib/bootstrap.css";
import "./assets/css/line-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import WentWrong from "./components/AccessDenied/WentWrong";
import { groupItems } from "./utils";
import NewGameView from "./components/User/playerGameView/newGameView";
import "./components/barRoom/style.css"
import "./components/barRoom/settings.css"

function PrivateRoute({ component: RouteComponent, roles }) {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const userHasRequiredRole = !!(user && roles.includes(user.role));

  if (user && !userHasRequiredRole) {
    return <AccessDenied />;
  }

  if (user && location.pathname === "/") {
    return <Navigate to={`/${user.role}`} />;
  }

  if (userHasRequiredRole) {
    return RouteComponent;
  }
  const redirect = location.pathname;
  if (redirect) {
    const isGameLink = redirect.split("/");
    if (isGameLink.length === 4) {
      localStorage.setItem(
        "redirectLink",
        `/user/waiting-room/${isGameLink[3]}`
      );
    }
    return <Navigate to={`/signup?redirect=${redirect}`} />;
  }
  return <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
  component: PropTypes.element,
  roles: PropTypes.array.isRequired,
};

PrivateRoute.defaultProps = {
  component: <>g</>,
};

const LoaderAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadericon,
};

function App() {
  const [user, setUser] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [callObject, setCallObject] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 14))
  );
  const [messages, setMessages] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [gameData, setGameData] = useState({});
  const [livePlayers, setLivePlayers] = useState([]);
  const [gamePlayers, setGamePlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlayerLoading, setIsPlayerLoading] = useState(true);
  const [gameEvent, setGameEvent] = useState();
  const [speaker, setSpeaker] = useState(
    localStorage.getItem("speakerVolume") !== "false"
  );
  const [oneToOneRoom, setOneToOneRoom] = useState([]);
  const [barroomVolume, setbarroomVolume] = useState(
    localStorage.getItem("barRoomBackgroundSound")
      ? Number(localStorage.getItem("barRoomBackgroundSound"))
      : 0.6
  );
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [historySidebar, setHistorySidebar] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [oneToOneDuration, setOneToOneDuration] = useState("00:00");
  const [games, setGames] = useState([]);
  const [tokens, setTokens] = useState(
    localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : {}
  );
  const [pokerTableVolume, setPokerTableVolume] = useState(
    localStorage.getItem("tableConversationsSound") !== null
      ? Number(localStorage.getItem("tableConversationsSound"))
      : 0.6
  );
  const [noiseCancellation, setNoiseCancellation] = useState(
    localStorage.getItem("noise-off")
      ? JSON.parse(localStorage.getItem("noise-off"))
      : false
  );
  const [blurBackGround, setBlurBackGround] = useState("background-image");
  const [bgState, setBgState] = useState(true);
  const [userMic, setUserMic] = useState(
    JSON.parse(localStorage.getItem("mic"))
  );
  const [userCamera, setUserCamera] = useState(
    JSON.parse(localStorage.getItem("camera"))
  );
  const [userSpeaker, setUserSpeaker] = useState(
    JSON.parse(localStorage.getItem("speaker"))
  );
  const [micDisable, setMicDisable] = useState(JSON.parse(localStorage.getItem("dealerMuteAll"))
    ? JSON.parse(localStorage.getItem("dealerMuteAll")) : false);

  const [allGamesData, setAllGamesData] = useState({});
  const [micOn, setMicOn] = useState(
    JSON.parse(localStorage.getItem("isMicOn")) !== null ? JSON.parse(localStorage.getItem("isMicOn")) : true
  );
  const [camOn, setCamOn] = useState(
    JSON.parse(localStorage.getItem("isCameraOn")) !== null ? JSON.parse(localStorage.getItem("isCameraOn")) : true
  );

  const [initialZoom, setInitialZoom] = useState(
    localStorage.getItem("communityVideoZoom")
      ? Number(localStorage.getItem("communityVideoZoom"))
      : 1
  );

  const [micValue, setMicValue] = useState(localStorage.getItem("outputVolume") ? localStorage.getItem("outputVolume") : "60");
  const [showProfileInfo, setShowProfileInfo] = useState("")

  const getUserCallback = (data) => {
    setIsLoading(false);
    if (data.success) {
      setUser(data.user);
    }
  };
  useEffect(() => {
    getUser(getUserCallback);
  }, []);

  const fetchGames = async (
    sKey = "",
    sDate = new Date(),
    eDate = new Date(new Date().setDate(new Date().getDate() + 14))
  ) => {
    const res = await getAllGameEvents(
      sDate.toISOString(),
      eDate.toISOString(),
      sKey
    );
    const groups = groupItems(res, "scheduleDate");
    setGames(groups);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        tokens,
        setTokens,
        livePlayers,
        setLivePlayers,
        gameData,
        messages,
        setMessages,
        setGameData,
        setParticipants,
        oneToOneDuration,
        setOneToOneDuration,
        participants,
        gamePlayers,
        setGamePlayers,
        showProfileModal,
        setOneToOneRoom,
        oneToOneRoom,
        setShowProfileModal,
        setCallObject,
        callObject,
        barroomVolume,
        setbarroomVolume,
        pokerTableVolume,
        setPokerTableVolume,
        noiseCancellation,
        setNoiseCancellation,
        historySidebar,
        setHistorySidebar,
        deviceStatus,
        setDeviceStatus,
        setGameEvent,
        gameEvent,
        games,
        setGames,
        fetchGames,
        startDate,
        endDate,
        searchKey,
        setSearchKey,
        setStartDate,
        setEndDate,
        blurBackGround,
        setBlurBackGround,
        bgState,
        setBgState,
        userMic,
        setUserMic,
        userCamera,
        setUserCamera,
        userSpeaker,
        setUserSpeaker,
        micDisable,
        setMicDisable,
        setSpeaker,
        speaker,
        setAllGamesData,
        allGamesData,
        micOn,
        setMicOn,
        camOn,
        setCamOn,
        initialZoom,
        setInitialZoom,
        isPlayerLoading,
        setIsPlayerLoading,
        micValue,
        setMicValue,
        showProfileInfo,
        setShowProfileInfo
      }}
    >
      {isLoading ? (
        <div className="loader">
          <Lottie options={LoaderAnimation} />
        </div>
      ) : (
        <div className="App">
          <Router>
            <Routes>
              {/* Dealer routes starts */}
              <Route
                exact
                path="/dealer"
                element={
                  <PrivateRoute roles={["dealer"]} component={<Game />} />
                }
              />
              <Route
                exact
                path="/dealer/companion/:gameId"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<DealerCompanion />}
                  />
                }
              />

              <Route
                exact
                path="/dealer/game/:gameId"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin", "user"]}
                    component={<NewGameView />}
                  />
                }
              />
              {/* <Route
                exact
                path="/dealer/tournament/:gameId"
                element={<AutomatedPlayerGameView />}
              /> */}

              <Route
                exact
                path="/dealer/game-community-card/:gameId"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<NewGameView />}
                  />
                }
              />

              <Route
                exact
                path="dealer/request-access"
                element={
                  <PrivateRoute
                    roles={["dealer", "admin"]}
                    component={<RequestAccess />}
                  />
                }
              />

              {/* Dealer routes end */}

              {/* User routes starts */}

              <Route
                exact
                path="/user"
                element={
                  <PrivateRoute
                    roles={["user"]}
                    component={<UserDashboard />}
                  />
                }
              />

              <Route
                exact
                path="/user/profile"
                element={
                  <PrivateRoute
                    roles={["user", "dealer"]}
                    component={<CreateProfile />}
                  />
                }
              />
              <Route
                exact
                path="/user/waiting-room/:gameId"
                element={
                  <PrivateRoute
                    roles={["user", "dealer"]}
                    component={<WaitingRoom />}
                  />
                }
              />

              <Route
                exact
                path="/user/main-game/:gameId"
                element={
                  <PrivateRoute roles={["user"]} component={<NewGameView />} />
                }
              />

              {/* <Route
                exact
                path="/user/tournament/:gameId"
                element={<AutomatedPlayerGameView />}
              /> */}
              {/* <Route
                exact
                path="/user/barroom/:gameId"
                element={
                  <PrivateRoute roles={["user"]} component={<BarRoom />} />
                }
              /> */}
              <Route
                exact
                path="/intro-video"
                element={
                  <PrivateRoute roles={["dealer"]} component={<IntroVideo />} />
                }
              />
              {/* User routes end */}

              {/* Public routes start */}
              <Route
                exact
                path="/"
                element={<PrivateRoute roles={["dealer", "user", "admin"]} />}
              />
              <Route exact path="/signup" element={<SignUp />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/verify-email" element={<VerifyEmail />} />
              <Route exact path="/forgot" element={<Forgot />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/went-wrong" element={<WentWrong />} />
              <Route exact path="*" element={<NotFound />} />

              {/* Public routes end */}
            </Routes>
          </Router>
        </div>
      )}
    </UserContext.Provider>
  );
}

export default App;
