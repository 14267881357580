/* eslint-disable no-console */
import "./signup.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { verifyEmail } from "../../utils/Api";
import logo from "../../assets/images/dealer/logo.svg";
import UserContext from "../../context/UserContext";

function VerifyEmail() {
  const [isVerified, setIsVerified] = useState(false);
  const { setTokens, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    const handleMailVerify = async (token) => {
      try {
        const res = await verifyEmail(token);
        if (res.user && res.tokens) {
          localStorage.setItem("token", JSON.stringify(res.tokens));
          setTokens(res.tokens);
          setUser(res.user);
          setIsVerified(true);
        }
      } catch (error) {
        navigate("/login");
        console.log("Your Token Expired", error);
      }
    };
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      handleMailVerify(token);
    }
  }, []);
  const handleLetsPlay = () => {
    const redirect = localStorage.getItem("redirectLink");
    if (redirect) {
      localStorage.removeItem("redirectLink");
      navigate(`${redirect}?modal=profile`);
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* After Email Confirmed */}

          {isVerified ? (
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="" />
              </div>
              <h3>Your email`s confirmed!</h3>
              <span
                className="lets-play-btn"
                onClick={handleLetsPlay}
                role="presentation"
              >
                Let`s Play!
              </span>
            </div>
          ) : (
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="" />
              </div>
              <h3>Checking...</h3>
              <Link className="lets-play-btn" to="/">
                <Spinner animation="border" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
