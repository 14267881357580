import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import PropsTypes from "prop-types";
import UserContext from "../../../context/UserContext";
import PlayersView from "./playerView";
import socket from "../../../config/socket";
import "./style.css";
import GameCreation from "../../gameCreation/gamecreation";
import { getAllGamePlayers } from "../../../utils/Api";
import Layout from "../../layout/layout";
import loadericon from "../../../assets/images/dealer/loader.json";

let tRound = null;
let tPlayer = null;
let game;
let profile;

let staticPlayersLeft = [];
let staticPlayersRight = [];

const LoaderAnimation = {
  loop: true,
  autoplay: true,
  animationData: loadericon,
};

function MultiTablePlayerGameView({ gameId: Id }) {
  const RoundName = {
    0: "players",
    1: "preflopround",
    2: "flopround",
    3: "turnround",
    4: "riverround",
    5: "showdown",
  };
  //  const navigate = useNavigate();

  const [currentPlayer, setCurrentPlayer] = useState();
  const [remainingTime, setRemainingTime] = useState(0);
  const [recentPlayer, setRecentPlayer] = useState();
  const [showGameCreation, setShowGameCreation] = useState(false);
  const [playersLeft, setPlayerLeft] = useState({});
  const [winners, setWinners] = useState([]);
  const [playersRight, setPlayersRight] = useState({});
  const [showCards, setShowCards] = useState(false);
  const [action, setAction] = useState({});
  const [gamePaused, setGamePaused] = useState("");
  const {
    user,
    setGamePlayers,
    setShowProfileModal,
    setMessages,
    setGameEvent,
    allGamesData,
    setAllGamesData,
    gameEvent,
  } = useContext(UserContext);

  const gameId = Id;
  const gameData = allGamesData[gameId];

  const [timer, setTimer] = useState({});
  const timerData = timer[gameId];
  const [bet, setBet] = useState(false);
  const [raise, setRaise] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [oneToOneParticipants] = useState({});
  const [openAction, setOpenAction] = useState({
    bet: false,
    call: false,
    raise: false,
    check: false,
    allin: false,
    fold: false,
  });
  const [timerType, setTimerType] = useState("timer");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  profile = queryParams.get("modal");

  const handlePlaySound = (value) => {
    setIsPlaying(true);
    const ele = document.getElementById(value);
    if (isPlaying) {
      ele.play();
    }
  };
  const handleStopSound = (value) => {
    setIsPlaying(false);
    const ele = document.getElementById(value);
    if (!isPlaying) {
      ele.pause();
    }
  };

  const getAllPlayers = async () => {
    const usersIds = allGamesData[gameId]?.players?.map((el) => el.id);
    const users = await getAllGamePlayers(usersIds);
    setGamePlayers(users);
  };

  useEffect(() => {
    if (user) {
      tPlayer = null;
      tRound = null;
      socket.emit("checkTable", {
        gameId,
        gameData: !!(gameData && Object.keys(gameData)?.length),
        userId: user?.id,
      });
    }
    if (gameData?.players?.length) {
      getAllPlayers();
    }
  }, [gameData?.players?.length]);

  useEffect(() => {
    const tryReconnect = () => {
      setTimeout(() => {
        socket.io.open((err) => {
          if (err) {
            tryReconnect();
          } else {
            tPlayer = null;
            tRound = null;
            socket.emit("checkTable", {
              gameId,
              gameData: !!(gameData && Object.keys(gameData).length),
              userId: user.id,
            });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
    return () => {
      socket.io.off("close");
    }
  }, []);

  const updatePlayers = (pls) => {
    const pl = [...pls];
    let pRight = [];
    let pleft = [];
    const leftLimit = Math.floor(pl.length / 2);
    const rightLimit = pl.length - leftLimit;

    const currPlayer = pl.findIndex((el) => el.id === user?.id);
    if (currPlayer >= 0) {
      let count = 0;
      let startIndex = currPlayer - (rightLimit - 1);
      if (startIndex < 0) startIndex += pl.length;

      while (count < pl.length) {
        count += 1;
        if (pRight?.length === rightLimit) {
          pleft = [pl[startIndex], ...pleft];
        } else if (pRight?.length < rightLimit) {
          pRight = [...pRight, pl[startIndex]];
        }
        if (pleft === leftLimit && pRight === rightLimit) break;
        startIndex = (startIndex + 1) % pl.length;
      }
    } else {
      pRight = pl.slice(0, Math.ceil(pl.length / 2));
      pleft = pl.slice(Math.ceil(pl.length / 2)).reverse();
    }

    setPlayerLeft(pleft);
    setPlayersRight(pRight);

    if (staticPlayersLeft.length !== pleft.length) {
      staticPlayersLeft = pleft;
    }
    if (staticPlayersRight.length !== pRight.length) {
      staticPlayersRight = pRight;
    }
  };

  const handleTentativeAction = (player) => {
    let event;
    const { tentativeAction } = player;
    if (player.tentativeAction.includes(" ")) {
      const [event1] = tentativeAction.split(" ");
      event = event1;
    } else {
      event = tentativeAction;
    }

    switch (event) {
      case "check":
        socket.emit("docheck", { gameId, userId: player.id });
        break;
      case "fold":
        socket.emit("dofold", { gameId, userId: player.id });
        break;
      case "check/fold":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else {
          socket.emit("dofold", { gameId, userId: player.id });
        }
        break;
      case "call":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else if (game?.raiseAmount >= player?.wallet) {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        } else {
          socket.emit("docall", {
            gameId,
            userId: player.id,
            amount: game.raiseAmount,
          });
        }
        break;
      case "callAny":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else if (game?.raiseAmount >= player?.wallet) {
          socket.emit("doallin", {
            gameId,
            userId: player?.id,
            amount: player?.wallet,
          });
        } else {
          socket.emit("docall", {
            gameId,
            userId: player.id,
            amount: game.raiseAmount,
          });
        }
        break;
      case "allin":
        if (game.lastAction === "check" || game.raiseAmount === player.pot) {
          socket.emit("docheck", { gameId, userId: player.id });
        } else {
          socket.emit("doallin", {
            gameId,
            userId: player.id,
            amount: player.wallet,
          });
        }
        break;
      default:
        return "";
    }
  };
  const handleActionButton = (currentPl) => {
    setBet(false);
    setRaise(false);
    const currentAction = { ...openAction };
    const { pot, wallet } = currentPl || {};
    const { raiseAmount, lastAction, runninground: round } = game || {};
    currentAction.fold = true;
    if (round === 1) {
      if (raiseAmount === pot) {
        // check true
        currentAction.check = true;
        currentAction.call = false;
      } else if (wallet + pot > raiseAmount) {
        // call true
        currentAction.call = true;
        currentAction.bet = false;
        currentAction.check = false;
      }
      if (wallet + pot > raiseAmount) {
        // range true
        currentAction.raise = true;
        currentAction.bet = false;
      } else if (wallet + pot <= raiseAmount) {
        // allin true
        currentAction.allin = true;
        currentAction.raise = false;
        currentAction.call = false;
      }
    }

    if (round >= 2) {
      if (lastAction === "check" || raiseAmount === pot) {
        currentAction.check = true;
      }

      if (lastAction === "check") {
        if (raiseAmount >= wallet + pot) {
          currentAction.allin = true;
          currentAction.raise = false;
          currentAction.check = false;
        } else {
          currentAction.call = false;
          currentAction.bet = true;
          currentAction.raise = false;
        }
      } else {
        if (wallet + pot > raiseAmount) {
          currentAction.call = true;
          currentAction.bet = false;
          currentAction.check = false;
        }
        if (raiseAmount >= wallet + pot) {
          currentAction.allin = true;
          currentAction.raise = false;
        }
        if (raiseAmount < wallet + pot) {
          currentAction.allin = false;
          currentAction.bet = false;
          currentAction.raise = true;
        }
      }
      if (wallet + pot <= raiseAmount) {
        currentAction.allin = true;
        currentAction.raise = false;
        currentAction.call = false;
      }

      if (lastAction !== "check" && pot !== raiseAmount) {
        currentAction.check = false;
      }
    }
    setOpenAction(() => currentAction);
  };

  useEffect(() => {
    socket.on("connect", () => {
      // eslint-disable-next-line no-console
      console.log("connected");
      tPlayer = null;
      tRound = null;
      socket.emit("checkTable", {
        gameId,
        gameEventId: gameEvent?.games?.find(
          (eachGameId) => eachGameId === gameId
        ),
        gameData: !!(gameData && Object.keys(gameData).length),
        userId: user.id,
      });
    });
    socket.on("updateGame", (data) => {
      if (data.game.id === gameId) {
        game = data.game;
        let roomData;
        setIsLoading(false);
        if (
          game?.pot === 0
          && game.sidePots.length === 0
          && game.runninground === 5
          && game.winnerPlayer.length
        ) {
          setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
          if (data.gameEvent) {
            setGameEvent(data.gameEvent);
          }
        } else {
          setWinners([]);
          game = data.game;
          roomData = data.game;
          setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
          if (data.gameEvent) {
            setGameEvent(data.gameEvent);
          }
        }
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("");
        if (game?.pause) {
          setGamePaused("gamePaused");
        } else if (
          game?.runninground === 1
          && game?.preflopround?.find((pl) => pl.cards.length !== 2 && pl.playing)
        ) {
          setGamePaused("usercardScanning");
        } else if (game.runninground === 2 && game.communityCard.length < 3) {
          setGamePaused("communityCardScanning");
        } else if (game.runninground === 3 && game.communityCard.length < 4) {
          setGamePaused("communityCardScanning");
        } else if (game.runninground === 4 && game.communityCard.length < 5) {
          setGamePaused("communityCardScanning");
        } else {
          setGamePaused("");
        }

        if (roomData?.runninground === 0) {
          updatePlayers(roomData.players);
          if (!data.gameEvent) return;
        //  const conditionCheck = (field, obj = roomData) =>
        //    obj[field]?.find(
        //      (el) => el?.id?.toString() === user?.id?.toString()
        //    );
        //  if (
        //    user.role === "user"
        //    && !(
        //      data.gameEvent?.games?.find(
        //        (g) =>
        //          conditionCheck("players", g) || conditionCheck("viewers", g)
        //      )
        //      || conditionCheck("players")
        //      || conditionCheck("barroomPlayers")
        //      || conditionCheck("viewers")
        //    )
        //  ) {
        //    localStorage.removeItem("videoKey");
        //    return navigate(`/user/waiting-room/${gameId}`);
        //  }
        } else if (roomData.runninground === 1) {
          updatePlayers(roomData.preflopround);
        } else if (roomData.runninground === 2) {
          updatePlayers(roomData.flopround);
        } else if (roomData.runninground === 3) {
          updatePlayers(roomData.turnround);
        } else if (roomData.runninground === 4) {
          updatePlayers(roomData.riverround);
        } else if (roomData.runninground === 5) {
          updatePlayers(roomData.showdown);
        }
        if (data?.profileUpdate) {
          getAllPlayers();
          setShowProfileModal(false);
        }
      }
    });

    socket.on("actionperformed", (data) => {
      if (data?.gameId === gameId) {
        setRecentPlayer(data);
        if (data.action === "fold") {
          setAction(data);
          setTimeout(() => setAction({}), 3000);
        }
      }
    });

    socket.on("newhand", (data) => {
      if (data.game.id === gameId) {
        game.gamestart = false;
        game.pot = 0;
        game.sidePots = [];
        tPlayer = null;
        tRound = null;

        const p = [];
        game.showdown.forEach((pl) => {
          const newP = data.game.players.find((newPl) => newPl.id === pl.id);
          if (newP) {
            p.push({ ...newP, cards: pl.cards });
          }
        });
        updatePlayers(p);
        const f = {
          ...game,
          players: p,
          viewers: data.game.viewers,
          viewersRank: data.game.viewersRank,
        };
        setAllGamesData((prev) => ({
          ...prev,
          [data.game.id]: { ...f },
        }));
        setGameEvent(data.gameEvent);
      }
    });

    socket.on("scanCardForUser", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        updatePlayers(game?.preflopround);
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (data?.gameEvent) {
          setGameEvent(data.gameEvent);
        }
        setCurrentPlayer({});
        setShowCards(false);
        setWinners([]);
        setGamePaused("usercardScanning");
      }
    });

    // card scans on seach round socket
    socket.on("scanCommunityCard", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        const obj = {};
        obj[data.game.id] = 0;
        setTimer({ ...timer, ...obj });
        setCurrentPlayer({});
        if (game.runninground === 0) {
          updatePlayers(game.players);
        } else if (game.runninground === 1) {
          updatePlayers(game.preflopround);
        } else if (game.runninground === 2) {
          setTimeout(() => {
            updatePlayers(game.flopround);
          }, 1000);
        } else if (game.runninground === 3) {
          setTimeout(() => {
            updatePlayers(game.turnround);
          }, 1000);
        } else if (game.runninground === 4) {
          setTimeout(() => {
            updatePlayers(game.riverround);
          }, 1000);
        } else if (game.runninground === 5) {
          updatePlayers(game.showdown);
        }
        setGamePaused("communityCardScanning");
      }
    });

    socket.on("winner", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        if (game?.winnerPlayer[0]?.handName) {
          setShowCards(true);
        }
        updatePlayers(game?.showdown);
        setCurrentPlayer({});
        setWinners(game?.winnerPlayer);
      }
    });

    socket.on("roomResume", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("");
      }
    });

    socket.on("roomPaused", (data) => {
      if (data.game.id === gameId && game && game.id === data.game.id) {
        game = data.game;
        setAllGamesData((prev) => ({ ...prev, [data.game.id]: data.game }));
        setGamePaused("gamePaused");
      }
    });
  }, []);

  useEffect(() => {
    socket.on("timer", (data) => {
      if (data.gameId.toString() === gameId.toString()) {
        setRemainingTime(data.playerchance);
        if (data.timerType === "timebank") {
          setTimerType("timebank");
          setTimer(data.maxtimer);
        }
        if (data.playerchance === 5) {
          handlePlaySound("timer-out");
        }
        game.timer = data.maxtimer;
        if (tPlayer !== data.id || tRound !== data.runninground) {
          if (timer === 0) {
            setTimer(game && game.timer);
          }

          const activePlayer = game[RoundName[data.runninground]]?.find(
            (ele) => ele.id === data.id
          );
          if (activePlayer?.id === user?.id) {
            if (profile) {
              setMessages((old) => [
                ...old,
                {
                  ...data,
                  msg: `${user?.name} It's Your Turn`,
                  id: user?.id,
                  type: "playerTurn",
                },
              ]);
            }
          }
          if (activePlayer?.tentativeAction && activePlayer?.id === user?.id) {
            if (
              activePlayer?.tentativeAction === "check"
              && game?.lastAction !== "Allin"
            ) {
              if (
                game?.lastAction === "check"
                || game?.raiseAmount === activePlayer?.pot
              ) {
                handleTentativeAction(activePlayer);
                setCurrentPlayer(activePlayer);
              } else {
                setTimerType("timer");
                setCurrentPlayer(activePlayer);
                handleActionButton(activePlayer);
              }
            } else {
              handleTentativeAction(activePlayer);
              setCurrentPlayer(activePlayer);
            }
          } else {
            setTimerType("timer");
            setCurrentPlayer(activePlayer);
            handleActionButton(activePlayer);
          }
          if (data.playerchance === 5 && activePlayer?.id === user.id) {
            handlePlaySound("timer-out");
          }
          setRecentPlayer((old) => old);
        }
        tPlayer = data.id;
        tRound = data.runninground;
      }
    });
  }, [gameId]);

  const handleOneToOneRequest = (requestedTo, name) => {
    socket.emit("oneToOneRequest", {
      requestedBy: user.id,
      requestedTo,
      requestedToName: name,
      requestedByName: user.name,
      gameId,
    });
    setMessages((old) => [
      ...old,
      {
        msg: "Request has been send",
        id: user.id + requestedTo,
        type: "success",
      },
    ]);
  };

  return (
    <Layout>
      <>
        <Helmet>
          <body className="game-view" />
        </Helmet>
        {isLoading ? (
          <div className="loader">
            <Lottie options={LoaderAnimation} />
          </div>
        ) : (
          <div className="player-game-view tournament-game-view">
            <div className="container">
              <div className="player-game-view-wrapper">
                <PlayersView
                  playersLeft={playersLeft}
                  playersRight={playersRight}
                  staticPlayersLeft={staticPlayersLeft}
                  staticPlayersRight={staticPlayersRight}
                  action={action}
                  currentPlayer={currentPlayer}
                  timer={timerData}
                  remainingTime={remainingTime}
                  showCards={showCards}
                  recentPlayer={recentPlayer}
                  winners={winners}
                  handleOneToOneRequest={handleOneToOneRequest}
                  oneToOneParticipants={oneToOneParticipants}
                  openAction={openAction}
                  setOpenAction={setOpenAction}
                  bet={bet}
                  setBet={setBet}
                  raise={raise}
                  setRaise={setRaise}
                  setTimer={setTimer}
                  gamePaused={gamePaused}
                  gameData={allGamesData[gameId]}
                  handleStopSound={handleStopSound}
                  timerType={timerType}
                />
              </div>
            </div>
            <Modal
              show={showGameCreation}
              onHide={() => setShowGameCreation(false)}
              className="game-creation-modal"
              centered
            >
              <Modal.Body>
                <GameCreation handleClose={() => setShowGameCreation(false)} />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </>
    </Layout>
  );
}

MultiTablePlayerGameView.defaultProps = {
  gameId: undefined,
};

MultiTablePlayerGameView.propTypes = {
  gameId: PropsTypes.string,
};
export default MultiTablePlayerGameView;
