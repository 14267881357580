import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import "./waitingRoomModal.css";

function JoinErrorModal({ joinError, handleJoinError }) {
  return (
    <div className="joinError">
      <Modal
        show={joinError}
        onHide={handleJoinError}
        centered
        className="bar-room-leave-popup joinErrorModal"
      >
        <div className="bar-room-leave">
          <h4>Join Error</h4>
          <p>Failed to join. The waiting room is full.</p>
          <div className="">
            <Button className="joinErrorModal-btn" onClick={handleJoinError}>
              <div>Leave</div>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

JoinErrorModal.propTypes = {
  joinError: PropsTypes.bool.isRequired,
  handleJoinError: PropsTypes.func.isRequired,
};
export default JoinErrorModal;
