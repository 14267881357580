import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import PropsTypes from "prop-types";
import { useParams } from "react-router-dom";
// import currentbid from "../../../assets/images/playerview/current-bid.svg";
import timer from "../../../assets/images/playerview/timer.svg";
import down from "../../../assets/images/playerview/down.svg";
import socket from "../../../config/socket";

function PlayerBidTimerAmount({ gameData }) {
  const [blindtimer, setBlindTimer] = useState(false);
  const [blindHistory, setBlindHistory] = useState([]);
  const [resetBlindTimer, setResetblindTimer] = useState();
  const [alertBlindTimer, setAlertBlindTimer] = useState();
  const { gameId } = useParams();

  useEffect(() => {
    socket.on("blindTimer", (data) => {
      if (data?.gameId === gameId) {
        setResetblindTimer(data.time);
        if (data.time < "00:10") {
          setAlertBlindTimer(true);
        } else {
          setAlertBlindTimer(false);
        }
        setBlindHistory(data.blindHistory);
      }
    });
  }, []);

  const handleBlindTimer = () => {
    setBlindTimer(!blindtimer);
  };

  return (
    <div className="player-bid-amount">
      <div className="player-bid-grid">
        <div className="player-bid-box">
          <div className="player-bid current-blind">
            <div className="player-bid-info">
              <span>Current blinds</span>
              <h4 title={`$${gameData?.smallBlind}/ $${gameData?.bigBlind}`}>
                {`$${gameData?.smallBlind}/ $${gameData?.bigBlind}`}
              </h4>
            </div>
            <div
              className="player-bid-icon"
              onClick={handleBlindTimer}
              role="presentation"
            >
              {resetBlindTimer ? (
                <h4 className={`${alertBlindTimer ? "alert-timer" : ""}`}>
                  {resetBlindTimer}
                </h4>
              ) : (
                <img src={timer} alt="" />
              )}
              <span>
                <img src={down} alt="" />
              </span>
            </div>
            <div
              className={`player-bidtimer-drop-wrapper ${
                blindtimer ? "show" : ""
              }`}
            >
              <div
                className={`player-blind-timer-dropdown ${
                  blindtimer ? "show" : ""
                }`}
              >
                <h4>
                  Blinds:
                  {" "}
                  <span>{blindHistory.length + 1}</span>
                </h4>
                <div className="player-blind-timer-table">
                  <Table>
                    <tbody>
                      {blindHistory.map((blind, i) => (
                        <tr key={`blind-${i + 1}`}>
                          <td>
                            №
                            {i + 1}
                          </td>
                          <td>
                            Time:
                            {" "}
                            <span>{blind.time}</span>
                          </td>
                          <td>
                            Blind:
                            {" "}
                            <span className="blin-td">{blind.blinds}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <div className="player-bid player-pot-count">
            {gameData?.sidePots?.length === 0 ? (
              <>
                <div className="player-bid-info">
                  <span>Pot count</span>
                  <h4 title={`$${gameData?.pot}`}>{`$${gameData?.pot}`}</h4>
                </div>
                {/* <div className="player-bid-icon">
                  <img src={currentbid} alt="" />
                </div> */}
              </>
            ) : (
              <ul
                className={`${
                  gameData?.sidePots?.filter((el) => el.pot)?.length > 3
                    ? "more-pots"
                    : ""
                }`}
              >
                {gameData?.sidePots
                  ?.filter((el) => el.pot)
                  ?.map((sidePot, i) => (
                    <li key={`pot-${i + 1}`}>
                      <span>{i === 0 ? "Main pot" : `Side pot №${i}`}</span>
                      <h4>
                        $
                        {sidePot?.pot}
                      </h4>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PlayerBidTimerAmount.defaultProps = {
  gameData: {},
};

PlayerBidTimerAmount.propTypes = {
  gameData: PropsTypes.object,
};
export default PlayerBidTimerAmount;
