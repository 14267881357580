/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";
import PropsTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropzone from "react-dropzone";
import arrow from "../../../assets/images/inrovideo/arrow.svg";
import ChatBtnIcon from "../../../IconComponents/ChatBtnIcon";
import SendIcon from "../../../IconComponents/SendIcon";
import ChatEmoji from "../../chatEmoji/chatEmoji";
import fileicon from "../../../assets/images/inrovideo/file.svg";

function UploadImageModal({
  showUploadModal,
  setShowUploadModal,
  handleSendMessageWithImage,
  preview,
  clearphoto,
  handleImageChange,
  handleCaption,
  sendImgLoader,
}) {
  //  const [showCamOption, setShowCamOption] = useState(false);
  const [caption, setCaption] = useState("");
  const [showEmoji, setShowEmoji] = useState("");
  const handleUploadClose = () => {
    setShowUploadModal(false);
  };

  const handleChangeCaption = (e, name) => {
    if (name === "emoji") {
      setCaption((old) => old + e);
    } else {
      setCaption(e.target.value);
    }
  };

  useEffect(() => {
    handleCaption(caption);
  }, [caption]);

  useEffect(() => {
    setCaption("");
  }, [showUploadModal]);

  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End

  return (
    <Modal
      show={showUploadModal}
      onHide={handleUploadClose}
      centered
      className="poker-popup player-history-modal cam-input-modal upload-chat-image-modal"
      style={{
        transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {preview ? (
            <span
              className="preview-btn"
              onClick={clearphoto}
              role="presentation"
            >
              <img src={arrow} alt="" />
              Preview
            </span>
          ) : (
            "Upload photo"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="selfie-cam">
          {preview ? (
            <img src={preview} alt="user" />
          ) : (
            <div className="add-company-logo">
              <h6>You can upload an image in JPG, GIF or PNG format</h6>

              <FileDropZone onDrop={handleImageChange} />
            </div>
          )}
        </div>
        {!preview ? (
          ""
        ) : (
          <div className="cam-shutter-btn-wrapper">
            <div className="tab-card-bottom">
              <Form
                className="Chat-input-wrapper"
                onSubmit={(e) => handleSendMessageWithImage(e)}
              >
                <Form.Control
                  type="text"
                  placeholder="Add a caption..."
                  onChange={(e) => handleChangeCaption(e, "chat")}
                  value={caption}
                />
                <div className="cam-emoji-wrapper">
                  <span
                    className="emoji-input-btn"
                    onClick={() => setShowEmoji("caption")}
                    role="presentation"
                  >
                    <ChatBtnIcon />
                  </span>
                </div>
                <div
                  className={`chat-input-emoji ${
                    showEmoji === "caption" ? "show" : ""
                  }`}
                >
                  <ChatEmoji
                    name="chat"
                    // selectEmoji={handleChangeCaption}
                    setShowEmoji={setShowEmoji}
                    handleReaction={handleChangeCaption}
                  />
                </div>

                <Button
                  className="bg-btn"
                  type="submit"
                  disabled={sendImgLoader}
                >
                  {sendImgLoader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <SendIcon />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

UploadImageModal.propTypes = {
  showUploadModal: PropsTypes.bool.isRequired,
  setShowUploadModal: PropsTypes.func.isRequired,
  //  takepicture: PropsTypes.func.isRequired,
  //  file: PropsTypes.object.isRequired,
  handleSendMessageWithImage: PropsTypes.func.isRequired,
  preview: PropsTypes.string.isRequired,
  //  allCameraOptions: PropsTypes.array.isRequired,
  clearphoto: PropsTypes.func.isRequired,
  sendImgLoader: PropsTypes.bool.isRequired,
  handleImageChange: PropsTypes.func.isRequired,
  handleCaption: PropsTypes.func.isRequired,
};

export default UploadImageModal;

function FileDropZone({ onDrop }) {
  return (
    <Dropzone
      onDrop={onDrop}
      maxFiles={1}
      accept={{
        "image/*": [".jpg", ".png", ".jpeg", ".webp"],
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="intro-drag-video">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              <span>
                <img src={fileicon} alt="" />
                {" "}
                Add file
                {" "}
              </span>
              or drag&drop here
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

FileDropZone.propTypes = {
  onDrop: PropsTypes.func.isRequired,
};
