/* eslint-disable jsx-a11y/media-has-caption */
import {
  useAudioTrack,
  useDaily,
  useDevices,
  useVideoTrack,
  useActiveSpeakerId,
} from "@daily-co/daily-react";
import { useEffect, useRef, useContext, useState } from "react";
import { Nav, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

import PropsTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import cross from "../../../assets/images/profile/cross.svg";
import ProfileModal from "../../profile/profileModal";
import noplayerImg from "../../../assets/images/profile/user.svg";
import soundMute from "../../../assets/images/waitingroom/sound.svg";
import BarRoomSetting from "../../barRoom/settings";

function WaitingRoomParticipant({ user }) {
  const callObject = useDaily();
  const videoRef = useRef();
  const audioRef = useRef();
  const { hasCamError } = useDevices();
  const {
    user: authUser,
    setShowProfileModal,
    showProfileInfo,
    setShowProfileInfo,
  } = useContext(UserContext);
  const handleClose = () => setShowProfileInfo("");
  const handleShow = (e) => {
    setShowProfileInfo(e);
  };
  const [showSetting, setShowSetting] = useState(false);
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);

  const remoteparticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteparticipant = remoteparticipants.find(
    (ele) => ele.user_name === user?.id
  );
  const activeSpeakerId = useActiveSpeakerId();

  const videoTrack = useVideoTrack(remoteparticipant?.session_id);
  const audioTrack = useAudioTrack(remoteparticipant?.session_id);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  //  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  //  Screen Scaling Function End

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoRef?.current) {
      videoRef.current.srcObject = new MediaStream([
        videoTrack?.persistentTrack,
      ]);
    }
  }, [videoTrack.persistentTrack, videoTrack.isOff]);

  useEffect(() => {
    if (!audioTrack.persistentTrack) return;
    if (audioRef?.current) {
      audioRef.current.srcObject = new MediaStream([
        audioTrack?.persistentTrack,
      ]);
    }
  }, [audioTrack]);
  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, [queryParams]);
  if (!user) {
    return (
      <div className="wr-camera-box">
        <img className="noplayerImg" src={noplayerImg} alt="" />
      </div>
    );
  }

  return (
    <div className="wr-camera-box camera-active">
      {remoteparticipant && !videoTrack.isOff && !hasCamError ? (
        <video
          style={{ width: "100%" }}
          ref={videoRef}
          autoPlay
          controls={false}
        />
      ) : (
        <img
          className="cameraoff"
          src={user?.avatar}
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.onerror = null; // prevents looping
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
          }}
          alt=""
        />
      )}
      <audio ref={audioRef} autoPlay muted={remoteparticipant?.local} />
      <div
        className={`player-dropdown ${
          activeSpeakerId === remoteparticipant?.session_id
            ? "player-btn-dropdown"
            : ""
        } `}
      >
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {/* Add this class below class to animate the dot icons "dot-animate" */}
            <div
              className={`dot-flashing ${
                activeSpeakerId === remoteparticipant?.session_id
                  ? "dot-animate"
                  : ""
              }`}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {user.id !== authUser.id ? (
              <Nav>
                <span
                  className="dropdown-item"
                  onClick={() => handleShow(user?.id)}
                  role="presentation"
                >
                  View profile
                </span>
              </Nav>
            ) : (
              ""
            )}
            {user.id === authUser.id ? (
              <>
                <Nav>
                  <span
                    className="dropdown-item"
                    onClick={() => navigate("?modal=profile")}
                    role="presentation"
                  >
                    Edit Profile
                  </span>
                </Nav>
                <span
                  className="dropdown-item"
                  onClick={handleShowSetting}
                  role="presentation"
                >
                  Manage Audio/Video
                </span>
              </>
            ) : (
              ""
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="wr-camera-name">
        <p>{user?.name}</p>
        {audioTrack.isOff ? <img src={soundMute} alt="mute" /> : ""}
      </div>

      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        centered
        className="bar-room-setting-popup"
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>

      {showProfileInfo === user?.id && (
        <div className="profile-viewer">
          <span
            className="closeprofile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="" />
          </span>
          <ProfileModal user={user} />
        </div>
      )}
    </div>
  );
}

WaitingRoomParticipant.defaultProps = {
  user: null,
};

WaitingRoomParticipant.propTypes = {
  user: PropsTypes.object,
};

export default WaitingRoomParticipant;
