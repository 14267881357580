import React from "react"
import PropsTypes from "prop-types";
import Banner from "../../../assets/images/Banner.png"

function GameBanner({ gameData, isExpanded }) {
  const myImageStyle = { width: "1230px", height: "142px", borderRadius: "10px" };
  return (
    <div className={`game-view-banner-main ${isExpanded ? "" : "show"}`}>
      <img style={myImageStyle} src={gameData?.gameBanner ? gameData?.gameBanner : Banner} alt="Game Banner" />
    </div>
  )
}

GameBanner.defaultProps = {
  gameData: {},
  isExpanded: false
}

GameBanner.propTypes = {
  gameData: PropsTypes.object,
  isExpanded: PropsTypes.bool
}

export default GameBanner
