import React from "react";
import { Link } from "react-router-dom";
// import Page403SCG from "./Page403SCG";

function AccessDenied() {
  return (
    <div className="page-404">
      <div className="container">
        <div className="page-404-content">
          <DeniedIcon />
          <div className="page-404-wrapper">
            <h6>Oops! The page you were looking for is forbidden.</h6>
            <p>You do not have the access to view this page.</p>
            <Link className="bg-btn" to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessDenied;

function DeniedIcon() {
  return (
    <svg
      width="246"
      height="246"
      viewBox="0 0 246 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.0146 173.811V191.639H63.5884V216.709H41.7012V191.639H7V106.716H27.6934V173.811H42.1787V106.716H63.5884V173.811H74.0146Z"
        fill="#373940"
      />
      <path
        d="M139.199 106C142.913 106 145.911 107.167 148.192 109.502C150.527 111.784 151.694 114.808 151.694 118.575V204.612C151.694 208.379 150.527 211.43 148.192 213.765C145.911 216.046 142.913 217.187 139.199 217.187H101.712C98.0508 217.187 95.0264 216.02 92.6387 213.685C90.304 211.35 89.1367 208.326 89.1367 204.612V118.575C89.1367 114.861 90.304 111.837 92.6387 109.502C95.0264 107.167 98.0508 106 101.712 106H139.199ZM113.093 198.961H127.738C128.321 198.961 128.799 198.802 129.17 198.483C129.542 198.112 129.728 197.661 129.728 197.13V126.216C129.728 125.049 129.064 124.465 127.738 124.465H113.093C111.767 124.465 111.104 125.049 111.104 126.216V197.13C111.104 197.661 111.289 198.112 111.661 198.483C112.032 198.802 112.51 198.961 113.093 198.961Z"
        fill="#373940"
      />
      <path
        d="M238.288 173.811V191.639H227.862V216.709H205.975V191.639H171.273V106.716H191.967V173.811H206.452V106.716H227.862V173.811H238.288Z"
        fill="#373940"
      />
      <path
        d="M7 93.7165V28.4252H37.7794V39.5276H20.0093V55.3071H34.9984V66.2205H20.0093V82.5669H37.7794V93.7165H7Z"
        fill="#373940"
      />
      <path
        d="M50.4588 28.4252H79.3056C81.5681 28.4252 83.4063 29.1496 84.8204 30.5984C86.2345 32.0157 86.9415 33.8583 86.9415 36.126V64.6142C86.9415 66.5984 86.4073 68.2677 85.3389 69.622C84.2705 70.9449 82.8407 71.7795 81.0496 72.126L87.13 93.7165H74.3092L68.4173 72.315H63.4681V93.7165H50.4588V28.4252ZM74.1207 60.9291V40.5197C74.1207 40.2992 74.0264 40.0787 73.8379 39.8583C73.6808 39.6378 73.5079 39.5276 73.3194 39.5276H63.4681V61.7795H73.3194C73.5079 61.7795 73.6808 61.685 73.8379 61.4961C74.0264 61.3071 74.1207 61.1181 74.1207 60.9291Z"
        fill="#373940"
      />
      <path
        d="M100.894 28.4252H129.74C132.003 28.4252 133.841 29.1496 135.255 30.5984C136.669 32.0157 137.376 33.8583 137.376 36.126V64.6142C137.376 66.5984 136.842 68.2677 135.774 69.622C134.705 70.9449 133.276 71.7795 131.484 72.126L137.565 93.7165H124.744L118.852 72.315H113.903V93.7165H100.894V28.4252ZM124.555 60.9291V40.5197C124.555 40.2992 124.461 40.0787 124.273 39.8583C124.116 39.6378 123.943 39.5276 123.754 39.5276H113.903V61.7795H123.754C123.943 61.7795 124.116 61.685 124.273 61.4961C124.461 61.3071 124.555 61.1181 124.555 60.9291Z"
        fill="#373940"
      />
      <path
        d="M157.786 28H180.552C182.626 28 184.323 28.6614 185.643 29.9843C186.963 31.3071 187.623 33.0079 187.623 35.0866V86.9606C187.623 89.0394 186.963 90.7402 185.643 92.063C184.323 93.3543 182.626 94 180.552 94H157.786C155.68 94 153.984 93.3543 152.695 92.063C151.407 90.7402 150.763 89.0394 150.763 86.9606V35.0866C150.763 33.0079 151.407 31.3071 152.695 29.9843C153.984 28.6614 155.68 28 157.786 28ZM174.566 82V40C174.566 39.748 174.472 39.5118 174.283 39.2913C174.126 39.0709 173.969 38.9606 173.812 38.9606H164.432C163.992 38.9606 163.772 39.3071 163.772 40V82C163.772 82.189 163.835 82.3937 163.961 82.6142C164.086 82.8031 164.243 82.8976 164.432 82.8976H173.812C173.969 82.8976 174.126 82.8031 174.283 82.6142C174.472 82.4252 174.566 82.2205 174.566 82Z"
        fill="#373940"
      />
      <path
        d="M202.329 28.4252H231.176C233.438 28.4252 235.276 29.1496 236.69 30.5984C238.104 32.0157 238.811 33.8583 238.811 36.126V64.6142C238.811 66.5984 238.277 68.2677 237.209 69.622C236.14 70.9449 234.711 71.7795 232.92 72.126L239 93.7165H226.179L220.287 72.315H215.338V93.7165H202.329V28.4252ZM225.991 60.9291V40.5197C225.991 40.2992 225.896 40.0787 225.708 39.8583C225.551 39.6378 225.378 39.5276 225.189 39.5276H215.338V61.7795H225.189C225.378 61.7795 225.551 61.685 225.708 61.4961C225.896 61.3071 225.991 61.1181 225.991 60.9291Z"
        fill="#373940"
      />
    </svg>
  );
}
