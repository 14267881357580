import { useState, useEffect } from "react";
import "./signup.css";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/images/dealer/logo.svg";
import eyehide from "../../assets/images/signup/eye-hide.svg";
import eyeopen from "../../assets/images/signup/eye-open.svg";
import { RegisterSchema } from "../../validationSchema/authSchema";
import { getGameName, signup } from "../../utils/Api";

function SignUp() {
  const [isRegister, setIsRegister] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswordEye, setShowPasswordEye] = useState([]);
  const [gameName, setGameName] = useState("");
  const location = useLocation();
  const url = location.search;
  const regex = /\/([^/]+)$/;
  const match = url.match(regex);
  const id = match ? match[1] : null;
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError,
  } = useForm({ resolver: yupResolver(RegisterSchema) });

  useEffect(() => {
    if (id) {
      const getName = async (gameId) => {
        const res = await getGameName(gameId);
        if (res) {
          setGameName(res.gameName)
        }
      };
      getName(id)
    }
  }, [id]);

  const handleSignup = async (values) => {
    setIsLoading(true);
    const res = await signup(values);
    setIsLoading(false);
    if (res.message === "Email Already Taken") {
      setError(
        "email",
        { type: "custom", message: "Email already taken" },
        { shouldFocus: true }
      );
    } else if (res.msg === "Email send for verification") {
      setIsRegister(true);
    }
  };

  const showPassword = (name) => {
    if (!showPasswordEye.includes(name)) {
      setShowPasswordEye((old) => [...old, name]);
    } else {
      setShowPasswordEye((old) => old.filter((el) => el !== name));
    }
  };

  const handleChange = (e) => {
    setValue("phoneNumber", e);
    setError("phoneNumber", "customError");
  };

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="signup">
      <div className="logo-icon">
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {isRegister ? (
            <div className="signup-form">
              <div className="signup-form-icon" role="presentation" onClick={handleClick}>
                <img src={logo} alt="" />
              </div>
              <h4>You are registered, please check your email to verify! </h4>
            </div>
          ) : (
            <div className="signup-form">
              <div className="signup-form-icon">
                <img src={logo} alt="" />
              </div>
              <div className="game-cp-wrapper">
                <h4>{gameName || ""}</h4>
              </div>
              <Form onSubmit={handleSubmit(handleSignup)}>
                <div className="half-width">
                  <Form.Group className="form-group">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="Enter First name"
                      className={errors.firstName ? "error-field" : ""}
                      {...register("firstName")}
                    />
                    {errors?.firstName ? (
                      <p className="error-text">{errors.firstName.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter last name"
                      name="lastName"
                      className={errors.lastName ? "error-field" : ""}
                      {...register("lastName")}
                    />
                    {errors?.lastName ? (
                      <p className="error-text">{errors.lastName.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="full-width">
                  <Form.Group className="form-group">
                    <Form.Label>Phone number</Form.Label>
                    <PhoneInput
                      country="us"
                      onChange={handleChange}
                      inputProps={{
                        name: "phoneNumber",
                      }}
                      className={errors.phoneNumber ? "error-field" : ""}
                    />

                    {errors?.phoneNumber ? (
                      <p className="error-text">{errors.phoneNumber.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="full-width">
                  <Form.Group className="form-group">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      className={errors.email ? "error-field" : ""}
                      {...register("email")}
                    />
                    {errors?.email ? (
                      <p className="error-text">{errors.email.message}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="half-width">
                  <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={
                        showPasswordEye.includes("password")
                          ? "text"
                          : "password"
                      }
                      placeholder="Enter password"
                      className={errors.password ? "error-field" : ""}
                      name="password"
                      {...register("password")}
                    />
                    {errors?.password ? (
                      <p className="error-text">{errors.password.message}</p>
                    ) : (
                      ""
                    )}
                    <span onClick={() => showPassword("password")} role="presentation">
                      <img
                        src={
                          showPasswordEye.includes("password")
                            ? eyeopen
                            : eyehide
                        }
                        alt=""
                      />
                    </span>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Verify password</Form.Label>
                    <Form.Control
                      type={
                        showPasswordEye.includes("verifyPassword")
                          ? "text"
                          : "password"
                      }
                      placeholder="Verify password"
                      name="verifyPassword"
                      className={errors.verifyPassword ? "error-field" : ""}
                      {...register("verifyPassword")}
                    />
                    <span onClick={() => showPassword("verifyPassword")} role="presentation">
                      <img
                        src={
                          showPasswordEye.includes("verifyPassword")
                            ? eyeopen
                            : eyehide
                        }
                        alt=""
                      />
                    </span>
                    {errors?.verifyPassword ? (
                      <p className="error-text">
                        {errors.verifyPassword.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>

                <div className="signup-btn">
                  <Button type="submit">
                    {isLoading ? <Spinner animation="border" /> : "Sign up"}
                  </Button>
                </div>
              </Form>
              <p>
                Already have an Account?
                {" "}
                <Link to="/login">Log in</Link>
              </p>
            </div>
          )}

          {/* After Email Confirmed */}

          {/* <div className="signup-form">
                    <div className="signup-form-icon">
                        <img src={logo} alt="" />
                    </div>
                    <h3>Your email's confirmed!</h3>
                    <Link className="lets-play-btn" to="">Let's Play!</Link>
                </div> */}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
