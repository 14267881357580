import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/dealer/logo.svg";
import { ResetPasswordSchema } from "../../validationSchema/authSchema";
import Success from "../toaster/success";
import Error from "../toaster/erros";
import { resetPassword } from "../../utils/Api";

function ResetPassword() {
  const [result, setResult] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

  const handleResetPassword = async (values) => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    try {
      const res = await resetPassword(values, urlParams.get("token"));
      setIsLoading(false);
      if (res.code === 200) {
        setResult("success");
        setMessage("Password update successfully.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setResult("fail");
        setMessage(res.message);
      }
    } catch (error) {
      setResult("fail");
      setMessage(error);
    }
  };

  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="signup">
      <div className="logo-icon" role="presentation" onClick={handleClick}>
        <img src={logo} alt="" />
      </div>
      <div className="container">
        <div className="signup-content">
          {/* Signup Form */}

          <div className="signup-form">
            <div className="signup-form-icon">
              <img src={logo} alt="" />
            </div>
            {result === "success" ? (
              <Success message={message} />
            ) : result === "fail" ? (
              <Error message={message} />
            ) : (
              ""
            )}

            <Form onSubmit={handleSubmit(handleResetPassword)}>
              <div className="full-width">
                <Form.Group className="form-group">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    name="password"
                    className={errors.password ? "error-field" : ""}
                    {...register("password")}
                  />
                  {errors?.password ? (
                    <p className="error-text">{errors.password.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    className={errors.confirmPassword ? "error-field" : ""}
                    {...register("confirmPassword")}
                  />
                  {errors?.confirmPassword ? (
                    <p className="error-text">
                      {errors.confirmPassword.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>

              <div className="signup-btn">
                <Button type="submit">
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
