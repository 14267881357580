import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import UserContext from "../../context/UserContext";
import "./sidebar.css";
import PlayerHistoryModal from "./dealercompanion/modals/playerHistoryModal";

function HistorySidebar() {
  const { historySidebar, setHistorySidebar, gameData } = useContext(UserContext);
  const { handWinner } = gameData;
  const [historyModal, setHistoryModal] = useState(false);
  const [modalName, setModalName] = useState("Player")
  const handleHistoryShow = (data) => {
    setModalName(data)
    setHistoryModal(true)
  }
  const handleHistoryClose = () => {
    setHistoryModal(false)
  }
  return (
    <div className={`history-sidebar ${historySidebar ? "show" : "hide"}`}>
      <div className="sidebar-head">
        <h4>Players History</h4>
        <span onClick={() => setHistorySidebar(false)} role="presentation">
          <CloseIcon />
        </span>
      </div>
      <div className="sidebar-content">
        {handWinner?.map((el) => (
          <div
            className="player-history-details"
            key={el?.id}
            onClick={() => handleHistoryShow(el)}
            role="presentation"
          >
            <div className="history-profile-section">
              <img
                src={el?.avatar}
                onError={({ currentTarget }) => {
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.onerror = null; // prevents looping
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                }}
                alt=""
              />
              <p>{el?.name}</p>
            </div>
            <div className="history-stats-section">
              <span className="game-stats">{el?.handPlayed}</span>
              <span className="game-stats win">{el?.win}</span>
              <span className="game-stats lose">{el?.lose}</span>
            </div>
          </div>
        ))}
      </div>

      <Modal
        show={historyModal}
        onHide={handleHistoryClose}
        centered
        className="poker-popup player-history-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${modalName?.name}'s History`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlayerHistoryModal playerHistory={modalName} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HistorySidebar;

function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1436 3.78737C16.5342 4.17789 16.5342 4.81106 16.1436 5.20158L9.34533 11.9999L16.1436 18.7982C16.5342 19.1887 16.5342 19.8219 16.1436 20.2124C15.7531 20.6029 15.1199 20.6029 14.7294 20.2124L6.5169 11.9999L14.7294 3.78737C15.1199 3.39684 15.7531 3.39684 16.1436 3.78737Z"
        fill="#C7AD7A"
      />
    </svg>
  );
}
