import React from "react"
import Layout from "../layout/layout"
import logo from "../../assets/images/dealer/logo.svg";
import "./userdashboard.css"

function UserDashboard() {
  return (
    <Layout>
      <div className="signup-content">
        <div className="signup-form">
          <div className="signup-form-icon" role="presentation">
            <img src={logo} alt="" />
          </div>
          <h4>
            Profile is saved! What`s next? Once it`s time to play,
            please click your game link URL (found in your original invite) to start playing.

          </h4>
        </div>
      </div>
    </Layout>
  )
}

export default UserDashboard
