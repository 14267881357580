import { useContext, useEffect } from "react";
import DealerLayout from "../layout";
import PokerTable from "./pokertable";
import "./dealer.css";
import { getAllUsers } from "../../../utils/Api";
import UserContext from "../../../context/UserContext";

import Layout from "../../layout/layout";

function DealerCompanion() {
  const { setLivePlayers, gameData } = useContext(UserContext)
  const { players, waitingPlayers } = gameData

  const newPlayer = (pl, wPl) => wPl?.filter((a) => !pl.includes(a))
  newPlayer(players, waitingPlayers)

  useEffect(() => {
    getAllUsers(setLivePlayers);
  }, []);

  return (
    <Layout>
      <DealerLayout>
        <div className="dealer-companion">
          <PokerTable />
        </div>
      </DealerLayout>
    </Layout>
  );
}

export default DealerCompanion;
