import * as yup from "yup";

export const RegisterSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string(),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  verifyPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
      "Password must be alpa numeric"
    ),
});

export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

export const ResetPasswordSchema = yup.object({
  confirmPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
      "Password must be alpa numeric"
    ),
});

export const ForgetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});
