function CrossIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.29304 5.29289C5.68357 4.90237 6.31673 4.90237 6.70726 5.29289L19.4815 18.0671C19.872 18.4576 19.872 19.0908 19.4815 19.4813C19.0909 19.8718 18.4578 19.8718 18.0672 19.4813L5.29304 6.70711C4.90252 6.31658 4.90252 5.68342 5.29304 5.29289Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 19.4811C4.90237 19.0906 4.90237 18.4574 5.29289 18.0669L18.0671 5.29272C18.4576 4.90219 19.0908 4.90219 19.4813 5.29272C19.8718 5.68324 19.8718 6.31641 19.4813 6.70693L6.70711 19.4811C6.31658 19.8716 5.68342 19.8716 5.29289 19.4811Z" fill="white" />
    </svg>
  );
}

export default CrossIcon
