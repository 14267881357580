import { useState, useContext } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import plusicon from "../../../../assets/images/dealer/plus.svg";
import Actions from "../actions/action";
import ChangeStack from "../modals/changestack";
import InviteUser from "../modals/inviteuser";
import carddefault from "../../../../assets/images/dealer/card-default.svg";
import addicon from "../../../../assets/images/dealer/add.svg";
import UserContext from "../../../../context/UserContext";
import socket from "../../../../config/socket";
import { client } from "../../../../config/keys";
import { DoDecrypt } from "../../../../utils";

function Player({ position, player, winOdd, winners }) {
  const { gameData } = useContext(UserContext);
  const [stack, setStack] = useState(false);
  const [invite, setInvite] = useState(false);
  const [invitePosition, setInvitePosition] = useState(null);

  const handleStackClose = () => {
    setStack(false);
  };
  const handleStackShow = () => {
    setStack(true);
  };

  const handleInviteClose = () => {
    setInvite(false);
  };
  const handleInviteShow = (pos) => {
    if (gameData?.gamestart) return;
    setInvite(true);
    setInvitePosition(pos);
  };

  const handleRemoveUser = async (userId) => {
    socket.emit("removeUserFromGame", { gameId: gameData.id, userId })
  };

  const handlePlayerMute = async (data) => {
    socket.emit("playerMicChange", { gameId: gameData?.id, userId: data.id, isAudio: !data.isAudio });
  }

  return (
    <div
      className={`player player${position} ${
        gameData?.timerPlayer === player?.id
        || player?.position === gameData?.scanningPlayer
          ? "active"
          : ""
      }`}
    >
      {player ? (
        <>
          <div className="player-grid">
            <div className="player-cover">
              <div className="player-stack" role="presentation" onClick={handleStackShow}>
                <img src={plusicon} alt="" />
              </div>
              <div className="player-pic">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src={player.avatar}
                      alt=""
                      onError={({ currentTarget }) => {
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.onerror = null; // prevents looping
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="#">
                      Invite to take a stage
                    </Dropdown.Item> */}
                    {player?.isAudio
                      ? (
                        <Dropdown.Item
                          onClick={() => handlePlayerMute(player)}
                        >
                          Mute
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item>
                          Muted
                        </Dropdown.Item>
                      )}
                    {/* <Dropdown.Item
                      onClick={() => handlePlayerMute(player)}
                    >
                      Mute
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => handleRemoveUser(player?.id)}
                    >
                      Remove
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="player-name" role="presentation" onClick={() => handleInviteShow(position)}>
                <p>{player?.name}</p>
              </div>
            </div>

            <div className="player-content">
              <div className="player-card">
                {[0, 1].map((card) => (
                  player?.cards?.length && player?.cards[card] && !player?.fold ? <img
                    key={card}
                    src={
                      player?.cards?.length && player?.cards[card]
                        ? `${client}cards/${DoDecrypt(player?.cards[card])}.svg`
                        : carddefault
                    }
                    alt=""
                  /> : ""
                ))}
              </div>
              <div className="player-bet">
                <h4>
                  $
                  {player?.wallet}
                </h4>
                <div className="player-timer">
                  {player?.isPlaying ? (
                    <span>
                      {winOdd}
                    </span>
                  ) : (
                    <span>{winOdd}</span>
                  )}
                  {player?.id === gameData?.currentPlayer?.id ? (
                    <h6>00:12</h6>
                  ) : (
                    ""
                  )}
                </div>
                {player?.position === gameData?.smallBlindPosition ? (
                  <div className="dealer-symbol">S</div>
                ) : player?.position === gameData?.bigBlindPosition ? (
                  <div className="dealer-symbol">B</div>
                ) : player?.position === gameData?.dealerPosition ? (
                  <div className="dealer-symbol">D</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {player && gameData ? <Actions user={player} gameData={gameData} winners={winners} /> : ""}
        </>
      ) : (
        <div className="add-player" role="presentation" onClick={() => handleInviteShow(position)}>
          <img src={addicon} alt="" />
        </div>
      )}

      <Modal
        show={stack}
        onHide={handleStackClose}
        centered
        className="poker-popup stack-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change stack</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangeStack
            handleStackClose={handleStackClose}
            userId={player?.id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={invite}
        onHide={handleInviteClose}
        centered
        className="poker-popup invite-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteUser
            position={invitePosition}
            handleInviteClose={handleInviteClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

Player.defaultProps = {
  player: null,
  winOdd: "0%",
}

Player.propTypes = {
  position: PropsTypes.number.isRequired,
  player: PropsTypes.object,
  winOdd: PropsTypes.string,
  winners: PropsTypes.array.isRequired
}
export default Player;
