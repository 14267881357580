import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PropsTypes from "prop-types";
import Dropzone from "react-dropzone";
// eslint-disable-next-line import/no-extraneous-dependencies
import clipboardCopy from "clipboard-copy";
import { useState } from "react";
import { client } from "../../../config/keys";
import copy from "../../../assets/images/gamedetail/copy-gold.svg";
import check from "../../../assets/images/gamedetail/check.svg";
import deleteIcon from "../../../assets/images/gamedetail/delete.svg";
import fileicon from "../../../assets/images/gamedetail/file.svg";
import "./detailPopup.css";
import { VideoTrackRemove, videoTrackUpload } from "../../../utils/Api";
import cross from "../../../assets/images/inrovideo/cross.svg";

function DetailTable({
  isLoading,
  games,
  handleDeleteGameData,
  handleAddMoreTable,
  gameEventId,
  setGame,
}) {
  const [showCopyTooltip, setShowCopyTooltip] = useState(" ");
  //  const [videoFile, setVideoFile] = useState();
  const [videoFileId, setVideoFileId] = useState("");
  const [videoTrackReset, setVideoTrackReset] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const handleCopy = (value) => {
    setShowCopyTooltip(value);
    setTimeout(() => {
      setShowCopyTooltip(" ");
    }, 1000);
  };

  const uploadVideo = async (id, file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("gameId", id);
      formData.append("gameEventId", gameEventId);
      setLoading("loading");
      const res = await videoTrackUpload(formData);
      if (!res) {
        setLoading("error");
      }
      if (res) {
        setGame(res?.gameEvent);
      }
      setLoading("");
    }
  };

  const handleFileSelect = (files, id) => {
    setVideoFileId(id);
    if (!files[0].type.startsWith("video")) {
      return setError("Please select video file");
    }
    if (files[0].size / (1024 * 1024) > 101) {
      setLoading("error");
      return setError("Max 100 MB is allowed");
    }

    // setVideoFile(files[0]);
    setError("");
    uploadVideo(id, files[0]);
  };

  const handleDeleteFile = async (id) => {
    const response = await VideoTrackRemove(id, gameEventId);
    if (response) {
      setGame(response.gameEvent);
    }
    setError("");
    setLoading("");
    setVideoTrackReset(id);
    setVideoFileId("");
  };

  const copyToClipBoard = (text, value) => {
    clipboardCopy(text)
      .then(() => {
        if (value) handleCopy(value);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  };

  return (
    <div className="game-list-section">
      <div className="game-list-table">
        <div className="game-list-th">
          <div className="game-list-tr">
            <div className="game-list-sr">
              <h6>Table #</h6>
            </div>
            <div className="game-list-link">
              <h6>Main Player link</h6>
            </div>
            <div className="game-list-link">
              <h6>Dealer link</h6>
            </div>
            {/* <div className="game-list-player">
              <h6>Number of players</h6>
            </div> */}
            <div className="game-intro-video">
              <h6>Welcome video</h6>
            </div>
            <div className="game-list-delete">
              <h6>Delete</h6>
            </div>
          </div>
        </div>
        <div className="game-list-body">
          {games?.map((game, i) => (
            <div className="game-list-tr" key={game?.id}>
              <div className="game-list-sr">
                <h6>
                  #
                  {i + 1}
                </h6>
              </div>
              <div className="game-list-link">
                {showCopyTooltip !== `main-${game?.id}` ? (
                  <Button
                    type="button"
                    onClick={() =>
                      copyToClipBoard(
                        `${client}user/main-game/${game?.id}`,
                        `main-${game?.id}`
                      )}
                  >
                    Copy link
                    <img src={copy} alt="copy icon" />
                  </Button>
                ) : (
                  <span className="copied-tooltip">
                    Copied
                    {" "}
                    <img src={check} alt="check icon" />
                  </span>
                )}
              </div>

              <div className="game-list-link">
                {showCopyTooltip !== `comp-${game?.id}` ? (
                  <Button
                    type="button"
                    onClick={() =>
                      copyToClipBoard(
                        `${client}dealer/companion/${game?.id}`,
                        `comp-${game?.id}`
                      )}
                  >
                    Copy link
                    <img src={copy} alt="copy icon" />
                  </Button>
                ) : (
                  <span className="copied-tooltip">
                    Copied
                    {" "}
                    <img src={check} alt="check icon" />
                  </span>
                )}
              </div>
              {game?.videoTrack && videoTrackReset !== game?.id ? (
                <div className="game-intro-video uploaded">
                  <VideoUploadedList
                    videoFile={{ name: game?.videoTrack.split("/").pop() }}
                    handleDeleteFile={handleDeleteFile}
                    id={game.id}
                    gameEventId={gameEventId}
                  />
                </div>
              ) : (
                <div className="game-intro-video">
                  {loading === "loading" && videoFileId === game.id ? (
                    <VideoUploading />
                  ) : (
                    <VideoDropZone
                      onDrop={(d) => handleFileSelect(d, game.id)}
                      loading={loading}
                    />
                  )}
                  {videoFileId === game.id && (loading === "error" || error) ? (
                    <span className="video-upload-error">{error}</span>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="game-list-delete">
                <Button
                  onClick={() => handleDeleteGameData(game?.id)}
                  disabled={isLoading === `deleteTable-${game.id}`}
                >
                  {isLoading === `deleteTable-${game.id}` ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <img src={deleteIcon} alt="icon" />
                  )}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="load-more">
        <Button onClick={handleAddMoreTable} disabled={isLoading === "addMore"}>
          {isLoading === "addMore" ? (
            <Spinner animation="border" />
          ) : (
            <i className="las la-plus" />
          )}
        </Button>
      </div>
    </div>
  );
}

DetailTable.propTypes = {
  games: PropsTypes.array.isRequired,
  handleAddMoreTable: PropsTypes.func.isRequired,
  handleDeleteGameData: PropsTypes.func.isRequired,
  isLoading: PropsTypes.string.isRequired,
  gameEventId: PropsTypes.string.isRequired,
  setGame: PropsTypes.func.isRequired,
};
export default DetailTable;

function VideoDropZone({ onDrop, loading }) {
  return (
    <Dropzone onDrop={onDrop} disabled={loading === "loading"}>
      {({ getRootProps, getInputProps }) => (
        <section className="intro-drag-video">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Add a video file</p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

VideoDropZone.propTypes = {
  onDrop: PropsTypes.func.isRequired,
  loading: PropsTypes.string.isRequired,
};
function VideoUploading() {
  return (
    <section className="intro-uploading-video">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" size="sm" />
      </div>
      <span className="intro-uploading-close">
        <img src={cross} alt="" />
      </span>
    </section>
  );
}

function VideoUploadedList({ videoFile, handleDeleteFile, id }) {
  return (
    <section className="intro-uploading-video-list">
      <div className="uploaded-video-grid">
        <div className="uploaded-video-name" title={videoFile?.name}>
          <img src={fileicon} alt="icon" />
          {videoFile?.name}
        </div>
        <div
          className="intro-uploading-list-delete"
          onClick={() => handleDeleteFile(id)}
          role="presentation"
        >
          <span>
            <i className="las la-times" />
          </span>
        </div>
      </div>
    </section>
  );
}
VideoUploadedList.propTypes = {
  videoFile: PropsTypes.object.isRequired,
  handleDeleteFile: PropsTypes.func.isRequired,
  id: PropsTypes.string.isRequired,
};
