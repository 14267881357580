/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect, useRef } from "react";
import PropsTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useDaily, useDevices } from "@daily-co/daily-react";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import socket from "../../../config/socket";
import UserContext from "../../../context/UserContext";
import Chat from "./chat";
import RankTab from "./rankTab";
import ViewerTab from "./viewerTab";
import HandHistoryTab from "./handHistoryTab";
import BarRoomSetting from "../../barRoom/settings";
import CameraIcon from "../../../IconComponents/CamIcon";
import SpeakerIcon from "../../../IconComponents/SpeakerIcon";
import MicIcon from "../../../IconComponents/MicIcon";
import SettingIcon from "../../../IconComponents/SettingIcon";
import DropTableIcon from "../../../IconComponents/DropTableIcon";
import HandHistoryIcon from "../../../IconComponents/HandHistoryIcon";
import RankIcon from "../../../IconComponents/RankIcon";
import ViewersIcon from "../../../IconComponents/ViewersIcon";
import CamOffIcon from "../../../IconComponents/CamOffIcon";
import MicOffIcon from "../../../IconComponents/MicOffIcon";
import SpeakerOffIcon from "../../../IconComponents/SpeakerOffIcon";
import ArrowIcon from "../../../IconComponents/ArrowIcon";
import ChatIcon from "../../../IconComponents/ChatIcon";
import SidebarIcon from "../../../IconComponents/SidebarIcon";
import useOutsideAlerter from "../../../utils/outSideAlert";
// import { getRecodingLink } from "../../../utils/Api";

function GameSidebar({
  activeTab,
  isExpanded,
  sidebarExpand,
  setSidebarExpand,
}) {
  const [showDrop, setShowDrop] = useState("");
  const {
    user,
    noiseCancellation,
    blurBackGround,
    setMicDisable,
    userCamera,
    userMic,
    userSpeaker,
    speaker,
    setSpeaker,
    pokerTableVolume,
    barroomVolume,
    setbarroomVolume,
    setPokerTableVolume,
    gameEvent,
    setUserCamera,
    allGamesData,
    micOn,
    setMicOn,
    camOn,
    setCamOn,
    micValue,
    setMicValue,
  } = useContext(UserContext);

  const { gameId } = useParams();
  const gameData = allGamesData[gameId];
  const { players, viewers: viewerData } = gameData || {};
  //  const [micLevel, setMicLevel] = useState(60);
  const [speakerOptions, setSpeakerOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [microphoneOptions, setMicrophoneOptions] = useState([]);
  const [message, setMessage] = useState("");
  const [chatCount, setChatCount] = useState(0);
  const initTabs = {
    isChat: false,
    isHandHistory: false,
    isRank: false,
    isViewer: false,
  };
  const [visibleTab, setVisibleTab] = useState({ ...initTabs, isChat: true });
  const [showSetting, setShowSetting] = useState(false);
  const [chats, setChats] = useState([]);

  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);
  const [tableData, setTableData] = useState({
    selectedGameId: "",
    selecteTableName: "All Tables",
  });
  const [actualAllChats, setActualAllChats] = useState([]);
  const tableDropRef = useRef(null);
  const camDropRef = useRef(null);
  const micDropRef = useRef(null);
  const speakerDropRef = useRef(null);

  const playersRank = players?.sort((obj1, obj2) => obj2.wallet - obj1.wallet);
  playersRank?.forEach((obj, index) => {
    // eslint-disable-next-line no-param-reassign
    obj.rank = index + 1;
  });
  // Merging All Games Data for Viewers,Ranking,Winners Hand
  const concatAllData = (field1, field2, field3) => {
    let count = 0;
    let addFieldData = [];
    const data = gameEvent?.games?.map((el) => {
      if ((field2 === "players" || "showdown") && field3 === "viewersRank") {
        let playersData = el[field2] || [];
        let viewersData = el[field3] || [];
        if (playersData?.length > 0) {
          playersData = playersData?.sort((a, b) => b.wallet - a.wallet);
        }
        if (viewersData?.length > 0) {
          viewersData = viewersData?.reverse();
        }
        const sortedPlayers = [...playersData, ...viewersData];
        sortedPlayers?.forEach((player, index) => {
          if (player) {
            // eslint-disable-next-line no-param-reassign
            player.rank = index + 1;
          }
        });
        addFieldData = sortedPlayers;
      } else {
        addFieldData = el[field1];
      }

      count += Number(addFieldData?.length) || 0;

      return { gameId: el.id, [field1]: addFieldData };
    });
    return { data, count };
  };

  const handleSetting = (name) => {
    if (showDrop) {
      setShowDrop("");
      return;
    }
    setShowDrop(name);
  };

  const call = useDevices();
  const callObject = useDaily();

  useEffect(() => {
    const getMediaDevices = async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const videoCameras = allDevices.filter((m) => m.kind === "videoinput");
      const microphones = allDevices.filter((m) => m.kind === "audioinput");
      const speakers = allDevices.filter((m) => m.kind === "audiooutput");
      setCameraOptions(
        videoCameras.map((camera) => ({
          label: camera.label,
          value: camera.deviceId,
        }))
      );
      setMicrophoneOptions(
        microphones.map((mic) => ({ label: mic.label, value: mic.deviceId }))
      );
      setSpeakerOptions(
        speakers.map((s) => ({ label: s.label, value: s.deviceId }))
      );
    };
    getMediaDevices();
  }, []);

  useEffect(() => {
    if (gameEvent?.id) {
      socket.emit("getChats", { gameEventId: gameEvent.id });
    }
  }, [gameEvent?.id]);

  useEffect(() => {
    if (userCamera) {
      call.setCamera(userCamera?.value);
    }
  }, [userCamera]);

  useEffect(() => {
    if (userSpeaker) {
      call.setSpeaker(userSpeaker?.value);
    }
  }, [userSpeaker]);

  useEffect(() => {
    if (userMic) {
      call.setMicrophone(userMic?.value);
    }
  }, [userMic]);

  const remoteparticipant = callObject?.participants()?.local || {};

  const handleStorageChange = (event) => {
    if (event.key === "isCameraOn") {
      // eslint-disable-next-line no-use-before-define
      hamdleCam(true);
    } else if (event.key === "communityCamera") {
      setUserCamera(JSON.parse(event.newValue));
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [callObject]);

  useEffect(() => {
    if (user.role !== "dealer" && remoteparticipant.video) {
      callObject
        ?.updateInputSettings({
          video: {
            processor: {
              type: blurBackGround || "background-image",
              config: {
                source: `${window.location.origin}/backgrounds/greyBg.png`,
                fit: "fit",
              },
            },
          },
          audio: {
            processor: {
              type: JSON.parse(localStorage.getItem("noise-off"))
                ? "noise-cancellation"
                : "none",
            },
          },
        })
        .then((cc) => {
          console.log("cccccc =>", cc);
        });
    }
  }, [remoteparticipant?.video, noiseCancellation, blurBackGround]);

  useEffect(() => {
    socket.on("playerMicChange", (data) => {
      if (data.userId === user.id) {
        callObject?.updateParticipant(remoteparticipant.session_id, {
          setAudio: data.isAudio,
        });
      }
    });

    socket.on("dealerMuteAll", (data) => {
      if (data.gameId === gameId) {
        if (players?.find((pl) => pl.id === user?.id)) {
          localStorage.setItem("isMicOn", false);
          localStorage.setItem("dealerMuteAll", true);
          setMicDisable(true);
          setMicOn(false);
          callObject?.updateParticipant(remoteparticipant.session_id, {
            setAudio: false,
          });
        }
      }
    });

    socket.on("dealerUnMuteAll", (data) => {
      if (data.gameId === gameId) {
        setMicDisable(false);
        localStorage.removeItem("dealerMuteAll");
        if (players?.find((pl) => pl.id === user?.id && pl.isAudio)) {
          setMicOn(true);
          localStorage.setItem("isMicOn", true);
          callObject?.updateParticipant(remoteparticipant.session_id, {
            setAudio: true,
          });
        }
      }
    });

    socket.on("dealerMuteAllViewers", (data) => {
      if (data.gameId === gameId) {
        if (viewerData?.find((pl) => pl.id === user?.id)) {
          localStorage.setItem("isMicOn", false);
          localStorage.setItem("dealerMuteAllViewers", true);
          setMicDisable(true);
          setMicOn(false);
          callObject?.updateParticipant(remoteparticipant.session_id, {
            setAudio: false,
          });
        }
      }
    });

    socket.on("dealerUnMuteAllViewers", (data) => {
      if (data.gameId === gameId) {
        setMicDisable(false);
        localStorage.removeItem("dealerUnMuteAllViewers");
        if (viewerData?.find((pl) => pl.id === user?.id && pl.isAudio)) {
          setMicOn(true);
          localStorage.setItem("isMicOn", true);
          callObject?.updateParticipant(remoteparticipant.session_id, {
            setAudio: true,
          });
        }
      }
    });

    socket.on("playerCameraChange", (data) => {
      if (data.userId === user?.id) {
        callObject?.updateParticipant(remoteparticipant.session_id, {
          setVideo: data.isVideo,
        });
      }
    });

    return () => {
      socket.off("playerMicChange");
      socket.off("dealerMuteAll");
      socket.off("playerCameraChange");
      socket.off("dealerUnMuteAll");
      socket.off("dealerMuteAllViewers");
      socket.off("dealerUnMuteAllViewers");
    };
  }, [remoteparticipant, callObject]);

  const chatByTableId = (allChats, tableId) => {
    if (!tableId) return allChats;
    if (allChats?.length > 0) {
      return allChats?.filter((el) => el.gameId === tableId);
    }
  };

  useEffect(() => {
    socket.on("newMessage", (data) => {
      setActualAllChats(data.chats);
      const tableViseChats = chatByTableId(
        data.chats,
        tableData?.selectedGameId
      );
      setChats(tableViseChats);
      //  if (!visibleTab.isChat) {
      setChatCount(data?.chats?.length);
      //  }
    });

    return () => {
      socket.off("newMessage");
    };
  }, [gameEvent, tableData?.selectedGameId, visibleTab?.isChat]);

  const handleMic = () => {
    setMicOn(!remoteparticipant.audio);
    localStorage.setItem("isMicOn", !remoteparticipant.audio);
    socket.emit("playerMicChange", {
      gameId: gameData?.id,
      userId: user?.id,
      isAudio: !remoteparticipant.audio,
    });
  };

  const handleCamera = () => {
    setCamOn(!remoteparticipant.video);
    localStorage.setItem("isCameraOn", !remoteparticipant.video);
    socket.emit("playerCameraChange", {
      gameId: gameData?.id,
      userId: user?.id,
      isVideo: !remoteparticipant.video,
    });
  };

  const hamdleCam = () => {
    socket.emit("playerCameraChange", {
      gameId: gameData?.id,
      userId: user.id,
      isVideo: !remoteparticipant.video,
    });
  };

  const handleSpeaker = () => {
    setSpeaker(!speaker);
    localStorage.setItem("speakerVolume", !speaker);
  };

  const handlePokerTableVolume = (value) => {
    localStorage.setItem("tableConversationsSound", value / 100);
    setPokerTableVolume(value / 100);
  };

  const handleRailVolume = (value) => {
    localStorage.setItem("barRoomBackgroundSound", value / 100);
    setbarroomVolume(value / 100);
  };
  const handleTabs = (key, value) => {
    if (!sidebarExpand) {
      setSidebarExpand(!sidebarExpand);
    }
    if (key === "isChat" && !sidebarExpand) {
      setVisibleTab({ ...initTabs, [key]: true });
      return;
    }
    setVisibleTab({ ...initTabs, [key]: !value });
  };

  const handleChangeMessage = (e, name) => {
    if (name === "emoji") {
      setMessage((old) => old + e);
    } else {
      const { value } = e.target;
      setMessage(value);
    }
  };

  const handleSendNewMessage = (e) => {
    e.preventDefault();
    if (message.length === 0) {
      return;
    }
    socket.emit("newMessage", {
      userId: user.id,
      gameId,
      gameEventId: gameEvent?.id,
      message,
    });
    setMessage("");
  };
  const [allGameHands, setAllGameHands] = useState([]);
  const [actualAllGameHands, setActualGameHands] = useState([]);
  const [allGameViewers, setAllGameViewers] = useState([]);
  const [actualAllGameViewers, setActualAllGameViewers] = useState([]);
  const [allGamesRanks, setAllGamesRanks] = useState([]);
  const [actualAllGamesRanks, setActualAllGamesRank] = useState([]);
  const [currentPlayerPosition, setCurrentPlayerPosition] = useState(0);
  const [counts, setCounts] = useState({
    viewers: 0,
    winnersHand: 0,
    actualviewers: 0,
    actualwinnersHand: 0,
  });

  const handsOrViewersOrRanksById = (winnerAllHands, id) => {
    if (!id) return winnerAllHands;
    const data = winnerAllHands.find((el) => el.gameId === id);
    if (data) return [data];
    return [];
  };

  const handleTableChange = (id, name) => () => {
    const tableViseChats = chatByTableId(actualAllChats, id);
    const tableViseWinnerHands = handsOrViewersOrRanksById(
      actualAllGameHands,
      id
    );
    const tableViseViewers = handsOrViewersOrRanksById(
      actualAllGameViewers,
      id
    );
    const tableViseRanks = handsOrViewersOrRanksById(actualAllGamesRanks, id);

    setCounts({
      ...counts,
      winnersHand: !id
        ? counts?.actualwinnersHand || 0
        : tableViseWinnerHands[0]?.winnersHand?.length || 0,
      viewers: !id
        ? counts?.actualviewers || 0
        : tableViseViewers[0]?.viewers?.length || 0,
    });
    setAllGamesRanks(tableViseRanks);
    setAllGameViewers(tableViseViewers);
    setAllGameHands(tableViseWinnerHands);
    setChats(tableViseChats);
    setTableData({ ...tableData, selectedGameId: id, selecteTableName: name });
    setShowDrop("");
  };

  useEffect(() => {
    if (gameEvent) {
      const { data: gameAllhands, count: winnersHand } = concatAllData(
        "winnersHand"
      );
      const { data: gameAllViewers, count: viewers } = concatAllData(
        "viewers"
      );
      const { data: gameAllRankedPlayers } = concatAllData(
        "rankedPlayers",
        gameData?.runninground === 5 ? "showdown" : "players",
        "viewersRank"
      );
      const currentGame = gameAllRankedPlayers?.find(
        (el) => el.gameId === gameId
      );

      if (currentGame) {
        const curPlayer = currentGame?.rankedPlayers?.find(
          (el) => el.id === user.id
        );
        setCurrentPlayerPosition(curPlayer?.rank || 0);
      }
      setCounts({
        ...counts,
        viewers,
        winnersHand,
        actualwinnersHand: winnersHand,
        actualviewers: viewers,
      });

      setActualAllGamesRank(gameAllRankedPlayers);
      setActualAllGameViewers(gameAllViewers);
      setActualGameHands(gameAllhands);
      const tableViseWinnerHands = handsOrViewersOrRanksById(
        actualAllGameHands,
        tableData?.selectedGameId
      );
      const tableViseViewers = handsOrViewersOrRanksById(
        actualAllGameViewers,
        tableData?.selectedGameId
      );
      const tableViseRanks = handsOrViewersOrRanksById(
        actualAllGamesRanks,
        tableData?.selectedGameId
      );
      setAllGameHands(tableViseWinnerHands);
      setAllGamesRanks(tableViseRanks);
      setAllGameViewers(tableViseViewers);
    }
  }, [gameEvent]);

  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End

  const handleSidebar = () => {
    setSidebarExpand(!sidebarExpand);
  };

  useOutsideAlerter(
    showDrop === "tables"
      ? tableDropRef
      : showDrop === "camera"
        ? camDropRef
        : showDrop === "mic"
          ? micDropRef
          : showDrop === "speaker"
            ? speakerDropRef
            : "",
    setShowDrop
  );

  return (
    <>
      <div
        className={`game-sidebar-main ${isExpanded ? "lg" : "sm"} ${
          sidebarExpand ? "show" : "hide"
        }`}
      >
        <div className="side-setting-wrapper">
          <span
            className={`sidebar-toggle ${sidebarExpand ? "show" : "hide"}`}
            onClick={() => handleSidebar()}
            role="presentation"
          >
            <SidebarIcon />
          </span>
          <CamSetting
            camOn={camOn}
            handleCamera={handleCamera}
            handleSetting={handleSetting}
            showDrop={showDrop}
            cameraOptions={cameraOptions}
            camDropRef={camDropRef}
          />

          <SpeakerSetting
            speakerOn={speaker}
            setSpeakerOn={handleSpeaker}
            volumeLevel={parseInt(pokerTableVolume * 100, 10)}
            setVolumeLevel={handlePokerTableVolume}
            railVolumeLevel={parseInt(barroomVolume * 100, 10)}
            setRailVolumeLevel={handleRailVolume}
            handleSetting={handleSetting}
            showDrop={showDrop}
            speakerOptions={speakerOptions}
            activeTab={activeTab}
            isExpanded={isExpanded}
            speakerDropRef={speakerDropRef}
          />

          <MicSetting
            micOn={micOn}
            handleMic={handleMic}
            micLevel={micValue}
            setMicLevel={setMicValue}
            handleSetting={handleSetting}
            showDrop={showDrop}
            microphoneOptions={microphoneOptions}
            activeTab={activeTab}
            isExpanded={isExpanded}
            micDropRef={micDropRef}
          />
          <div className="setting-btn-group">
            <Button className="setting-btn" onClick={handleShowSetting}>
              <SettingIcon />
            </Button>
          </div>
        </div>
        <div className="table-drop-wrapper" ref={tableDropRef}>
          <div
            className="show-drop-btn"
            onClick={() => handleSetting("tables")}
            role="presentation"
          >
            <h5>
              {sidebarExpand ? (
                <>
                  Show:
                  {" "}
                  <span>
                    {gameEvent?.games?.length > 1
                      ? tableData?.selecteTableName
                      : "Table 1"}
                  </span>
                  {" "}
                </>
              ) : (
                <span>All</span>
              )}
            </h5>
            {gameEvent?.games?.length > 1 ? (
              <span
                className={`btn-down-icon ${
                  showDrop === "tables" ? "rotate" : ""
                }`}
                role="presentation"
              >
                <DropTableIcon />
              </span>
            ) : (
              ""
            )}
          </div>
          <div
            className={`drop-wrapper table-drop-list-wrapper ${
              showDrop === "tables" ? "show" : "hide"
            }`}
          >
            <span
              role="presentation"
              onClick={handleTableChange("", "All Tables")}
            >
              {gameEvent?.games?.length > 1 ? "All Tables" : "Table 1"}
            </span>
            {gameEvent?.games.map((el, i) => (
              <span
                role="presentation"
                key={el.id}
                onClick={handleTableChange(el.id, `Table #${i + 1}`)}
              >
                {`Table #${i + 1}`}
              </span>
            ))}
          </div>
        </div>
        <div className="game-stats-wrapper">
          {(!visibleTab?.isChat || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="ChatTab"
              onClick={() => handleTabs("isChat", visibleTab?.isChat)}
            >
              <ChatIcon />
              {isExpanded && activeTab === "multi-table" && <h3>Chat</h3>}
              {chatCount}
            </Button>
          )}
          {(!visibleTab?.isHandHistory || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="HandHistoryTab"
              onClick={() =>
                handleTabs("isHandHistory", visibleTab?.isHandHistory)}
            >
              <HandHistoryIcon />
              {isExpanded && activeTab === "multi-table" && (
                <h3>Hand history</h3>
              )}
              {counts?.winnersHand}
            </Button>
          )}
          {(!visibleTab?.isRank || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="RankTab"
              onClick={() => handleTabs("isRank", visibleTab?.isRank)}
            >
              <RankIcon />
              {isExpanded && activeTab === "multi-table" && <h3>Rank</h3>}
              {currentPlayerPosition}
            </Button>
          )}
          {(!visibleTab?.isViewer || !sidebarExpand) && (
            <Button
              className="stat-btn"
              name="ViewerTab"
              onClick={() => handleTabs("isViewer", visibleTab?.isViewer)}
            >
              <ViewersIcon />
              {isExpanded && activeTab === "multi-table" && <h3>Viewers</h3>}
              {counts?.viewers}
            </Button>
          )}
        </div>
        {visibleTab?.isChat && (
          <Chat
            chats={chats}
            handleChangeMessage={handleChangeMessage}
            message={message}
            handleSendNewMessage={handleSendNewMessage}
            tableData={tableData?.selecteTableName}
          />
        )}
        {visibleTab?.isHandHistory && (
          <HandHistoryTab
            gameData={allGamesData[gameId]}
            allGameHands={allGameHands}
            handCounts={counts?.winnersHand}
            tableData={tableData?.selecteTableName}
          />
        )}
        {visibleTab?.isRank && (
          <RankTab
            gameData={allGamesData[gameId]}
            allGamesRanks={allGamesRanks}
            currentPlayerPosition={currentPlayerPosition}
            tableData={tableData?.selecteTableName}
          />
        )}
        {visibleTab?.isViewer && (
          <ViewerTab
            gameData={allGamesData[gameId]}
            allGameViewers={allGameViewers}
            viewersCounts={counts?.viewers}
            tableData={tableData?.selecteTableName}
          />
        )}
      </div>
      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        className="bar-room-setting-popup"
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
        centered
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>
    </>
  );
}

GameSidebar.defaultProps = {
  isExpanded: false,
  sidebarExpand: true,
};
GameSidebar.propTypes = {
  isExpanded: PropsTypes.bool,
  sidebarExpand: PropsTypes.bool,
  activeTab: PropsTypes.string.isRequired,
  setSidebarExpand: PropsTypes.func.isRequired,
};

export default GameSidebar;

export function CamSetting({
  camOn,
  handleCamera,
  handleSetting,
  showDrop,
  cameraOptions,
  camDropRef,
}) {
  const { userCamera, setUserCamera } = useContext(UserContext);
  const call = useDevices();

  const selectedCamera = call.cameras.find((cam) => cam.selected)?.device
    .deviceId;
  //  const { startRecording, stopRecording, recordingId } = useRecording()
  //  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare({
  //    onLocalScreenShareStarted: () => {
  //      startRecording({ layout: {
  //        preset: "custom",
  //        composition_id: "daily:baseline",
  //        composition_params: { mode: "single" } } })
  //    },
  //    onLocalScreenShareStopped: () => { stopRecording() } })
  //  console.log("ddd", recordingId, isSharingScreen)

  //  useEffect(() => {
  //    if (recordingId) { getRecodingLink(recordingId) }
  //  }, [recordingId])
  const handleCameraChange = (value) => {
    setUserCamera(value);
    localStorage.setItem("camera", JSON.stringify(value));
  };
  return (
    <div className="setting-btn-group" ref={camDropRef}>
      <span className="setting-btn">
        <span onClick={() => handleCamera()} role="presentation">
          {camOn ? <CameraIcon /> : <CamOffIcon />}
        </span>
        <span
          className={`btn-down-icon ${showDrop === "camera" ? "rotate" : ""}`}
          onClick={() => handleSetting("camera")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
      </span>
      <div
        className={`drop-wrapper ${showDrop === "camera" ? "show" : "hide"}`}
      >
        {cameraOptions?.map((camera) => (
          <div className="drop-item" key={camera.label}>
            <div className="invite-user-action">
              <Form.Check
                className="add-check-box"
                type="radio"
                id={camera.label}
                name="camera-action"
                label={camera.label}
                value={camera.value}
                checked={
                  camera.value === userCamera?.value
                  || selectedCamera === camera.value
                }
                onChange={() => handleCameraChange(camera)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
CamSetting.defaultProps = {
  camOn: true,
  showDrop: "",
};

CamSetting.propTypes = {
  camOn: PropsTypes.bool,
  handleCamera: PropsTypes.func.isRequired,
  showDrop: PropsTypes.string,
  handleSetting: PropsTypes.func.isRequired,
  cameraOptions: PropsTypes.array.isRequired,
  camDropRef: PropsTypes.object.isRequired,
};

function SpeakerSetting({
  speakerOn,
  setSpeakerOn,
  volumeLevel,
  setVolumeLevel,
  railVolumeLevel,
  setRailVolumeLevel,
  handleSetting,
  showDrop,
  speakerOptions,
  activeTab,
  isExpanded,
  speakerDropRef,
}) {
  const { userSpeaker, setUserSpeaker } = useContext(UserContext);
  const handleSpeakerChange = (value) => {
    setUserSpeaker(value);
    localStorage.setItem("speaker", JSON.stringify(value));
  };
  return (
    <div className="setting-btn-group" ref={speakerDropRef}>
      <span className="setting-btn">
        <span onClick={() => setSpeakerOn(!speakerOn)} role="presentation">
          {speakerOn ? <SpeakerIcon /> : <SpeakerOffIcon />}
        </span>
        <span
          className={`btn-down-icon ${showDrop === "speaker" ? "rotate" : ""}`}
          onClick={() => handleSetting("speaker")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
      </span>
      <div
        className={`drop-wrapper ${showDrop === "speaker" ? "show" : "hide"}`}
      >
        {speakerOptions?.map((sp) => (
          <div className="drop-item" key={sp.label}>
            <div className="invite-user-action">
              <Form.Check
                className="add-check-box"
                type="radio"
                id={sp.label}
                name="speaker-action"
                label={sp.label}
                value={sp.value}
                checked={userSpeaker?.value === sp.value}
                onChange={() => handleSpeakerChange(sp)}
              />
            </div>
          </div>
        ))}
        <div className="drop-item-divider" />
        <div className="volume-control-wrapper">
          <div className="slider-title">
            <span>Main game volume</span>
          </div>
          <div className="volume-range-main">
            <input
              className="volume-input-slider"
              type="range"
              min="0"
              max="100"
              value={volumeLevel}
              onChange={(e) => setVolumeLevel(e.target.value)}
              id="slider"
            />
            <div
              id="selector"
              style={{
                left: `${
                  activeTab === "multi-table" && isExpanded && volumeLevel < 3
                    ? 2
                    : volumeLevel <= 7
                      ? 7
                      : volumeLevel
                }%`,
              }}
            >
              <div
                className="volume-range-selectBtn"
                style={{
                  left: `${
                    volumeLevel <= 12 ? 0 : volumeLevel >= 96 ? 0 : 12
                  }px`,
                }}
              />
              <div
                className="selectValue "
                style={{
                  left: `${
                    volumeLevel <= 12 ? 50 : volumeLevel >= 96 ? 50 : 100
                  }%`,
                }}
              >
                {volumeLevel}
                %
              </div>
            </div>
            <div id="progressBar" style={{ width: `${volumeLevel}%` }} />
          </div>
        </div>
        <div className="volume-control-wrapper rail-volume-control-wrapper">
          <div className="slider-title">
            <span>Rail participants volume</span>
          </div>
          <div className="volume-range-main">
            <input
              className="volume-input-slider"
              type="range"
              min="0"
              max="100"
              value={railVolumeLevel}
              onChange={(e) => setRailVolumeLevel(e.target.value)}
              id="slider"
            />
            <div
              id="selector"
              style={{
                left: `${
                  activeTab === "multi-table"
                  && isExpanded
                  && railVolumeLevel < 3
                    ? 2
                    : railVolumeLevel <= 7
                      ? 7
                      : railVolumeLevel
                }%`,
              }}
            >
              <div
                className="volume-range-selectBtn"
                style={{
                  left: `${
                    railVolumeLevel <= 12 ? 0 : railVolumeLevel >= 96 ? 0 : 12
                  }px`,
                }}
              />
              <div
                className="selectValue"
                style={{
                  left: `${
                    railVolumeLevel <= 12
                      ? 50
                      : railVolumeLevel >= 96
                        ? 50
                        : 100
                  }%`,
                }}
              >
                {railVolumeLevel}
                %
              </div>
            </div>
            <div id="progressBar" style={{ width: `${railVolumeLevel}%` }} />
          </div>
        </div>
      </div>
    </div>
  );
}
SpeakerSetting.defaultProps = {
  speakerOn: true,
  showDrop: "",
};

SpeakerSetting.propTypes = {
  speakerOn: PropsTypes.bool,
  setSpeakerOn: PropsTypes.func.isRequired,
  setVolumeLevel: PropsTypes.func.isRequired,
  volumeLevel: PropsTypes.number.isRequired,
  railVolumeLevel: PropsTypes.number.isRequired,
  setRailVolumeLevel: PropsTypes.func.isRequired,
  showDrop: PropsTypes.string,
  handleSetting: PropsTypes.func.isRequired,
  speakerOptions: PropsTypes.array.isRequired,
  isExpanded: PropsTypes.bool.isRequired,
  activeTab: PropsTypes.string.isRequired,
  speakerDropRef: PropsTypes.object.isRequired,
};

function MicSetting({
  micOn,
  handleMic,
  micLevel,
  setMicLevel,
  handleSetting,
  showDrop,
  microphoneOptions,
  activeTab,
  isExpanded,
  micDropRef,
}) {
  const { userMic, setUserMic, micDisable } = useContext(UserContext);
  const handleUserMic = (value) => {
    setUserMic(value);
    localStorage.setItem("mic", JSON.stringify(value));
  };
  return (
    <div className="setting-btn-group" ref={micDropRef}>
      <span className="setting-btn">
        <span
          style={{ pointerEvents: ` ${micDisable ? "none" : ""}` }}
          onClick={() => handleMic()}
          role="presentation"
        >
          {micOn ? <MicIcon /> : <MicOffIcon />}
        </span>
        <span
          className={`btn-down-icon ${showDrop === "mic" ? "rotate" : ""}`}
          onClick={() => handleSetting("mic")}
          role="presentation"
        >
          <ArrowIcon />
        </span>
      </span>
      <div className={`drop-wrapper ${showDrop === "mic" ? "show" : "hide"}`}>
        {microphoneOptions?.map((m) => (
          <div className="drop-item" key={m.label}>
            <div className="invite-user-action">
              <Form.Check
                className="add-check-box"
                type="radio"
                id={m.label}
                name="mic-action"
                label={m.label}
                value={m.label}
                checked={m.value === userMic?.value}
                onChange={() => handleUserMic(m)}
              />
            </div>
          </div>
        ))}
        <div className="drop-item-divider" />
        <div className="volume-control-wrapper">
          <div className="slider-title">
            <span>Input volume</span>
          </div>
          <div className="volume-range-main">
            <input
              className="volume-input-slider"
              type="range"
              min="0"
              max="100"
              value={micLevel}
              onChange={(e) => setMicLevel(e.target.value)}
              id="slider"
            />
            <div
              id="selector"
              //  style={{ left: `${micLevel <= 7 ? 7 : micLevel}%` }}
              style={{
                left: `${
                  activeTab === "multi-table" && isExpanded && micLevel < 3
                    ? 2
                    : micLevel <= 7
                      ? 7
                      : micLevel
                }%`,
              }}
            >
              <div
                className="volume-range-selectBtn"
                style={{
                  left: `${micLevel <= 12 ? 0 : micLevel >= 96 ? 0 : 12}px`,
                }}
              />
              <div
                className="selectValue "
                style={{
                  left: `${micLevel <= 12 ? 50 : micLevel >= 96 ? 50 : 100}%`,
                }}
              >
                {micLevel}
                %
              </div>
            </div>
            <div id="progressBar" style={{ width: `${micLevel}%` }} />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
MicSetting.defaultProps = {
  micOn: true,
  showDrop: "",
  isExpanded: false,
};

MicSetting.propTypes = {
  micOn: PropsTypes.bool,
  handleMic: PropsTypes.func.isRequired,
  setMicLevel: PropsTypes.func.isRequired,
  micLevel: PropsTypes.string.isRequired,
  showDrop: PropsTypes.string,
  handleSetting: PropsTypes.func.isRequired,
  microphoneOptions: PropsTypes.array.isRequired,
  isExpanded: PropsTypes.bool,
  activeTab: PropsTypes.string.isRequired,
  micDropRef: PropsTypes.object.isRequired,
};
