/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropsTypes from "prop-types";
import { client } from "../../../config/keys";
import HandInfoIcon from "../../../IconComponents/HandInfoIcon";
import CardBack from "../../../assets/images/cardBack.svg";
import ArrowIcon from "../../../IconComponents/ArrowIcon";

const HandInfoData = [
  {
    title: "Royal flush",
    desc: "5 same-suit consecutive cards lead by Ace",
    cards: ["10S", "JS", "QS", "KS", "AS"],
  },
  {
    title: "Straight Flush",
    desc: "5 consecutive cards of the same suit",
    cards: ["4H", "5H", "6H", "7H", "8H"],
  },
  {
    title: "Four of a kind",
    desc: "4 cards of the same number",
    cards: ["QS", "QD", "QH", "QC", ""],
  },
  {
    title: "Full house",
    desc: "3 cards of the sane number and a pair",
    cards: ["KS", "KD", "3C", "3H", "3D"],
  },
  {
    title: "Flush",
    desc: "5 cards of the same suit",
    cards: ["AD", "QD", "6D", "JD", "2D"],
  },
  {
    title: "Straight",
    desc: "5 consecutive cards of any suit",
    cards: ["KS", "QD", "JC", "10H", "9S"],
  },
  {
    title: "Three of kind",
    desc: "3 cards of the same number",
    cards: ["8D", "8S", "8H", "", ""],
  },
  {
    title: "Two pair",
    desc: "Two sets of 2 cards of the same number",
    cards: ["7D", "7S", "QS", "QD", ""],
  },
  {
    title: "Pair",
    desc: "2 cards of the same number",
    cards: ["6H", "6D", "", "", ""],
  },
  {
    title: "High card",
    desc: "The highest unordered card wins",
    cards: ["KD", "", "", "", ""],
  },
];

function HandInfo({ handleHandInfo, showHandInfo }) {
  return (
    <div className="hand-info-main">
      <div className={`hand-info-wrapper ${showHandInfo ? "show" : ""}`}>
        <div className="hand-info-head">
          <HandInfoIcon />
          <h5>Poker hand Info</h5>
        </div>
        <div className="hand-info-card-wrapper">
          {HandInfoData.map((cardInfo, i) => (
            <div className="hand-info-card" key={i}>
              <div className="hand-info-card-text">
                <h6>{cardInfo?.title}</h6>
                <p>{cardInfo?.desc}</p>
              </div>
              <div className="hand-info-card-box">
                {cardInfo?.cards.map((card, i) => (
                  <img
                    src={card !== "" ? `${client}/main-view-cards/${card}.svg` : CardBack}
                    alt={card}
                    key={i}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`hand-info-btn ${showHandInfo ? "close" : ""}`} onClick={handleHandInfo} title="Hand Info" role="presentation">
        {!showHandInfo ? <HandInfoIcon /> : <ArrowIcon />}
      </div>
    </div>
  );
}

HandInfo.defaultProps = {
  showHandInfo: false
}

HandInfo.propTypes = {
  showHandInfo: PropsTypes.bool,
  handleHandInfo: PropsTypes.func.isRequired
}

export default HandInfo;
