import { useState, useContext, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { ProfileSchema } from "../../validationSchema/profileSchema";
import { deleteImage, getUser, updateUser, uploadImage } from "../../utils/Api";
// import logo from "../../assets/images/dealer/logo.svg";
import ProfileModal from "./profileModal";
import "./profile.css";

function CreateProfileModal() {
  const { user, setUser, setShowProfileModal, gameData } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const initProfileData = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    companyName: user?.companyName || "",
    aboutMe: user?.aboutMe || "",
    title: user?.title || "",
    twitter: user?.twitter || "",
    instagram: user?.instagram || "",
  };
  const [profileFormData, setProfileFormData] = useState(initProfileData);
  const [deleteFile, setDeleteFile] = useState(false);
  const { gameId } = useParams();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues: { user, gameId, round: gameData?.runninground },
  });

  const callBackuserUpdate = (data) => {
    setUser(data.user);
  };

  const handleUpdate = async (values) => {
    setIsLoading("update");
    if (deleteFile) {
      await deleteImage();
      getUser(callBackuserUpdate);
    }
    const payload = { ...values, user };

    const res = await updateUser(payload);
    setShowProfileModal(false);
    setIsLoading("");
    if (res) {
      setDeleteFile(false);
      setUser(res);
    }
    navigate(window.location.pathname);
  };
  const splitName = user?.avatar;
  const imageName = splitName?.substring(splitName.lastIndexOf("/") + 1);

  const handleClick = () => {
    setShowProfileModal(false);
    setDeleteFile(false);
    navigate(window.location.pathname);
  };

  const handleSaveFile = async (imageFile) => {
    // setIsLoading("save");
    const formdata = new FormData();
    formdata.append("file", imageFile);
    const res = await uploadImage(formdata);
    if (res) {
      getUser(callBackuserUpdate);
      setIsLoading("");
      setImagePreview(file);
    }
  };

  const handleFileChange = (e) => {
    const file1 = e.target.files[0];
    setImagePreview(URL.createObjectURL(file1));
    setFile(file1);
    handleSaveFile(file1);
  };

  const handleSetDeleteFile = async () => {
    setDeleteFile(true);
    setImagePreview("https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png");
    setIsLoading("save");
  };

  useEffect(() => {
    const updateProfileData = {
      firstName: watch("firstName") ? watch("firstName") : user?.firstName,
      lastName: watch("lastName") ? watch("lastName") : user?.lastName,
      email: watch("email") ? watch("email") : user?.email,
      companyName: watch("companyName")
        ? watch("companyName")
        : user?.companyName,
      phoneNumber: watch("phoneNumber")
        ? watch("phoneNumber")
        : user?.phoneNumber,
      aboutMe: watch("aboutMe") ? watch("aboutMe") : user?.aboutMe,
      title: watch("title") ? watch("title") : user?.title,
      twitter: watch("twitter") ? watch("twitter") : user?.twitter,
      instagram: watch("instagram") ? watch("instagram") : user?.instagram,
    };
    setProfileFormData(updateProfileData);
  }, [
    watch("firstName"),
    watch("lastName"),
    watch("email"),
    watch("companyName"),
    watch("phoneNumber"),
    watch("aboutMe"),
    watch("title"),
    watch("twitter"),
    watch("instagram"),
  ]);

  return (
    <div className="register-profile-modal">
      <div className="create-profile-page">
        <div className="create-profile">
          <div className="create-profile-content">
            <Form onSubmit={handleSubmit(handleUpdate)}>
              <div className="create-profile-left">
                <h3>Create profile</h3>

                <div className="create-profile-grid">
                  <div className="profile-pic-upload">
                    <div className="profile-pic">
                      <img
                        src={imagePreview || user?.avatar}
                        onError={({ currentTarget }) => {
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.onerror = null; // prevents looping
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                        }}
                        alt=""
                      />
                    </div>
                    <div className="upload-btn">
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        multiple={false}
                      />
                    </div>
                    <div className="delete-btn">
                      {imagePreview ? (
                        <Button
                          onClick={handleSaveFile}
                          disabled={isLoading === "save"}
                        >
                          {isLoading === "" ? (
                            <Spinner animation="border" />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      ) : (
                        <Button
                          className="dlt-btn"
                          onClick={handleSetDeleteFile}
                          disabled={imageName === "user.png"}
                        >
                          {isLoading === "delete" ? (
                            <Spinner animation="border" />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="profile-form">
                    <div className="half-width">
                      <Form.Group className="form-group">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter firstname"
                          name="firstName"
                          className={errors.firstName ? "error-field" : ""}
                          defaultValue={user?.firstName}
                          {...register("firstName")}
                        />
                        {errors?.firstName ? (
                          <p className="error-text">
                            {errors.firstName.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter lastname"
                          name="lastName"
                          defaultValue={user?.lastName}
                          className={errors.lastName ? "error-field" : ""}
                          {...register("lastName")}
                        />
                        {errors?.lastName ? (
                          <p className="error-text">
                            {errors.lastName.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          readOnly
                          placeholder="Enter email"
                          name="email"
                          defaultValue={user?.email}
                          className={errors.email ? "error-field" : ""}
                          {...register("email")}
                        />
                        {errors?.email ? (
                          <p className="error-text">{errors.email.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone number"
                          name="phoneNumber"
                          defaultValue={user?.phoneNumber}
                          className={errors.phoneNumber ? "error-field" : ""}
                          {...register("phoneNumber")}
                        />
                        {errors?.phoneNumber ? (
                          <p className="error-text">
                            {errors.phoneNumber.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </div>
                    <div className="half-width">
                      <Form.Group className="form-group">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter company name"
                          name="companyName"
                          defaultValue={user?.companyName}
                          className={errors.companyName ? "error-field" : ""}
                          {...register("companyName")}
                        />
                        {errors?.companyName ? (
                          <p className="error-text">
                            {errors.companyName.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your Title"
                          name="title"
                          defaultValue={user?.title}
                          className={errors.title ? "error-field" : ""}
                          {...register("title")}
                        />
                        {errors?.title ? (
                          <p className="error-text">{errors.title.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Twitter (Optional)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Twitter handle"
                          name="twitter"
                          defaultValue={user?.twitter ? user.twitter : null}
                          className={errors.twitter ? "error-field" : ""}
                          {...register("twitter")}
                        />
                        {errors?.twitter ? (
                          <p className="error-text">{errors.twitter.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Instagram (Optional)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Instagram handle"
                          name="instagram"
                          defaultValue={
                            user?.instagram ? user?.instagram : null
                          }
                          className={errors.instagram ? "error-field" : ""}
                          {...register("instagram")}
                        />
                        {errors?.instagram ? (
                          <p className="error-text">
                            {errors.instagram.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              <div className="create-profile-right">
                <div className="profile-btns">
                  <span onClick={handleShow} role="presentation">
                    Profile preview
                  </span>
                  <Button
                    className="profile-outline-btn profile-bg-btn"
                    onClick={handleClick}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="profile-bg-btn"
                    type="submit"
                    disabled={isLoading === "update"}
                  >
                    {isLoading === "update" ? (
                      <Spinner animation="border" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
                <Form.Group className="form-group">
                  <Form.Label>About me</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="aboutMe"
                    defaultValue={user?.aboutMe}
                    {...register("aboutMe")}
                  />
                  {errors?.aboutMe ? (
                    <p className="error-text">{errors.aboutMe.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                {/* <Form.Group className="form-group">
                    <Form.Check
                      aria-label="option 1"
                      label="Allow to make public Twitter and Instagram links"
                      // defaultChecked={user?.isLinkPublic}
                      defaultChecked="checked"
                      name="isLinkPublic"
                      {...register("isLinkPublic")}
                    />
                    {errors?.isLinkPublic ? (
                      <p className="error-text">
                        {errors.isLinkPublic.message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Group> */}

                <div className="profile-change-password">Change password</div>
              </div>
            </Form>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          className="preview-modal"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <ProfileModal
              imagePreview={imagePreview}
              user={user}
              profileFormData={profileFormData}
            />
          </Modal.Body>
        </Modal>
      </div>
      {/* )} */}
    </div>
  );
}

export default CreateProfileModal;
