import React, { useContext } from "react";
import PropsTypes from "prop-types";

import { useParams } from "react-router-dom";
import MultiTablePlayerGameView from "../User/playerGameView/MultiTablePlayerGameView";
import RotateIcon from "../../IconComponents/RotateIcon"

import "./style.css";

import socket from "../../config/socket";
import UserContext from "../../context/UserContext";

function MultiTableGameView({ gameId }) {
  const { user, allGamesData } = useContext(UserContext);
  const { gameId: gId } = useParams();

  const handleViewClick = () => {
    socket.emit("playerChangingTable", { userId: user?.id, gameFromId: gId, gameToId: gameId });
    window.location.href = `/user/main-game/${gameId}`;
  }
  return (
    <div className="multi-table-game-view">
      <div className="multi-table-game-wrapper">
        <MultiTablePlayerGameView gameId={gameId} />
      </div>
      {allGamesData[gId]?.viewers?.find((el) => el.id === user?.id)
        ? (
          <div className="table-hover-switch" role="presentation" onClick={handleViewClick}>
            <RotateIcon />
            <p>Switch to the Table </p>
          </div>
        ) : ""}
    </div>
  );
}

MultiTableGameView.propTypes = {
  gameId: PropsTypes.string.isRequired,
};

export default MultiTableGameView;
