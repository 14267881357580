import React, { useEffect, useRef, useState } from "react";
import PropsTypes from "prop-types";
import WaitingUser from "./waitingUser";
import RailUser from "./railUser";

function ManageWaitingRailModal({ showWaitingList, setShowWaitingList }) {
  const waitingModal = useRef(null)
  const [activeTab, setActiveTab] = useState("waiting");

  const handleTab = (name) => {
    setActiveTab(name);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowWaitingList(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(waitingModal);

  return (
    <div
      className={`manage-waiting-rail-modal ${showWaitingList ? "show" : ""}`}
      ref={waitingModal}
    >
      <div className="waiting-rail-modal-wrapper">
        <div className="waiting-rail-tab-head">
          <div className="waiting-rail-tab-btn-wrapper">
            <span
              className={activeTab === "waiting" ? "active" : ""}
              onClick={() => handleTab("waiting")}
              role="presentation"
            >
              Waiting list
            </span>
            <span
              className={activeTab === "rail" ? "active" : ""}
              onClick={() => handleTab("rail")}
              role="presentation"
            >
              Rail
            </span>
          </div>
        </div>
        <div className="waiting-rail-tab-content-wrapper">
          {activeTab === "waiting" && <WaitingUser />}
          {activeTab === "rail" && <RailUser />}
        </div>
      </div>
    </div>
  );
}

ManageWaitingRailModal.defaultProps = {
  showWaitingList: false,
};
ManageWaitingRailModal.propTypes = {
  showWaitingList: PropsTypes.bool,
  setShowWaitingList: PropsTypes.func.isRequired,
};

export default ManageWaitingRailModal;
