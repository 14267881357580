import React from "react";
import PropsTypes from "prop-types";
import RankIcon from "../../../IconComponents/RankIcon";
import ChipIcon from "../../../IconComponents/ChipIcon";

function RankTab({
  allGamesRanks, currentPlayerPosition, tableData }) {
  const findDuplicate = (data) => {
    const uniquePlayers = {};
    const uniquePlayerData = data?.filter((player) => {
      const playerId = player.id;
      // Add the player to the uniquePlayers object if the ID is not already present
      if (!uniquePlayers[playerId]) {
        uniquePlayers[playerId] = true;
        return true;
      }
      return false;
    });
    return uniquePlayerData;
  }
  return (
    <div className="chat-wrapper rank-wrapper">
      <div className="tab-card-head">
        <RankIcon />
        <h4>
          Rank
          <span>{currentPlayerPosition || 0}</span>
        </h4>
      </div>
      <div className="tab-card-body">

        {allGamesRanks?.map((el, ind) =>
          (findDuplicate(el?.rankedPlayers)
            ?.length > 0
            ? (
              <>
                <div className="tab-card-element">
                  <p className="element-title">{tableData === "All Tables" ? `Table #${ind + 1}` : tableData}</p>
                </div>
                {el?.rankedPlayers
                  ?.map((rank) => (
                    <div className="tab-card-element" key={rank.id}>
                      <div className="hand-history-head rank-element-head">
                        <div className="hand-history-head-left">
                          <span className="hand-count">{rank?.rank}</span>
                          <div className="hand-history-profile-wrapper" style={{ opacity: `${rank?.wallet === 0 ? 0.5 : 1}` }}>
                            <img
                              src={rank?.avatar}
                              onError={({ currentTarget }) => {
                                // eslint-disable-next-line no-param-reassign
                                currentTarget.onerror = null; // prevents looping
                                // eslint-disable-next-line no-param-reassign
                                currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
                              }}
                              alt={rank?.name}
                            />
                            <div className="hand-winner-name">
                              <p>{rank?.name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="rank-head-right">
                          <div className="rank-chips-details">
                            <p>{rank?.wallet}</p>
                            <ChipIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

              </>
            ) : (
              ""
            ))
        )}

        {}
        <div className="tab-card-element">
          {/* <p className="element-title">Table #2</p> */}
        </div>
      </div>
    </div>
  );
}
RankTab.defaultProps = {
//  gameData: {},
  allGamesRanks: [],
  currentPlayerPosition: 0,
  tableData: "All Tables",

};
RankTab.propTypes = {
//  gameData: PropsTypes.object,
  allGamesRanks: PropsTypes.array,
  currentPlayerPosition: PropsTypes.number,
  tableData: PropsTypes.string
};
export default RankTab;
