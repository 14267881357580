import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropsTypes from "prop-types";
import MultiTableGameView from "./multiTableGameView";
// import MultiTableGame from "./multiTableGame";

function MultiTableSlider({ allGames }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    className: "myCustomCarousel",
  };

  return (
    <div className="multi-table-slider-content">
      <div className="multi-table-slider-main">
        <Slider {...settings}>
          {allGames?.map((el) => (
            <div key={el?.id}>
              <MultiTableGameView gameId={el?.id} />
            </div>
          )
          )}
        </Slider>
      </div>
    </div>
  );
}

MultiTableSlider.propTypes = {
  allGames: PropsTypes.array.isRequired
}

export default MultiTableSlider;
