import { useState } from "react";
import PropTypes from "prop-types";
// import user1 from "../../assets/images/profile/user.svg";
import insta from "../../assets/images/profile/instagram.svg";
import twitter from "../../assets/images/profile/twitter.svg";

function ProfileModal({ imagePreview, user, profileFormData }) {
  const [showmore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(!showmore);
  };
  const options = { year: "numeric", month: "short", day: "numeric" };
  return (
    <>
      <div className="preview-user">
        <div className="preview-cover">
          <img
            src={imagePreview || user?.avatar}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null; // prevents looping
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/user.png";
            }}
            alt=""
          />
        </div>
        <div className="preview-info">
          <h4>
            {profileFormData
              ? `${profileFormData?.firstName} ${profileFormData?.lastName}`
              : `${user?.firstName || user?.name} ${user?.lastName || ""}`}
          </h4>
          <span>
            {profileFormData ? profileFormData?.companyName : user?.companyName}
          </span>
          <p>{profileFormData ? profileFormData?.title : user?.title}</p>
        </div>
      </div>
      <div className="preview-about">
        <h4>About me</h4>
        <p className={`${showmore ? "about-me-full" : "expand"}`}>
          {profileFormData ? profileFormData?.aboutMe : user?.aboutMe}
        </p>
        {profileFormData?.aboutMe?.length >= 70
        || user?.aboutMe?.length >= 70 ? (
          <span onClick={handleShowMore} role="presentation">
            {showmore ? "Less" : "More"}
          </span>
          ) : (
            ""
          )}
      </div>

      <div className="preview-social">
        <ul>
          <li>
            <a
              href={`https://www.instagram.com/${
                user?.instagram ? user?.instagram.substring(1) : ""
              }`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
              {profileFormData ? profileFormData?.instagram : user?.instagram}
            </a>
          </li>
          <li>
            <a
              href={`https://twitter.com/${user?.twitter ? user?.twitter : ""}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="" />
              {profileFormData ? profileFormData?.twitter : user?.title}
            </a>
          </li>
        </ul>
      </div>

      <div className="preview-join">
        <ul>
          <li>
            Joined:
            {" "}
            {user?.createdAt
              ? new Date(user?.createdAt).toLocaleString("en-US", options)
              : ""}
          </li>
          {/* <li>
            Nominated by:
            {" "}
            <img src={user1} alt="" />
            {" "}
            <span>{user?.nominatedBy ? user?.nominatedBy : "Tony Martin"}</span>
          </li> */}
        </ul>
      </div>
    </>
  );
}

ProfileModal.defaultProps = {
  imagePreview: ""
}

ProfileModal.propTypes = {
  user: PropTypes.object.isRequired,
  imagePreview: PropTypes.string,
  profileFormData: PropTypes.object.isRequired,
};

export default ProfileModal;
