/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import Dropdown from "react-bootstrap/Dropdown";
import { Nav, Modal } from "react-bootstrap";
import PropsTypes from "prop-types";
import Lottie from "react-lottie";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import dot from "../../../assets/images/playerview/dot.svg";
import { useActiveSpeakerId, useDaily } from "@daily-co/daily-react";
import carddefault from "../../../assets/images/playerview/card-default.svg";
import raisesign from "../../../assets/images/playerview/raise-sign.svg";
import callsign from "../../../assets/images/playerview/call-sign.svg";
import cardanimatefold from "../../../assets/images/playerview/lottie/fold.json";
import cardanimatefirstcard from "../../../assets/images/playerview/lottie/card_1.json";
import cardanimatesecondcard from "../../../assets/images/playerview/lottie/card_2.json";
import cardanimateviewcard from "../../../assets/images/playerview/lottie/card_view.json";
import cross from "../../../assets/images/profile/cross.svg";
import { client } from "../../../config/keys";
import UserContext from "../../../context/UserContext";
import ProfileModal from "../../profile/profileModal";
import socket from "../../../config/socket";
import Participant from "./Participant";
import BarRoomSetting from "../../barRoom/settings";
import { DoDecrypt } from "../../../utils";
import MuteIcon from "../../../IconComponents/MuteIcon";
import AddTimerIcon from "../../../assets/images/playerview/addTimerIcon.svg";

const CardAnimationFold = {
  loop: false,
  autoplay: true,
  animationData: cardanimatefold,
};

const CardAnimationFirstCard = {
  loop: false,
  autoplay: true,
  animationData: cardanimatefirstcard,
};

const CardAnimationSecondCard = {
  loop: false,
  autoplay: true,
  animationData: cardanimatesecondcard,
};

const CardAnimationViewCard = {
  loop: true,
  autoplay: true,
  animationData: cardanimateviewcard,
};

// const playerData = {};

function Player({
  user,
  participant,
  currentPlayer,
  remainingTime,
  showCards,
  action,
  recentPlayer,
  winners,
  handleOneToOneRequest,
  oneToOneParticipant,
  gameData,
  timerType,
  timer,
  playerIndex,
}) {
  const {
    user: authUser,
    setShowProfileModal,
    gamePlayers,
    bgState,
    setBgState,
    blurBackGround,
    setBlurBackGround,
    setMessages,
    micOn,
    showProfileInfo,
    setShowProfileInfo
  } = useContext(UserContext);
  const handleClose = () => setShowProfileInfo("");
  const handleShow = (e) => {
	 setShowProfileInfo(e)
  }
  const [showCardPeakAnimate, setShowCardPeakAnimate] = useState(false);
  const [hideSecondAnimation, setHideSecondAnimation] = useState(false);
  const [hideFirstAnimation, setHideFirstAnimation] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [isMute, setIsMute] = useState(!micOn || false);
  const [showSetting, setShowSetting] = useState(false);
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);
  const [disableAddTimeBank, setDisableTimeBank] = useState(false);
  const {
    allinPlayers,
    players,
    preflopround,
    flopround,
    turnround,
    riverround,
    showdown,
    oneToOneRoom,
    isReset,
  } = gameData;
  let inHandPlayers = [];
  switch (gameData?.runninground) {
    case 1:
      inHandPlayers = preflopround?.filter(
        (el) => !el.fold && el.playing
      )?.length;
      break;
    case 2:
      inHandPlayers = flopround?.filter((el) => !el.fold && el.playing)?.length;
      break;
    case 3:
      inHandPlayers = turnround?.filter((el) => !el.fold && el.playing)?.length;
      inHandPlayers = turnround?.filter((el) => !el.fold && el.playing)?.length;
      break;
    case 4:
      inHandPlayers = riverround?.filter(
        (el) => !el.fold && el.playing
      )?.length;
      break;

    default:
      inHandPlayers = players?.filter((el) => !el.fold && el.playing)?.length;
  }

  function formatTime(value) {
    let hours = Math.floor(value / 60);
    let minutes = value % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (hours < 10) {
      hours = `0${hours}`;
    }

    return `${hours}:${minutes}`;
  }
  const location = useLocation();
  const navigate = useNavigate();
  const [cardIndex, setCardIndex] = useState("");
  const [timerCount, setTimerCount] = useState(
    localStorage.getItem("timerCount")
      ? parseInt(localStorage.getItem("timerCount"), 10)
      : 0
  );

  const queryParams = new URLSearchParams(location.search);

  const callObject = useDaily();
  const activeSpeakerId = useActiveSpeakerId();

  const remoteparticipants = callObject
    ? Object.values(callObject?.participants())
    : [];
  const remoteparticipant = remoteparticipants.find(
    (ele) => ele.user_name === user.id
  );

  useEffect(() => {
    if (queryParams.get("modal")) {
      setShowProfileModal(true);
    }
  }, [queryParams]);

  useEffect(() => {
    if (timerCount !== 2) {
      setDisableTimeBank(false);
    }
  }, [gameData?.runninground]);

  useEffect(() => {
    if (authUser.id !== user.id && !user.fold && showdown?.length <= 0) {
      socket.on("cardCheck", (data) => {
        if (data.userId === user.id) {
          setShowCardPeakAnimate(true);
          setTimeout(() => {
            setShowCardPeakAnimate(false);
          }, 3000);
        }
      });
    }
    socket.on("showCard", (data) => {
      if (data.userId === user.id) {
        setCardIndex(data.cardIndex);
      }
    });

    const storedCount = localStorage.getItem("timerCount");
    if (storedCount) {
      setTimerCount(parseInt(storedCount, 10));
    }
  }, []);

  useEffect(() => {
    if (gameData?.runninground === 1 && user?.cards?.length === 0) {
      setHideFirstAnimation(false);
      setHideSecondAnimation(false);
    }
    if (gameData?.runninground === 0 || gameData?.runninground === 1) {
      setCardIndex("");
    }
  }, [gameData]);

  useEffect(() => {
    localStorage.setItem("timerCount", timerCount.toString());
  }, [timerCount]);

  const getAction = () => {
    let lastAction = user?.fold ? "fold" : "";
    if (user?.actionType) {
      if (winners.find((winner) => winner.id === user.id)) {
        lastAction = "winner";
      } else {
        lastAction = user?.actionType;
      }
    } else if (
      gameData.smallBlindPosition === user.position
      && gameData.runninground === 1
    ) {
      lastAction = "smallBlind";
    } else if (
      (gameData.bigBlindPosition === user.position
        || gameData.bigBlind === user?.pot)
      && gameData.runninground === 1
    ) {
      lastAction = "bigBlind";
    } else if (winners.find((winner) => winner.id === user.id)) {
      lastAction = "winner";
    }
    if (action.id === user.id) {
      lastAction = action.action;
    }

    switch (lastAction) {
      case "call":
        return (
          <Call
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "fold":
        return <Fold />;
      case "check":
        return <Check recentPlayer={recentPlayer?.id === user.id} />;
      case "bet":
        return (
          <Bet amount={user?.pot} recentPlayer={recentPlayer?.id === user.id} />
        );
      case "raise":
        return (
          <Raise
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "smallBlind":
        return (
          <SmallBlind
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "bigBlind":
        return (
          <BigBlind
            amount={user?.pot}
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "all-in":
        return (
          <AllIn
            amount={
              gameData.allinPlayers.find((u) => u.id === user.id)?.amt
              || user?.pot
            }
            recentPlayer={recentPlayer?.id === user.id}
          />
        );
      case "winner":
        return (
          <Winner winner={winners.find((winner) => winner.id === user.id)} />
        );
      case "show":
        return <Show />;
      default:
        return <div className="player-action-empty-field" />;
    }
  };

  const handleCardSneak = () => {
    if (
      authUser.id === user.id
      && !showCard
      && user?.cards?.length === 2
      && !user.fold
      && showdown?.length <= 0
      && !(
        allinPlayers?.length >= inHandPlayers - 1
        && allinPlayers?.slice(-1)?.find((el) => el.round < gameData.runninground)
      )
    ) {
      setShowCard(true);
      socket.emit("cardCheck", { userId: user.id, gameId: gameData.id });
    }
    // setTimeout(() => {
    //  setShowCard(false);
    // }, 2000);
  };
  const handleCardSneakDone = () => {
    setShowCard(false);
  };

  const handleBackgroundBlur = () => {
    setBgState(!bgState);
    if (blurBackGround === "background-image") {
      setBlurBackGround("none");
    } else {
      setBlurBackGround("background-image");
    }
  };

  const handleAddTimeBank = () => {
    if (timerCount === 2) return;
    setDisableTimeBank(true);
    setTimerCount(timerCount + 1);
    socket.emit("addTimeBank", {
      runninground: gameData?.runninground,
      userId: user?.id,
      gameId: gameData?.id,
    });
    setMessages((old) => [
      ...old,
      {
        msg: `${user?.name} 60 Seconds Time Added.`,
        id: user?.id,
        type: "timebank",
      },
    ]);
  };

  //  Screen Scaling Function Start
  const [pageRatio, setPageRatio] = useState(0.6);
  const [pageWidthHeight, setPageWidthHeight] = useState({
    width: 0,
    height: 0,
  });

  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth;

  const initialScale = (pageRatio <= 0.62 ? pageHeight : pageWidth)
    / (pageRatio <= 0.62 ? 9.6 : 17.2);
  const initialTop = (100 - initialScale) / 2;

  const [scaleValue, setScaleValue] = useState(initialScale);

  useEffect(() => {
    setPageRatio(pageHeight / pageWidth);
  }, [pageWidthHeight]);

  useEffect(() => {
    setScaleValue(initialScale);
  }, [initialScale, initialTop]);

  const handleResize = (e) => {
    setPageWidthHeight({ ...pageWidthHeight, height: e.target.innerHeight });
    setPageWidthHeight({ ...pageWidthHeight, width: e.target.innerWidth });
    const currentScreenSize = e.target[pageRatio <= 0.62 ? "innerHeight" : "innerWidth"];
    setScaleValue(currentScreenSize / (pageRatio <= 0.62 ? 9.6 : 17.2));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //  Screen Scaling Function End

  return (
    <div
      className={`player-box ${
        winners?.find((winner) => winner.id === user.id) ? "winner-player" : ""
      } ${
        currentPlayer.id === user.id && !isReset ? "active-player" : ""
      } player-position${playerIndex + 1}`}
    >
      <div className="player-box-list">
        <div className="player-box-grid">
          <div className="player-box-view">
            <div
              className={`player-dropdown ${
                gameData?.oneToOneRoom?.find(
                  (ele) =>
                    ele.requestedBy === user?.id || ele.requestedTo === user?.id
                )
                  ? "dropdwon-btn-1to1"
                  : activeSpeakerId === remoteparticipant?.session_id
                    ? "player-btn-dropdown"
                    : ""
              } `}
            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {/* Add this class below class to animate the dot icons "dot-animate" */}
                  <div
                    className={`dot-flashing ${
                      activeSpeakerId === remoteparticipant?.session_id
                        ? "dot-animate"
                        : ""
                    }`}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {user.id !== authUser.id
                  && !oneToOneRoom?.find(
                    (ele) =>
                      ele.requestedBy === authUser?.id
                      || ele.requestedTo === authUser?.id
                      || ele.requestedBy === user?.id
                      || ele.requestedTo === user?.id
                  ) ? (
                    <span
                      className="dropdown-item"
                      onClick={() => handleOneToOneRequest(user.id, user.name)}
                      role="presentation"
                    >
                      Ask 1-1
                    </span>
                    ) : (
                      ""
                    )}
                  {user.id !== authUser.id
                  && oneToOneRoom?.find(
                    (ele) =>
                      ele.requestedBy === authUser?.id
                      || ele.requestedTo === authUser?.id
                  ) ? (
                    <span className="dropdown-item" role="presentation">
                      Already in Call
                    </span>
                    ) : (
                      ""
                    )}
                  {user.id !== authUser.id ? (
                    <Nav>
                      <span
                        className="dropdown-item"
                        onClick={() => handleShow(user?.id)}
                        role="presentation"
                      >
                        View profile
                      </span>
                    </Nav>
                  ) : (
                    ""
                  )}
                  {user.id === authUser.id ? (
                    <>
                      <Nav>
                        {/* <NavLink
                          className="dropdown-item"
                          to={`/user/profile${"?modal=profile"}`}
                        > */}
                        <span
                          className="dropdown-item"
                          onClick={() => navigate("?modal=profile")}
                          role="presentation"
                        >
                          Edit Profile
                        </span>
                        {/* </NavLink> */}
                      </Nav>
                      <span
                        className="dropdown-item"
                        onClick={handleShowSetting}
                        role="presentation"
                      >
                        Manage Audio/Video
                      </span>
                      {!bgState ? (
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            setBgState(true);
                            handleBackgroundBlur();
                          }}
                          role="presentation"
                        >
                          Turn Blur On
                        </span>
                      ) : (
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            setBgState(false);
                            handleBackgroundBlur();
                          }}
                          role="presentation"
                        >
                          Turn Blur Off
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {user?.position === gameData?.dealerPosition && user.playing ? (
                <div className="main-dealer-symbol">D</div>
              ) : (
                ""
              )}
            </div>
            <div className="company-logo-grid">
              {currentPlayer.id === user.id
              && !isReset
              && timerCount !== 2
              && timer > 0
              && user.id === authUser.id
              && !disableAddTimeBank ? (
                <div className="add-time" role="presentation">
                  <span role="presentation" onClick={handleAddTimeBank}>
                    60
                    <img src={AddTimerIcon} alt="add icon" />
                  </span>
                </div>
                ) : gameData?.playerBg && gameData?.playerBg !== " " ? (
                  <div className="company-logo-section">
                    {" "}
                    <img src={gameData?.playerBg} alt="company logo" />
                  </div>
                ) : (
                  ""
                )}
            </div>

            <Participant
              participant={participant}
              oneToOneParticipant={oneToOneParticipant}
              player={user}
              //  setAudioMuted={setAudioMuted}
              setIsMute={setIsMute}
              gameData={gameData}
            />

            <div className={`player-box-user ${isMute ? "mute" : ""}`}>
              <span title={user?.name}>
                {user?.name}
                {isMute ? <MuteIcon /> : ""}
              </span>
              <h4>{`$${user?.wallet}`}</h4>
            </div>
          </div>

          <div
            className={`player-box-action-grid ${
              showCardPeakAnimate
                ? "showCard"
                : action?.action === "fold" && action?.id === user?.id
                  ? "foldCard"
                  : user?.cards?.length === 1
                    ? "firstCard"
                    : user?.cards?.length === 2
                      ? "secondCard"
                      : ""
            }`}
          >
            <div
              className={`player-box-card  ${
                user.id === authUser.id && user.fold
                  ? "self-fold"
                  : user.fold
                    ? "opponent-fold"
                    : ""
              }`}
              onMouseEnter={handleCardSneak}
              onMouseLeave={handleCardSneakDone}
            >
              {user?.cards?.length === 2 ? (
                ""
              ) : user?.cards?.length ? (
                <span
                  className={`${
                    hideFirstAnimation && user?.cards?.length === 1
                      ? "show"
                      : ""
                  }`}
                >
                  <img
                    src={
                      (authUser?.role === "dealer" && showCards) || showCard
                        ? `${client}main-view-cards/${DoDecrypt(
                          user?.cards[0]
                        )}.svg`
                        : carddefault
                    }
                    alt=""
                  />
                </span>
              ) : (
                ""
              )}
              {user?.cards?.map((card, index) => (
                <span
                  key={card}
                  className={`${
                    ((user?.id === authUser?.id && user?.fold) || !user.fold)
                    && !showCardPeakAnimate
                    && hideSecondAnimation
                    && user?.cards?.length === 2
                      ? "show"
                      : ""
                  }`}
                >
                  <img
                    src={
                      (user?.id === authUser?.id && user?.fold)
                      || (authUser?.role === "dealer" && showCards)
                      || showCard
                      || (allinPlayers?.length >= inHandPlayers - 1
                        && inHandPlayers === 2
                        && gameData?.lastAction !== "fold"
                        && allinPlayers
                          ?.slice(-1)
                          ?.find((el) => el.round < gameData.runninground))
                      || (allinPlayers?.length >= inHandPlayers - 1
                        && inHandPlayers > 2
                        && allinPlayers
                          ?.slice(-1)
                          ?.find((el) => el.round < gameData.runninground))
                      || (allinPlayers?.length >= inHandPlayers - 1
                        && inHandPlayers > 2
                        && allinPlayers
                          ?.slice(-1)
                          ?.find((el) => el.round === gameData.runninground)
                        && showdown?.length > 0)
                      || (gameData?.runninground === 5
                        && winners?.find((el) => el)?.handName)
                      || cardIndex === "showBothCard"
                      || cardIndex === index
                        ? `${client}main-view-cards/${DoDecrypt(card)}.svg`
                        : carddefault
                    }
                    alt=""
                  />
                </span>
              ))}
              {action?.action === "fold" && action.id === user?.id ? (
                <div className="card-animate-fold">
                  <Lottie options={CardAnimationFold} />
                </div>
              ) : (
                ""
              )}
              {showCardPeakAnimate
                && showdown?.length === 0
                && !(
                  allinPlayers?.length >= inHandPlayers - 1
                  && allinPlayers
                    ?.slice(-1)
                    ?.find((el) => el.round < gameData.runninground)
                ) && (
                  <div className="card-animate-cardview">
                    <Lottie options={CardAnimationViewCard} />
                  </div>
              )}
              {user?.cards?.length === 1 && !hideFirstAnimation ? (
                <div className="card-animate-firstcard">
                  <Lottie
                    options={CardAnimationFirstCard}
                    eventListeners={[
                      {
                        eventName: "complete",
                        callback: () => setHideFirstAnimation(true),
                      },
                    ]}
                  />
                </div>
              ) : (action.action === "fold" && user.id === action.id)
                || user.fold
                || hideSecondAnimation ? (
                  ""
                ) : user?.cards?.length === 2 ? (
                  <div className="card-animate-secondcard">
                    <Lottie
                      options={CardAnimationSecondCard}
                      eventListeners={[
                        {
                          eventName: "complete",
                          callback: () => setHideSecondAnimation(true),
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
            </div>
            <div className="player-box-action">
              {currentPlayer.id === user.id && !isReset ? (
                <div
                  className={`player-action-view timer ${
                    timerType === "timebank" || user?.timebank > 1
                      ? "green"
                      : ""
                  }`}
                >
                  {remainingTime === -1 ? "00:00" : formatTime(remainingTime)}
                </div>
              ) : (
                getAction()
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        centered
        className="bar-room-setting-popup"
        style={{
          transform: `translate(-50%, 0%) scale(${scaleValue / 100})`,
        }}
      >
        {showSetting && (
          <Helmet>
            <body className="bar-room-setting-page" />
          </Helmet>
        )}
        <BarRoomSetting />
      </Modal>

      {showProfileInfo === user?.id && (
        <div className="profile-viewer">
          <span
            className="closeprofile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="" />
          </span>
          <ProfileModal
            user={gamePlayers?.find((player) => player.id === user.id)}
          />
        </div>
      )}
    </div>
  );
}

Player.defaultProps = {
  participant: null,
  currentPlayer: {},
  remainingTime: 0,
  recentPlayer: {},
  oneToOneParticipant: null,
  gameData: {},
  timerType: "timer",
  timer: 0,
};
Player.propTypes = {
  user: PropsTypes.object.isRequired,
  participant: PropsTypes.object,
  oneToOneParticipant: PropsTypes.object,
  currentPlayer: PropsTypes.object,
  remainingTime: PropsTypes.number,
  showCards: PropsTypes.bool.isRequired,
  recentPlayer: PropsTypes.object,
  winners: PropsTypes.array.isRequired,
  action: PropsTypes.object.isRequired,
  handleOneToOneRequest: PropsTypes.func.isRequired,
  gameData: PropsTypes.object,
  timerType: PropsTypes.string,
  timer: PropsTypes.number,
  playerIndex: PropsTypes.number.isRequired,
};

export default Player;

// function EmptyPlayerBox() {
//  return (
//    <div className="player-empty-box">
//      <div className="player-empty-circle" />
//      <div className="player-empty-grid">
//        <div className="player-empty-tile" />
//        <div className="player-empty-tile" />
//        <div className="player-empty-btn" />
//      </div>
//    </div>
//  )
// }

// function PlayerInvite() {
//  return (
//    <div className="player-invite-box">
//      <div className="player-invite-box-info">
//        <h4>
//          Invite friend
//          <Button><img src={plusicon} alt="" /></Button>
//        </h4>
//      </div>

//      <div className="player-invite-user">
//        <h4>Invite friend</h4>
//        <Form>
//          <Form.Label>E-mail</Form.Label>
//          <Form.Group className="form-group">
//            <Select
//              isMulti
//              options={users}
//            />
//            <Button><img src={arrowicon} alt="" /></Button>
//          </Form.Group>
//        </Form>
//      </div>
//    </div>
//  )
// }

function Check({ recentPlayer }) {
  return (
    <div
      className={`player-action-view-check ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Check</div>
    </div>
  );
}

Check.propTypes = {
  recentPlayer: PropsTypes.bool.isRequired,
};

function Call({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view call-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Call</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>
          <img src={callsign} alt="Call" />
        </span>
      </div>
    </div>
  );
}
Call.defaultProps = {
  amount: 0,
};
Call.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function Raise({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view raise-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Raise</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>
          <img src={raisesign} alt="Raise" />
        </span>
      </div>
    </div>
  );
}
Raise.defaultProps = {
  amount: 0,
};
Raise.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function Bet({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view raise-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Bet</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>
          <img src={raisesign} alt="Raise" />
        </span>
      </div>
    </div>
  );
}
Bet.defaultProps = {
  amount: 0,
};
Bet.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function BigBlind({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view bigblind-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Big Blind</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>BB</span>
      </div>
    </div>
  );
}
BigBlind.defaultProps = {
  amount: 0,
};
BigBlind.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function SmallBlind({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view smallblind-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">Small Blind</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
        <span>SB</span>
      </div>
    </div>
  );
}
SmallBlind.defaultProps = {
  amount: 0,
};
SmallBlind.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function AllIn({ amount, recentPlayer }) {
  return (
    <div
      className={`player-action-view allin-action-view ${
        recentPlayer ? "recent-player" : ""
      }`}
    >
      <div className="player-action-view-text">All-In</div>
      <div className="player-action-view-bet">
        <h4>{`$${amount}`}</h4>
      </div>
    </div>
  );
}
AllIn.defaultProps = {
  amount: 0,
};
AllIn.propTypes = {
  amount: PropsTypes.number,
  recentPlayer: PropsTypes.bool.isRequired,
};

function Fold() {
  return (
    <div className="player-action-view-fold">
      <span>Fold</span>
    </div>
  );
}

function Winner({ winner }) {
  return (
    <div className="player-action-winner">
      <span>WINNER!</span>
      <p>{winner && winner.handName ? winner?.handName : "All-Folded"}</p>
    </div>
  );
}

function Show() {
  return (
    <div className="player-action-view-show">
      <span>Show</span>
    </div>
  );
}

Winner.propTypes = {
  winner: PropsTypes.object.isRequired,
};
